declare global { interface Window { AdobeDC: any; } }

class ViewSDKClient {
  readyPromise: Promise<unknown>;
  adobeDCView: any;

  constructor() {
    this.readyPromise = new Promise<void>((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  previewFile(divId: string, viewerConfig: any, fileName: string, url: string) {
    const config = {
      clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
      divId: divId,
      locale: "nl-NL"
    };

    this.adobeDCView = new window.AdobeDC.View(config);

    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: { location: { url } },
        metaData: { fileName },
      },
      viewerConfig
    );
    return previewFilePromise;
  }
}

export default ViewSDKClient;