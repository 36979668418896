import { CommandBarButton, FontSizes, FontWeights, ILabelStyles, Label } from "@fluentui/react";
import React from "react";
import { LanguageContext } from "../../../helpers/LocalizationModule";

const labelHeadingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.mediumPlus,
    },
};

export interface IAdminManageHeaderProps {
    title: string;
    manageClicked?: () => void;
    altText?: string;
}

export const AdminManageHeader: React.FunctionComponent<IAdminManageHeaderProps> = ({ title, manageClicked, altText }: IAdminManageHeaderProps) => {
    const languageStrings = React.useContext(LanguageContext);

    return (
        <div className="flex flex-row justify-between">
            <Label styles={labelHeadingStyle}>{title}</Label>
            {manageClicked && <CommandBarButton
                iconProps={{
                    iconName: "Edit",
                    styles: {
                        root: {
                            color: "rgb(50, 49, 48)",
                        },
                    },
                }}
                text={altText ? altText : languageStrings.Manage}
                styles={{
                    root: {
                        backgroundColor: "transparent",
                    },
                }}
                onClick={() => {
                    manageClicked();
                }}
            />}
        </div>
    );
};
