import { DefaultButton, Dialog, DialogContent, DialogFooter, DialogType, Label, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import React from "react";
import { LanguageContext } from "../helpers/LocalizationModule";
import { IUser } from "../interfaces/IUser";
import { editUser } from "../services/userService";

export interface IConfirmDialogProps {
    hideDialog: boolean;
    dismissDialog: () => void;
    title: string;
    subText: string;
    onConfirm: () => void;
}

export const ConfirmDialog: React.FunctionComponent<IConfirmDialogProps> = ({ hideDialog, dismissDialog, title, subText, onConfirm }: IConfirmDialogProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const [password, setPassword] = React.useState("");
    const [isSaving, setIsSaving] = React.useState(false);

    const dialogContentProps = {
        type: DialogType.normal,
        title,
        closeButtonAriaLabel: "Close",
        subText,
    };

    return (
        <>
            <Dialog hidden={hideDialog} onDismiss={dismissDialog} dialogContentProps={dialogContentProps}>
                <DialogFooter>
                    <DefaultButton onClick={dismissDialog} text={languageStrings.Cancel} disabled={isSaving} />
                    <DefaultButton onClick={() => onConfirm()} text={languageStrings.Confirm} disabled={isSaving}>
                        {isSaving && <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: 5 } }}></Spinner>}
                    </DefaultButton>
                </DialogFooter>
            </Dialog>
        </>
    );
};
