import { Label } from "@fluentui/react";
import React from "react";

export interface IBrumToggleProps {
    label: string;
    checked: boolean;
    toggled: () => void;
    id:string;
}

export const BrumToggle: React.FunctionComponent<IBrumToggleProps> = ({ label, checked, toggled, id }: IBrumToggleProps) => {
    return (
        <div>
            <Label>{label}</Label>
            <label htmlFor={id} className="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" value="" id={id} className="sr-only peer" checked={checked} onChange={() => toggled()} />
                <div
                    className="w-11 h-6 
                    bg-gray-200 
                    rounded-full peer 
                    dark:bg-gray-700 
                    peer-checked:after:translate-x-full 
                    peer-checked:after:border-white after:content-[''] 
                    after:absolute after:top-[2px] 
                    after:left-[2px] 
                    after:bg-white 
                    after:border 
                    after:rounded-full 
                    after:h-5 
                    after:w-5 
                    after:transition-all 
                    peer-checked:bg-purple-600"
                ></div>
            </label>
        </div>
    );
};
