import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from "./redux/store";
import { initializeIcons } from "@fluentui/react/lib/Icons";

import dayjs from 'dayjs';
import 'dayjs/locale/nl'; // import locale
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

initializeIcons();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
      <App />
    </Provider>
);

