import { Callout, ISearchBoxStyles, Icon, Persona, PersonaSize, SearchBox, Stack, TooltipHost } from "@fluentui/react";
import React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { AppPage, MediaMode } from "../../helpers/enums";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { IPatient } from "../../interfaces/IPatient";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setCurrentPage, setSelectedFolder } from "../../redux/slices/navigationSlice";
import { setSelectedPatient } from "../../redux/slices/userSlice";
import { SelectPatientPanel } from "../panels/SelectPatientPanel";
import { UserSettingsPanel } from "../panels/UserSettingsPanel";
import { ApprovalFolderName } from "../../helpers/constants";

const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
        width: '25vw',
        border: '1px solid white',
        selectors: {
            ':focus': {
                borderColor: 'white',
                border: '1px solid white'
            },
            ':focus-within': {
                borderColor: 'white',
                border: '1px solid white'
            },
            '::after': {
                border: '1px solid white'
            }
        }
    }
};

export const TopNavBar: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);
    const dispatch = useAppDispatch();

    const currentPage = useAppSelector((state) => state.navigation.currentPage);
    const selectedFolder = useAppSelector((state) => state.navigation.selectedFolder);
    const user = useAppSelector((state) => state.user.user);
    const selectedPatient = useAppSelector((state) => state.user.selectedPatient);
    const isCameraDeviceActive = useAppSelector((state) => state.insta.isCameraDeviceActive);
    const mediaMode = useAppSelector((state) => state.insta.mediaMode);
    const userPatients = useAppSelector((state) => state.user.user?.patients);

    const [isAdmin, setIsAdmin] = React.useState(false);

    const [inAdminSection, setInAdminSection] = React.useState(false);
    const [adminSectionDisplay, setAdminSectionDisplay] = React.useState("");

    const [hasMultiplePatients, setHasMultiplePatients] = React.useState(false);

    const [isSelectPatientsPanelOpen, setIsSelectPatientsPanelOpen] = React.useState(false);
    const [isUserSettingsPanelOpen, setIsUserSettingsPanelOpen] = React.useState(false);

    const [privateSearchValue, setPrivateSearchValue] = React.useState<string>("");
    const [filteredPatients, setFilteredPatients] = React.useState<IPatient[]>([]);
    const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
    const [searchPlaceHolder, setSearchPlaceholder] = React.useState("");

    const searchBoxRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (selectedPatient) {
            setSearchPlaceholder(`${selectedPatient.firstName} ${selectedPatient.lastName}`);
        } else {
            setSearchPlaceholder(`${languageStrings.Search} ${languageStrings.Children}`);
        }
    }, [selectedPatient])

    React.useEffect(() => {
        if (user) {
            const adminRole = user.roles?.find((x) => x.id === 1);
            setIsAdmin(adminRole ? true : false);

            if (user.patients && user.patients.length > 1) {
                setHasMultiplePatients(true);
            }
        }
    }, [user]);

    React.useEffect(() => {
        if (currentPage === AppPage.AdminPage) {
            setInAdminSection(true);
            setAdminSectionDisplay(languageStrings.Home);
        } else if (currentPage === AppPage.AdminUsersPage) {
            setInAdminSection(true);
            setAdminSectionDisplay(languageStrings.Users);
        } else if (currentPage === AppPage.AdminChildrenPage) {
            setInAdminSection(true);
            setAdminSectionDisplay(languageStrings.Children);
        } else {
            setInAdminSection(false);
            setAdminSectionDisplay("");
        }
    }, [currentPage]);

    const patientSelected = (patient: IPatient) => {
        dispatch(setSelectedPatient(patient.id! > 0 ? patient : undefined));
        setIsSelectPatientsPanelOpen(false);
        dispatch(setSelectedFolder(undefined));
    };

    const getCurrentPageDisplay = React.useCallback(() => {
        switch (currentPage) {
            case AppPage.AdminPage:
                return languageStrings.Admin;

            case AppPage.AdminChildrenPage:
                return languageStrings.Admin;

            case AppPage.AdminUsersPage:
                return languageStrings.Admin;

            case AppPage.HomePage:
                return languageStrings.Home;

            case AppPage.InstaPage:
                return languageStrings.DailyCmmunication;

            case AppPage.WorkDocumentPage:
                return languageStrings.WorkingDocuments;

            case AppPage.ReportPage:
                return languageStrings.Reports;

            case AppPage.ArchivePage:
                return languageStrings.Archive;

            case AppPage.BrumGeneralPage:
                return languageStrings.BrumGeneral;

            case AppPage.Methodology:
                return languageStrings.Methodology;
            default:
                break;
        }

        return currentPage;
    }, [currentPage]);

    const onChangeFilter = async (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        setPrivateSearchValue(newValue || "");
        if (newValue && userPatients) {
            const filteredResults = userPatients?.filter(x => x.firstName?.toLowerCase().includes(newValue?.toLowerCase()) || x.lastName?.toLowerCase().includes(newValue?.toLowerCase())) || [];
            if (filteredResults && filteredResults?.length > 0) {
                setFilteredPatients(filteredResults);
            } else {
                setFilteredPatients([]);
            }

            if (selectedPatient) {
                filteredResults.unshift({
                    id: 0,
                    firstName: languageStrings.AllChildren,
                    users: []
                });
            }

            setFilteredPatients(filteredResults);
            setIsDropdownVisible(true);
        } else {
            setFilteredPatients([]);
            setIsDropdownVisible(false);
        }
    };

    const onClearFilter = () => {
        setPrivateSearchValue("");
    };
    const onEscapeFilter = () => {
        setPrivateSearchValue("");
    };

    return (
        <div>
            <div className="flex flex-row theme-color pl-2 py-2 justify-between">
                <div className="text-left pl-1 flex flex-row">
                    {selectedPatient && (
                        <div className="my-auto pr-2">
                            <Persona
                                hidePersonaDetails
                                imageUrl={selectedPatient.profileImage && `data:image/*;base64,${selectedPatient.profileImage}`}
                                text={`${selectedPatient?.firstName ? selectedPatient?.firstName : ""} ${selectedPatient?.lastName ? selectedPatient?.lastName : ""}`}
                            />
                        </div>
                    )}
                    <div>
                        {!isCameraDeviceActive && (
                            <div>
                                <span className="text-xl md:text-2xl">{getCurrentPageDisplay()}</span>
                                {selectedFolder && (
                                    <span className="pl-2 md:pl-4 text-xs md:text-md">{selectedFolder.folderName === ApprovalFolderName ? languageStrings.ToBeApproved : selectedFolder.folderName}</span>
                                )}
                            </div>
                        )}
                        {isCameraDeviceActive && (
                            <div>
                                {mediaMode === MediaMode.Camera && <span className="text-2xl">{languageStrings.ModeCamera}</span>}
                                {mediaMode === MediaMode.Video && <span className="text-2xl">{languageStrings.ModeVideo}</span>}
                            </div>
                        )}
                        {!inAdminSection && selectedPatient && (
                            <div className="text-md">
                                {selectedPatient?.firstName ? selectedPatient?.firstName : ""} {selectedPatient?.lastName ? selectedPatient?.lastName : ""}
                            </div>
                        )}
                        {!inAdminSection && !selectedPatient && <div className="text-md">{languageStrings.AllChildren}</div>}
                        {inAdminSection && <div className="text-md">{adminSectionDisplay}</div>}
                    </div>
                </div>
                <div ref={searchBoxRef} className="hidden md:block my-auto">
                    {currentPage !== AppPage.AdminChildrenPage && currentPage !== AppPage.AdminPage && currentPage !== AppPage.AdminUsersPage && <SearchBox
                        styles={searchBoxStyles}
                        placeholder={searchPlaceHolder}
                        value={privateSearchValue ?? ""}
                        onChange={onChangeFilter}
                        onClear={onClearFilter}
                        onEscape={onEscapeFilter}
                    />}

                    {isDropdownVisible &&
                        <Callout
                            target={searchBoxRef.current}
                            directionalHint={4}
                            doNotLayer={false}
                            gapSpace={0}
                            setInitialFocus
                            calloutWidth={searchBoxRef.current?.offsetWidth}
                            style={{ marginTop: -12 }}
                        >
                            <Stack tokens={{ childrenGap: 3 }} className="mentionDropdown">
                                {filteredPatients.map((patient) => (
                                    <div key={patient.id} onClick={() => {
                                        patientSelected(patient);
                                        setFilteredPatients([]);
                                        setPrivateSearchValue("");
                                        setIsDropdownVisible(false);
                                    }}
                                        className="mentionItem">
                                        <Persona
                                            imageUrl={`${patient.profileImage ? `data:image/*;base64,${patient.profileImage}` : undefined} `}
                                            text={`${patient.firstName || ""} ${patient.lastName || ""}`}
                                            size={PersonaSize.size32}
                                            styles={{ secondaryText: { display: 'block' } }}
                                        />
                                    </div>
                                ))}
                            </Stack>
                        </Callout>}
                </div>
                {!isCameraDeviceActive && (
                    <div className="my-auto flex flex-row justify-end">
                        {isBrowser && isAdmin && (
                            <TooltipHost content={languageStrings.Admin}>
                                <Icon
                                    className="hover:cursor-pointer hover:text-gray-200 px-3 pt-2"
                                    iconName="Settings"
                                    styles={{ root: { fontSize: 24 } }}
                                    onClick={() => dispatch(setCurrentPage(AppPage.AdminPage))}
                                />
                            </TooltipHost>
                        )}
                        {isBrowser && hasMultiplePatients && (
                            <TooltipHost content={languageStrings.Children}>
                                <Icon
                                    className="hover:cursor-pointer hover:text-gray-200 px-3 pt-2"
                                    iconName="ContactList"
                                    styles={{ root: { fontSize: 24 } }}
                                    onClick={() => setIsSelectPatientsPanelOpen(true)}
                                />
                            </TooltipHost>
                        )}
                        <TooltipHost content={`${user?.firstName ? user?.firstName : ""} ${user?.lastName ? user?.lastName : ""}`}>
                            <Persona
                                imageUrl={user?.profileImage && `data:image/*;base64,${user?.profileImage}`}
                                hidePersonaDetails
                                text={`${user?.firstName ? user?.firstName : ""} ${user?.lastName ? user?.lastName : ""}`}
                                className="hover:cursor-pointer hover:text-gray-200 px-3"
                                onClick={() => setIsUserSettingsPanelOpen(true)}
                            />
                        </TooltipHost>
                    </div>
                )}
            </div>
            {isSelectPatientsPanelOpen && (
                <SelectPatientPanel
                    isOpen={isSelectPatientsPanelOpen}
                    dismissPanel={() => {
                        setIsSelectPatientsPanelOpen(false);
                    }}
                    itemSelected={patientSelected}
                    isAdminMode={false}
                />
            )}

            {isUserSettingsPanelOpen && (
                <UserSettingsPanel
                    isOpen={isUserSettingsPanelOpen}
                    dismissPanel={() => {
                        setIsUserSettingsPanelOpen(false);
                    }}
                    childChangedClicked={() => {
                        setIsSelectPatientsPanelOpen(true);
                        setIsUserSettingsPanelOpen(false);
                    }}
                    adminClicked={() => {
                        setIsUserSettingsPanelOpen(false);
                        dispatch(setCurrentPage(AppPage.AdminPage));
                    }}
                />
            )}
        </div>
    );
};
