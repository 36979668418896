import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, ICommandBarItemProps, mergeStyles, Persona, PersonaSize, SelectionMode, Selection, Pivot, PivotItem } from "@fluentui/react";
import React from "react";
import { AdminCrudMode } from "../../helpers/enums";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { IPatient } from "../../interfaces/IPatient";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getPatients, deletePatient } from "../../services/patientService";
import { BrumCommandBar } from "../../_components/BrumCommandBar";
import { BrumSpinner } from "../../_components/BrumSpinner";
import { ConfirmDialog } from "../../_components/ConfirmDialog";
import { AddUpdatePatientPanel } from "../../_components/panels/AddUpdatePatientPanel";
import { setActivePatients } from "../../redux/slices/navigationSlice";

function copyAndSort<T>(items: T[], columnKey?: string, isSortedDescending?: boolean): T[] {
    try {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => {
            return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1)
        });
    } catch (error) {
        console.error("copyAndSort: ", error);
        return items;
    }
};

export const AdminPatientsPage: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);

    const dispatch = useAppDispatch();

    const [isBusy, setisBusy] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);

    const [patients, setPatients] = React.useState<IPatient[]>([]);
    const [filteredPatients, setFilteredPatients] = React.useState<IPatient[]>([]);
    const [searchText, setSearchText] = React.useState<string>("");

    const [commandBarItems, setCommandBarItems] = React.useState<ICommandBarItemProps[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<any>();
    const [hideConfirmDeleteDialog, setHideConfirmDeleteDialog] = React.useState(true);

    const [isAddUpdatePatientPanelOpen, setIsAddUpdatePatientPanelOpen] = React.useState(false);
    const [adminCrudMode, setAdminCrudMode] = React.useState<AdminCrudMode>(AdminCrudMode.None);

    const [selectedView, setSelectedView] = React.useState<string>(languageStrings.Active);

    const [columns, setColumns] = React.useState<IColumn[]>([]);
    const columnClickedRef = React.useRef<{ key: string, isSorted: boolean, isSortedDescending: boolean }>();

    const _selection = React.useRef(
        new Selection({
            onSelectionChanged: () => getSelectionDetails(),
        })
    );

    const _onColumnClick = React.useCallback((_ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
        try {
            setColumns(prevCols => {
                const newColumns = prevCols.slice();
                const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

                newColumns.forEach((newCol: IColumn) => {
                    if (newCol === currColumn) {
                        currColumn.isSortedDescending = !currColumn.isSortedDescending;
                        currColumn.isSorted = true;

                        columnClickedRef.current = {
                            key: currColumn.key,
                            isSorted: currColumn.isSorted,
                            isSortedDescending: currColumn.isSortedDescending
                        };
                    } else {
                        newCol.isSorted = false;
                        newCol.isSortedDescending = true;
                    }
                });

                setFilteredPatients(prevFiltered => {
                    return copyAndSort(prevFiltered, currColumn.fieldName!, currColumn.isSortedDescending);
                });

                return newColumns;
            });


        } catch (error) {
            console.error("onColumnClick: ", error);
        }
    }, [filteredPatients, columns, searchText]);

    const getSelectionDetails = () => {
        const filteredSelectedItems = _selection.current.getSelection().filter((x) => x);

        if (filteredSelectedItems?.length === 1) {
            let selectedItem: any = filteredSelectedItems[0];
            setSelectedItem(selectedItem);
        } else {
            setSelectedItem(null);
        }
    };

    React.useEffect(() => {
        setCommandBarItems([
            {
                key: "Add",
                text: languageStrings.Add,
                disabled: selectedItem || isDeleting,
                iconProps: { iconName: "Add" },
                onClick: () => {
                    setIsAddUpdatePatientPanelOpen(true);
                    setAdminCrudMode(AdminCrudMode.Add);
                },
            },
            {
                key: "Edit",
                text: languageStrings.Edit,
                disabled: !selectedItem || isDeleting,
                iconProps: { iconName: "Edit" },
                onClick: () => {
                    setIsAddUpdatePatientPanelOpen(true);
                    setAdminCrudMode(AdminCrudMode.Edit);
                },
            },
            {
                key: "Delete",
                text: `${isDeleting ? languageStrings.Deleting : languageStrings.Delete}`,
                disabled: !selectedItem || isDeleting,
                iconProps: { iconName: "Delete" },
                onClick: async () => {
                    await onDeletePatient();
                },
            },
        ]);
    }, [selectedItem, isDeleting]);

    React.useEffect(() => {
        setColumns([
            {
                key: "displayName",
                name: languageStrings.Name,
                fieldName: "displayName",
                data: "string",
                minWidth: 100,
                maxWidth: 350,
                isResizable: true,
                isSorted: columnClickedRef.current && columnClickedRef.current.key === "displayName" ? columnClickedRef.current.isSorted : false,
                isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "displayName" ? columnClickedRef.current.isSortedDescending : false,
                onColumnClick: _onColumnClick,
                onRender: (patient: IPatient) => {
                    return (
                        <div className="hover:cursor-pointer">
                            <Persona
                                imageUrl={patient.profileImage && `data:image/*;base64,${patient.profileImage}`}
                                text={`${patient.firstName} ${patient.lastName}`}
                                size={PersonaSize.size40}
                                onClick={() => { }}
                            />
                        </div>
                    );
                },
            },
        ])
    }, []);

    React.useEffect(() => {
        (async () => {
            setisBusy(true);
            setPatients(await getPatients());
            setisBusy(false);
        })();
    }, []);

    React.useEffect(() => {
        if (!searchText) {
            // setFilteredPatients([...patients]);
            setFilteredPatients(selectedView === languageStrings.Active
                ? [...patients?.filter(patient => patient.isArchived !== true)]
                : [...patients?.filter(patient => patient.isArchived === true)]);
        } else {
            setFilteredPatients([
                ...patients.filter(
                    (x) =>
                        (x.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
                            x.lastName?.toLowerCase().includes(searchText.toLowerCase()))
                        &&
                        (selectedView === languageStrings.Active
                            ? x.isArchived !== true
                            : x.isArchived === true)
                ),
            ]);
        }
    }, [patients, searchText, selectedView]);

    const onDeletePatient = async () => {
        setHideConfirmDeleteDialog(false);
    };

    const saveSuccess = async (patient: IPatient) => {
        setIsAddUpdatePatientPanelOpen(false);
        setisBusy(true);
        if (adminCrudMode === AdminCrudMode.Add) {
            setSelectedItem(undefined);
        } else {
            setSelectedItem(patient);
        }

        setAdminCrudMode(AdminCrudMode.None);

        const allPatients = await getPatients();
        setPatients(allPatients);
        dispatch(setActivePatients(allPatients.filter(p => p.isArchived !== true)));

        setisBusy(false);
    };

    const onConfirmDelete = async () => {
        setIsDeleting(true);
        setHideConfirmDeleteDialog(true);
        await deletePatient(selectedItem.id!);
        setIsDeleting(false);

        const allPatients = await getPatients();
        setPatients(allPatients);
        dispatch(setActivePatients(allPatients.filter(p => p.isArchived !== true)));
    };

    const searchChanged = (value: string) => {
        setSearchText(value);
    };

    const viewItemClicked = React.useCallback((item?: PivotItem) => {
        setSelectedView(item?.props.itemKey === languageStrings.Archived ? languageStrings.Archived : languageStrings.Active);
    }, [setSelectedView]);

    return (
        <>
            <BrumCommandBar commandBarItems={commandBarItems} onSearch={searchChanged} />
            <div className="h-[calc(100%-90px)] overflow-scroll overflow-x-hidden scrollbar-thin theme-scrollbar theme-scrollbar-track">
                {!isBusy && (
                    <>
                        <Pivot onLinkClick={viewItemClicked} styles={{ root: { paddingLeft: 25 } }}>
                            <PivotItem headerText={languageStrings.Active} itemKey={languageStrings.Active} />
                            <PivotItem headerText={languageStrings.Archived} itemKey={languageStrings.Archived} />
                        </Pivot>
                        <DetailsList
                            items={filteredPatients ?? []}
                            columns={columns}
                            selectionMode={SelectionMode.single}
                            layoutMode={DetailsListLayoutMode.justified}
                            constrainMode={ConstrainMode.unconstrained}
                            selectionPreservedOnEmptyClick={true}
                            selection={_selection.current}
                            checkboxCellClassName={mergeStyles({
                                display: "flex",
                                alignItems: "center",
                            })}
                        />
                    </>
                )}
                {isBusy && (
                    <div className="text-center mt-4">
                        <BrumSpinner />
                    </div>
                )}
                {isAddUpdatePatientPanelOpen && (
                    <AddUpdatePatientPanel
                        isOpen={isAddUpdatePatientPanelOpen}
                        dismissPanel={() => {
                            setIsAddUpdatePatientPanelOpen(false);
                        }}
                        adminCrudMode={adminCrudMode}
                        saveSuccess={saveSuccess}
                        selectedPatient={selectedItem}
                    />
                )}
                <ConfirmDialog
                    hideDialog={hideConfirmDeleteDialog}
                    dismissDialog={() => setHideConfirmDeleteDialog(true)}
                    title={languageStrings.ConfirmDelete}
                    subText={languageStrings.ConfirmDeleteChildMessage}
                    onConfirm={() => onConfirmDelete()}
                />
            </div>
        </>
    );
};
