export class StorageHelper {
    static setItem(key: string, value: string): void {
        try {
            localStorage.setItem(key, value);
        } catch (error) {
        }
    }

    static getItem(key: string): string | null {
        try {
            return localStorage.getItem(key);
        } catch (error) {
        }
        return null;
    }

    static removeItem(key: string): void {
        try {
            localStorage.removeItem(key);
        } catch (error) {
        }
    }
}