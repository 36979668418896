import React from "react";
import { BottomNavBar } from "./navigation/BottomNavBar";
import { TopNavBar } from "./navigation/TopNavBar";

export interface ILayoutProps {
    children: React.ReactNode
}

export const Layout: React.FunctionComponent<ILayoutProps> = ({ children }: ILayoutProps) => {
    return (
        <div className="flex flex-col h-[100%] bg-gray-50">
            <div>
                <TopNavBar />
            </div>
            <div className="grow overflow-scroll overflow-x-hidden scrollbar-thin theme-scrollbar theme-scrollbar-track">{children}</div>
            <div>
                <BottomNavBar />
            </div>
        </div>
    );
}