import { DirectionalHint, Icon, ITooltipHostStyles, TooltipHost } from "@fluentui/react";
import React from "react";
import { AppPage, MediaMode } from "../../helpers/enums";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setMediaMode } from "../../redux/slices/instaSlice";
import { setCurrentPage, setSelectedFolder } from "../../redux/slices/navigationSlice";
import { StorageHelper } from "../../helpers/StorageHelper";
import { getFileCategoryNewCount } from "../../services/fileService";
import { IFileCategoryNewCount } from "../../interfaces/IFileCategoryNewCount";

const tooltipStyles: ITooltipHostStyles = {
    root: {
        display: "inline-block"
    }
}
export const BottomNavBar: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);

    const dispatch = useAppDispatch();

    const isCameraDeviceActive = useAppSelector((state) => state.insta.isCameraDeviceActive);
    const user = useAppSelector((state) => state.user.user);
    const selectedPatient = useAppSelector((state) => state.user.selectedPatient);
    const refreshFolderContent =  useAppSelector((state) => state.navigation.refreshFolderContent);
    const currentPage =  useAppSelector((state) => state.navigation.currentPage);

    const [categoryCounts, setCategoryCounts] = React.useState<IFileCategoryNewCount[]>([]);

    const [werkdocumentenCounts, setWerkdocumentenCounts] = React.useState<IFileCategoryNewCount>();
    const [verslagenCounts, setVerslagenCounts] = React.useState<IFileCategoryNewCount>();
    const [archiefCounts, setArchiefCounts] = React.useState<IFileCategoryNewCount>();
    const [brumAlgemeenCounts, setBrumAlgemeenCounts] = React.useState<IFileCategoryNewCount>();



    React.useEffect(() => {
        setWerkdocumentenCounts(categoryCounts.find(x => x.id === 6));
        setVerslagenCounts(categoryCounts.find(x => x.id === 7));
        setArchiefCounts(categoryCounts.find(x => x.id === 8));
        setBrumAlgemeenCounts(categoryCounts.find(x => x.id === 9));
    }, [categoryCounts]);

    React.useEffect(() => {
        (async () => {
            if (user && user.patients !== undefined) {
                const categoryCount = await getFileCategoryNewCount(user.id.toString(), selectedPatient?.id?.toString())
                setCategoryCounts(categoryCount);
            }
        })();
    }, [user, selectedPatient]);

    const onNavigate = (page: AppPage) => {
        if (!isCameraDeviceActive) {
            dispatch(setCurrentPage(page));
            dispatch(setSelectedFolder(undefined));
            dispatch(setMediaMode(MediaMode.None));
            StorageHelper.setItem("currentPage", JSON.stringify(page));
        }
    };

    return (
        <div className="theme-color grid grid-cols-7 py-4">
            <div className="m-auto">
                <TooltipHost content={languageStrings.Home} directionalHint={DirectionalHint.topCenter} styles={tooltipStyles}>
                    <Icon
                        className={`${!isCameraDeviceActive ? "hover:cursor-pointer hover:text-gray-200" : "text-gray-700 hover:cursor-default"} text-3xl md:text-4xl xl:text-5xl`}
                        iconName="Home"
                        onClick={() => onNavigate(AppPage.HomePage)}
                    />
                </TooltipHost>
            </div>
            <div className="m-auto">
                <TooltipHost content={languageStrings.DailyCmmunication} directionalHint={DirectionalHint.topCenter} styles={tooltipStyles}>
                    <Icon
                        className={`${!isCameraDeviceActive ? "hover:cursor-pointer hover:text-gray-200" : "text-gray-700 hover:cursor-default"} text-3xl md:text-4xl xl:text-5xl`}
                        iconName="Camera"
                        onClick={() => onNavigate(AppPage.InstaPage)}
                    ></Icon>
                </TooltipHost>
            </div>
            <div className="m-auto">
                <TooltipHost content={languageStrings.Methodology} directionalHint={DirectionalHint.topCenter} styles={tooltipStyles}>
                    <div className="inline-flex relative">
                        <Icon
                            className={`m-auto ${!isCameraDeviceActive ? "hover:cursor-pointer hover:text-gray-200" : "text-gray-700 hover:cursor-default"} text-3xl md:text-4xl xl:text-5xl`}
                            iconName="PenWorkspace"
                            onClick={() => onNavigate(AppPage.Methodology)}
                        ></Icon>
                    </div>
                </TooltipHost>
            </div>
            <div className="m-auto">
                <TooltipHost content={languageStrings.WorkingDocuments} directionalHint={DirectionalHint.topCenter} styles={tooltipStyles}>
                    <div className="inline-flex relative">
                        <Icon
                            className={`m-auto ${!isCameraDeviceActive ? "hover:cursor-pointer hover:text-gray-200" : "text-gray-700 hover:cursor-default"} text-3xl md:text-4xl xl:text-5xl`}
                            iconName="Documentation"
                            onClick={() => onNavigate(AppPage.WorkDocumentPage)}
                        ></Icon>
                        {werkdocumentenCounts && werkdocumentenCounts.newFileCount > 0 && <div
                            className="inline-flex absolute top-0 -right-2 justify-center items-center w-6 h-6 text-sm font-bold  text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
                            {werkdocumentenCounts.newFileCount}
                        </div>}
                    </div>
                </TooltipHost>
            </div>
            <div className="m-auto">
                <TooltipHost content={languageStrings.Reports} directionalHint={DirectionalHint.topCenter} styles={tooltipStyles}>
                    <div className="inline-flex relative">
                        <Icon
                            className={`m-auto ${!isCameraDeviceActive ? "hover:cursor-pointer hover:text-gray-200" : "text-gray-700 hover:cursor-default"} text-3xl md:text-4xl xl:text-5xl`}
                            iconName="DietPlanNotebook"
                            onClick={() => onNavigate(AppPage.ReportPage)}
                        ></Icon>
                        {verslagenCounts && verslagenCounts.newFileCount > 0 && <div
                            className="inline-flex absolute top-0 -right-2 justify-center items-center w-6 h-6 text-sm font-bold  text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
                            {verslagenCounts.newFileCount}
                        </div>}
                    </div>
                </TooltipHost>
            </div>
            <div className="m-auto">
                <TooltipHost content={languageStrings.Archive} directionalHint={DirectionalHint.topCenter} styles={tooltipStyles}>
                    <div className="inline-flex relative">
                        <Icon
                            className={`m-auto ${!isCameraDeviceActive ? "hover:cursor-pointer hover:text-gray-200" : "text-gray-700 hover:cursor-default"} text-3xl md:text-4xl xl:text-5xl`}
                            iconName="Archive"
                            onClick={() => onNavigate(AppPage.ArchivePage)}
                        ></Icon>
                        {archiefCounts && archiefCounts.newFileCount > 0 && <div
                            className="inline-flex absolute top-0 -right-2 justify-center items-center w-6 h-6 text-sm font-bold  text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
                            {archiefCounts.newFileCount}
                        </div>}
                    </div>

                </TooltipHost>
            </div>
            <div className="m-auto">
                <TooltipHost content={languageStrings.BrumGeneral} directionalHint={DirectionalHint.topCenter} styles={tooltipStyles}>
                    <div className="inline-flex relative">
                        <Icon
                            className={`m-auto ${!isCameraDeviceActive ? "hover:cursor-pointer hover:text-gray-200" : "text-gray-700 hover:cursor-default"} text-3xl md:text-4xl xl:text-5xl`}
                            iconName="FolderOpen"
                            onClick={() => onNavigate(AppPage.BrumGeneralPage)}
                        ></Icon>
                        {brumAlgemeenCounts && brumAlgemeenCounts.newFileCount > 0 && <div
                            className="inline-flex absolute top-0 -right-2 justify-center items-center w-6 h-6 text-sm font-bold  text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
                            {brumAlgemeenCounts.newFileCount}
                        </div>}
                    </div>
                </TooltipHost>
            </div>
        </div>
    );
};
