import React from "react";
import { AppPage } from "../../helpers/enums";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { useAppDispatch } from "../../redux/hooks";
import { setCurrentPage } from "../../redux/slices/navigationSlice";
import { InstaCard } from "../../_components/page/Insta/InstaCard";

export const AdminPage: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);

    const dispatch = useAppDispatch();

    return (
        <div className="h-[100%] overflow-scroll overflow-x-hidden p-4 scrollbar-thin theme-scrollbar theme-scrollbar-track">
            <>
                <div className="h-full flex flex-col lg:flex-row xl:mx-[20%] py-[10%] md:py-0 gap-2">
                    <InstaCard iconName="Education" label={languageStrings.Children} cardClicked={() => dispatch(setCurrentPage(AppPage.AdminChildrenPage))} />
                    <InstaCard iconName="People" label={languageStrings.Users} cardClicked={() => dispatch(setCurrentPage(AppPage.AdminUsersPage))} />
                </div>
            </>
        </div>
    );
};
