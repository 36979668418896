export const getBase64 = (file: any): Promise<string> => {
    return new Promise((resolve) => {
        let baseURL = "";
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
            baseURL = reader.result as string;
            resolve(baseURL);
        };
    });
};


export const filterArray = (arr1: any[], arr2: any[]) => {
    const fileteredArray: any[] = [];
    arr1.forEach((item1) => {
        const foundItem = arr2.find(x => x.id === item1.id);
        if (!foundItem) {
            fileteredArray.push(item1);
        }
    });

    return fileteredArray;
};