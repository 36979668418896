import { IFile } from "../../interfaces/IFile";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // import plugin

import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { AppPage, FileType, UserRole } from "../../helpers/enums";
import { downloadFileStream, getFileUrl } from "../../services/fileService";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { getFileTypeIconProps, initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import ViewSDKClient from "../../pdf/ViewSDKClient";
import { ApprovalFolderName } from "../../helpers/constants";
dayjs.extend(utc);

initializeFileTypeIcons();

export interface IFileListItemProps {
    file: IFile;
    hasElevatedPermissions: boolean;
    onEditClicked: (readOnly: boolean) => void;
}

export const FileListItem: React.FunctionComponent<IFileListItemProps> = ({ file, hasElevatedPermissions, onEditClicked }: IFileListItemProps) => {
    const languageStrings = React.useContext(LanguageContext);

    // const selectedPatient = useAppSelector((state) => state.user.selectedPatient);
    const user = useAppSelector((state) => state.user.user);
    const selectedFolder = useAppSelector((state) => state.navigation.selectedFolder);
    const currentPage = useAppSelector((state) => state.navigation.currentPage);

    const [thumbUrl, setThumbUrl] = React.useState<string>("");
    const [isDownloading, setIsDownloading] = React.useState(false);
    const [isApprover, setIsApprover] = React.useState(false);

    const viewSDKClient = new ViewSDKClient();

    React.useEffect(() => {
        (async () => {
            if (file && file.thumbnailData) {
                const base64Response = await fetch(`data:image/jpeg;base64,${file.thumbnailData}`);
                setThumbUrl(URL.createObjectURL(await base64Response.blob()));
            }
        })();
    }, [file]);

    React.useEffect(() => {
        setIsApprover(user?.roles.find((x) => x.id === UserRole.Goedkeurder || x.id === UserRole.Admin) ? true : false);
    }, [user]);

    const getFileExtension = (name: string) => {
        const fileParts = name?.split(".");
        return fileParts?.length > 0 ? fileParts[fileParts.length - 1] : "";
    };

    return (
        <div
            className={`grid grid-cols-12 mb-3 pb-3 ${!file.approved && selectedFolder?.folderName !== ApprovalFolderName ? "bg-gray-200" : "shadow-purple-200 shadow-lg border-purple-200"
                }  border-2  mx-2 min-h-[200px] max-h-[200px]`}
        >
            <div className="col-span-5 md:col-span-5 p-2 m-auto ">
                {file.type === FileType.Image && (
                    <img
                        onContextMenu={(e) => e.preventDefault()}
                        alt={file.description}
                        src={`data:image/jpeg;base64,${file.thumbnailData}`}
                        className={`h-[180px] max-h-[180px] object-cover ${!file.approved && selectedFolder?.folderName !== ApprovalFolderName ? "opacity-50" : ""}`}
                    />
                )}
                {file.type === FileType.Video && (
                    <video
                        onContextMenu={(e) => e.preventDefault()}
                        src={`${process.env.REACT_APP_API_URL}/api/files/download?FileId=${file.id}&PatientId=${file.patientId}&FileName=${file.name}&AccessToken=${user?.token}`}
                        className={`h-[180px] max-h-[180px] ${!file.approved && selectedFolder?.folderName !== ApprovalFolderName ? "opacity-50" : ""}`}
                        controls={!file.approved && selectedFolder?.folderName !== ApprovalFolderName ? false : true}
                        poster={thumbUrl}
                        preload="none"
                        muted
                        playsInline
                    />
                )}
                {file.type === FileType.File && (
                    file.thumbnailData
                        ? <img
                            onContextMenu={(e) => e.preventDefault()}
                            alt={file.description}
                            src={`data:image/jpeg;base64,${file.thumbnailData}`}
                            className={`h-[180px] max-h-[180px] object-cover ${!file.approved && selectedFolder?.folderName !== ApprovalFolderName ? "opacity-50" : ""}`}
                        />
                        : <div className="p-1 ">
                            <Icon {...getFileTypeIconProps({ extension: getFileExtension(file.name ?? ""), size: 96, imageFileType: "svg" })} />
                        </div>
                )}
                {file.type === FileType.Note && (
                    <div className="p-1 flex flex-col">
                        <div className="pt-4"></div>
                        <div>
                            <Icon className={`text-8xl sm:text-9xl ${!file.approved && selectedFolder?.folderName !== ApprovalFolderName ? "text-gray-400" : "text-purple-900"}`} iconName="EditNote" />
                        </div>
                    </div>
                )}
            </div>
            {(file.type === FileType.Video || file.type === FileType.Image) && (
                <div className="col-span-7 p-2 flex flex-col overflow-hidden">
                    <div className="grid grid-cols-3">
                        <div className="text-lg text-purple-900 truncate font-semibold">
                            {file.isNew && <Icon className="text-purple-900 text-sm pr-2" iconName="Glimmer" />}
                            {file.type === FileType.Video ? languageStrings.Video : languageStrings.Image}
                        </div>
                        <div className="col-span-2 my-auto text-right text-sm truncate pr-2">{dayjs.utc(file.dateCreated).local().format("DD/MM/YYYY h:mm A")}</div>
                    </div>

                    <div className="text-sm truncate">
                        {file.patientFirstName ?? ""} {file.patientLastName ?? ""} ({file.createdBy})
                    </div>

                    <div className="text-gray-600 text-sm grow list-item-text overflow-y-scroll scrollbar-thin theme-scrollbar theme-scrollbar-track pr-2 pt-2">
                        {file.description ? file.description : languageStrings.NoDescription}
                    </div>
                    <div className="text-gray-600 text-sm flex flex-row justify-end pr-2 pt-2">
                        <div className="my-auto grow text-sm truncate">{file.addressedTo ? `@${file.addressedTo}` : ""}</div>
                        <div className="my-auto">
                            <Icon
                                className="text-xl text-purple-900 hover:cursor-pointer"
                                iconName={isApprover || file.userId === user?.id ? "Edit" : "View"}
                                onClick={() => onEditClicked(!(isApprover || file.userId === user?.id))}
                            />
                        </div>
                        <div className="my-auto">
                            {!isDownloading && (hasElevatedPermissions || user?.allowDownload || file.allowDownload) && (
                                <Icon
                                    className="text-xl text-purple-900 hover:cursor-pointer ml-4"
                                    iconName="Download"
                                    onClick={async () => {
                                        setIsDownloading(true);
                                        let filename = `${file.patientFirstName}_${file.patientLastName}_${file.dateCreated}.${file.type === FileType.Video ? "webm" : "jpeg"}`;
                                        await downloadFileStream(file.id!.toString(), file.patientId?.toString(), file.name ?? filename, user?.token);
                                        setIsDownloading(false);
                                    }}
                                />
                            )}
                            {isDownloading && <Spinner className="pt-2" size={SpinnerSize.small}></Spinner>}
                        </div>
                    </div>
                </div>
            )}
            {file.type === FileType.File && (
                <div className="col-span-7 p-2 flex flex-col overflow-hidden">
                    <div className="flex flex-row">
                        <div className="text-lg text-purple-900 truncate font-semibold grow">
                            {file.isNew && <Icon className="text-purple-900 text-sm pr-2" iconName="Glimmer" />}
                            {file.name}
                        </div>
                        <div className="my-auto text-sm truncate pr-2">{dayjs.utc(file.dateCreated).local().format("DD/MM/YYYY h:mm A")}</div>
                    </div>
                    <div className="text-sm truncate">
                        {file.patientFirstName ?? ""} {file.patientLastName ?? ""} ({file.createdBy})
                    </div>
                    <div className="text-gray-600 text-sm grow list-item-text overflow-y-scroll scrollbar-thin theme-scrollbar theme-scrollbar-track pr-2 pt-2">
                        {file.description ? file.description : languageStrings.NoDescription}
                    </div>
                    <div className="text-gray-600 text-sm flex flex-row justify-end pr-2">
                        {file.name?.includes(".pdf") && <div className="mr-4">
                            <Icon
                                className="text-xl text-purple-900 hover:cursor-pointer pt-2"
                                iconName={"Pdf"}
                                onClick={() => {
                                    const url = getFileUrl(file.id!.toString(), file.patientId?.toString(), user?.token);
                                    console.log("url", url);
                                    viewSDKClient.ready().then(() => {
                                        viewSDKClient.previewFile(
                                            "pdf-div",
                                            {
                                                embedMode: "LIGHT_BOX",
                                                exitPDFViewerType: "CLOSE",
                                                defaultViewMode: "FIT_WIDTH",
                                            },
                                            file.name || "",
                                            url
                                        );
                                    });
                                }}
                            />
                        </div>}
                        <div className="mr-4">
                            <Icon
                                className="text-xl text-purple-900 hover:cursor-pointer pt-2"
                                iconName={isApprover || file.userId === user?.id ? "Edit" : "View"}
                                onClick={() => onEditClicked(!(isApprover || file.userId === user?.id))}
                            />
                        </div>
                        <div>
                            {(!isDownloading && (currentPage !== AppPage.HomePage || hasElevatedPermissions || file.allowDownload || (user?.allowDownload && currentPage === AppPage.HomePage))) && (
                                <Icon
                                    className="text-xl text-purple-900 hover:cursor-pointer pt-2"
                                    iconName="Download"
                                    onClick={async () => {
                                        setIsDownloading(true);
                                        await downloadFileStream(file.id!.toString(), file.patientId?.toString(), file.name as string, user?.token);
                                        setIsDownloading(false);
                                    }}
                                />
                            )}
                            {isDownloading && <Spinner className="pt-2" size={SpinnerSize.small}></Spinner>}
                        </div>
                    </div>
                </div>
            )}
            {file.type === FileType.Note && (
                <div className="col-span-7 p-2 flex flex-col overflow-hidden">
                    <div className="grid grid-cols-3">
                        <div className="text-lg text-purple-900 truncate font-semibold">
                            {file.isNew && <Icon className="text-purple-900 text-sm pr-2" iconName="Glimmer" />}
                            {languageStrings.Note}
                        </div>
                        <div className="col-span-2 my-auto text-right text-sm truncate pr-2">{dayjs.utc(file.dateCreated).local().format("DD/MM/YYYY h:mm A")}</div>
                    </div>
                    <div className="text-sm truncate">
                        {file.patientFirstName ?? ""} {file.patientLastName ?? ""} ({file.createdBy})
                    </div>

                    <div className="text-gray-600 text-sm grow list-item-text overflow-y-scroll scrollbar-thin theme-scrollbar theme-scrollbar-track pr-2 pt-2">
                        {file.description ? file.description : languageStrings.NoDescription}
                    </div>
                    <div className="text-gray-600 text-sm flex flex-row pr-2 pt-2">
                        <div className="my-auto grow text-sm truncate">{file.addressedTo ? `@${file.addressedTo}` : ""}</div>
                        <div className="my-auto">
                            <Icon
                                className="text-xl text-purple-900 hover:cursor-pointer"
                                iconName={isApprover || file.userId === user?.id ? "Edit" : "View"}
                                onClick={() => onEditClicked(!(isApprover || file.userId === user?.id))}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
