import { IPanelStyles, Panel, PanelType, Persona, PersonaSize, SearchBox, Stack } from "@fluentui/react";
import React from "react";
import { IPatient } from "../../interfaces/IPatient";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getPatients } from "../../services/patientService";
import { BrumSpinner } from "../BrumSpinner";
import { NoItemsFound } from "../NoItemsFound";
import { isMobile } from "react-device-detect";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { IUser } from "../../interfaces/IUser";
import { filterArray } from "../../helpers/utils";
import { setActivePatients } from "../../redux/slices/navigationSlice";

const panelStyles: Partial<IPanelStyles> = {
    content: {
        overflowX: "hidden",
        touchAction: "true",
        height: "calc(100vh - 60px);",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
    },
};
export interface ISelectPatientPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    itemSelected: (patient: IPatient) => void;
    isAdminMode: boolean;
    selectedUser?: IUser;
}

export const SelectPatientPanel: React.FunctionComponent<ISelectPatientPanelProps> = ({ isOpen, dismissPanel, itemSelected, isAdminMode, selectedUser }: ISelectPatientPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.user.user);
    const selectedPatient = useAppSelector((state) => state.user.selectedPatient);
    const activePatients = useAppSelector((state) => state.navigation.activePatients);

    const [patients, setPatients] = React.useState<IPatient[]>([]);
    const [patientList, setPatientList] = React.useState<any[]>([]);
    const [isBusy, setisBusy] = React.useState(false);
    const [searchText, setSearchText] = React.useState<string>("");

    React.useEffect(() => {
        (async () => {
            setisBusy(true);
            if (isAdminMode) {
                const patientsRes: IPatient[] = activePatients.length > 0 ? activePatients : await getPatients(true);
                dispatch(setActivePatients(patientsRes));

                if (selectedUser && selectedUser.patients?.length > 0) {
                    setPatients(filterArray(patientsRes, selectedUser.patients));
                } else {
                    setPatients(patientsRes);
                }
                //
            } else {
                if (selectedPatient) {
                    setPatients([{ id: 0, firstName: languageStrings.AllChildren, users: [] }, ...user?.patients!]);
                } else {
                    setPatients(user?.patients ?? []);
                }
            }
            setisBusy(false);
        })();
    }, [user, activePatients]);

    React.useEffect(() => {
        setPatientList(
            (searchText
                ? patients.filter(p => (p.firstName && p.firstName.toUpperCase().indexOf(searchText.toUpperCase()) > -1) || (p.lastName && p.lastName.toUpperCase().indexOf(searchText.toUpperCase()) > -1))
                : patients)
                .map((patient, index) => {
                    return (
                        <Persona
                            className="hover: cursor-pointer hover:bg-gray-200 h-16 pl-2 border-b last:border-b-0 border-gray-200"
                            imageUrl={`${patient.profileImage ? `data:image/*;base64,${patient.profileImage}` : undefined} `}
                            text={`${patient.firstName ? patient.firstName : ""} ${patient.lastName ? patient.lastName : ""}`}
                            size={PersonaSize.size40}
                            key={index}
                            onClick={() => {
                                itemSelected(patient);
                            }}
                        />
                    );
                })
        );
    }, [patients, searchText]);

    const searchChanged = (value: string) => {
        setSearchText(value);
    };

    return (
        <Panel
            headerText={languageStrings.SelectChild}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            closeButtonAriaLabel="Close"
            styles={panelStyles}
            type={PanelType.custom}
            customWidth={`${isMobile ? "100%" : "500px"}`}
            allowTouchBodyScroll
        >
            <>
                {!isBusy && patients.length > 0 && <>
                    <Stack verticalAlign="center">
                        <div style={{ margin: 15 }}>
                            <SearchBox placeholder={languageStrings.Search} underlined onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => searchChanged(newValue ?? "")} />
                        </div>
                        {patientList}
                    </Stack></>}
                {!isBusy && patients.length === 0 && <NoItemsFound />}
                {isBusy && <BrumSpinner />}
            </>
        </Panel>
    );
};
