import { DefaultButton, Icon, Label } from "@fluentui/react";
import React from "react";
import { isMobile, isBrowser } from "react-device-detect";
import { BrumSpinner } from "../../BrumSpinner";
import { useAppDispatch } from "../../../redux/hooks";
import { setIsCameraDeviceActive, setMediaMode } from "../../../redux/slices/instaSlice";
import { MediaMode } from "../../../helpers/enums";
import { getBase64 } from "../../../helpers/utils";
import { LanguageContext } from "../../../helpers/LocalizationModule";

export interface IInstaCameraProps {
    photoChanged: (data: string, uploadedFormFile?: any) => void;
}

export const InstaCamera: React.FunctionComponent<IInstaCameraProps> = ({ photoChanged }: IInstaCameraProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const dispatch = useAppDispatch();

    const videoRef = React.useRef<any>(null);
    const photoRef = React.useRef(null);
    const [stream, setStream] = React.useState<MediaStream | null>();

    const [hasPhoto, setHasPhoto] = React.useState(false);
    const fileUploadInput = React.useRef<HTMLInputElement>(null);

    const [searchingCamera, setSearchingCamera] = React.useState(true);
    const [cameraSupported, setCameraSupported] = React.useState(false);

    const stopVideoAudioTracks = () => {
        if (stream) {
            stream.getTracks().forEach(function (track) {
                if (track.readyState === "live") {
                    track.stop();
                }
            });
        }
    };

    React.useEffect(() => {
        (async () => {
            const devices: MediaDeviceInfo[] = (await navigator.mediaDevices.enumerateDevices()).filter((x) => x.kind === "videoinput");
            if (devices?.length > 0) {
                setCameraSupported(true);
            }
            setSearchingCamera(false);
        })();
    }, []);

    React.useEffect(() => {
        if (isBrowser && cameraSupported) {
            (async () => {
                dispatch(setIsCameraDeviceActive(true));
                let constraints = {
                    audio: false,
                    video: {
                        width: {
                            min: 640,
                            max: 1024,
                        },
                        height: {
                            min: 480,
                            max: 768,
                        },
                    },
                };

                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                setStream(stream);
                let video = videoRef.current;
                if (video) {
                    video.srcObject = stream;
                    video.play();
                }

                return () => {
                    stopVideoAudioTracks();
                };
            })();
        }
    }, [videoRef, cameraSupported, dispatch]);

    const takePhotoDesktop = () => {
        const width = 640;
        const height = 480;

        let video = videoRef.current;
        let photo = photoRef.current as any;

        photo.width = width;
        photo.height = height;

        let ctx = photo.getContext("2d");
        ctx.drawImage(video, 0, 0, width, height);
        setHasPhoto(true);
        photoChanged(photo.toDataURL("image/jpeg"));
        stopVideoAudioTracks();
        dispatch(setIsCameraDeviceActive(false));
    };

    const cancelPhoto = () => {
        stopVideoAudioTracks();
        dispatch(setIsCameraDeviceActive(false));
        dispatch(setMediaMode(MediaMode.None));
    };

    const takePhotoMobile = async (e: any) => {
        let file = e.target.files[0];
        const res = await getBase64(file);
        photoChanged(res, file);
    };

    return (
        <div className="flex flex-col">
            {searchingCamera && (
                <div className="text-center mt-10">
                    <div>
                        <BrumSpinner />
                    </div>
                    <Label disabled className="text-lg">
                        {languageStrings.SearchingForCameraDevices}
                    </Label>
                </div>
            )}
            {!searchingCamera && !cameraSupported && (
                <div className="text-center mt-10">
                    <Label disabled className="text-lg">
                        {languageStrings.NoCamaraFound}
                    </Label>
                </div>
            )}
            {cameraSupported && isBrowser && (
                <div>
                    {!stream && (
                        <div>
                            <BrumSpinner />
                        </div>
                    )}
                    {stream && (
                        <div className="p-2 pb-2 mx-auto text-center">
                            <DefaultButton onClick={() => cancelPhoto()}>{languageStrings.Cancel}</DefaultButton>
                        </div>
                    )}
                    <video ref={videoRef} className="w-full md:w-[640px] mx-auto"></video>
                    <div className={`fixed ${hasPhoto ? "hasPhoto" : ""}`}>
                        <canvas ref={photoRef}></canvas>
                    </div>
                    {stream && (
                        <div className="p-2 pb-1 mx-auto text-center">
                            <Icon iconName="Camera" className="text-4xl mx-auto text-purple-700 hover:cursor-pointer" onClick={() => takePhotoDesktop()} />
                        </div>
                    )}
                </div>
            )}
            {cameraSupported && isMobile && (
                <div className="py-5 text-center">
                    <label htmlFor="cameraFileInput">
                        <DefaultButton onClick={() => fileUploadInput.current?.click()}>{languageStrings.OpenCamera}</DefaultButton>

                        <input className="hidden" ref={fileUploadInput} id="cameraFileInput" type="file" accept="image/*" capture="environment" onChange={(e) => takePhotoMobile(e)} />
                    </label>
                </div>
            )}
        </div>
    );
};
