import { ICommandBarItemProps, IContextualMenuItem, Label } from "@fluentui/react";
import React from "react";
import { FileCategory, FolderSortByOption, UserType } from "../helpers/enums";
import { LanguageContext } from "../helpers/LocalizationModule";
import { IPatient } from "../interfaces/IPatient";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setRefreshFolderContent } from "../redux/slices/navigationSlice";
import { setSelectedPatient } from "../redux/slices/userSlice";
import { BrumCommandBar } from "../_components/BrumCommandBar";
import { PageFolderItemList } from "../_components/page/PageFolderItemList";
import { PageFolderList } from "../_components/page/PageFolderList";
import { SelectPatientPanel } from "../_components/panels/SelectPatientPanel";
import { UploadLocalFilePanel } from "../_components/panels/UploadLocalFilePanel";
import { ApprovalFolderName } from "../helpers/constants";

export const MethodolgyPage: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);
    const dispatch = useAppDispatch();

    const selectedFolder = useAppSelector((state) => state.navigation.selectedFolder);
    const selectedPatient = useAppSelector((state) => state.user.selectedPatient);
    const notApprovedCount = useAppSelector((state) => state.navigation.notApprovedCount);
    const createdByList = useAppSelector((state) => state.navigation.createdByList);
    const addressedToList = useAppSelector((state) => state.navigation.addressedToList);

    const [commandBarItems, setCommandBarItems] = React.useState<ICommandBarItemProps[]>([]);
    const [commandBarFarItems, setCommandBarFarItems] = React.useState<ICommandBarItemProps[]>([]);
    const [selectedCreatedByOptions, setSelectedCreatedByOptions] = React.useState<string[]>([]);
    const [selectedAddressedToOptions, setSelectedAddressedToOptions] = React.useState<string[]>([]);

    const [isUploadLocalFilePanelOpen, setIsUploadLocalFilePanelOpen] = React.useState(false);
    const [refreshList, setRefreshList] = React.useState(false);
    const [isSelectPatientsPanelOpen, setIsSelectPatientsPanelOpen] = React.useState(false);

    const [selectedFolderSortOption, setSelectedFolderSortOption] = React.useState<FolderSortByOption>(FolderSortByOption.NameAscending);
    const [selectedFileSortOption, setSelectedFileSortOption] = React.useState<FolderSortByOption>(FolderSortByOption.DateDescending);

    React.useEffect(() => {
        setCommandBarItems([
            // {
            //     key: "Upload",
            //     text: languageStrings.Upload,
            //     iconProps: { iconName: "Upload" },
            //     onClick: () => {
            //         if (!selectedPatient) {
            //             setIsSelectPatientsPanelOpen(true);
            //         } else {
            //             setIsUploadLocalFilePanelOpen(true);
            //         }
            //     },
            // },
        ]);

        const farItems: ICommandBarItemProps[] = [
            {
                key: "Sort",
                text: languageStrings.SortBy,
                iconProps: { iconName: "Sort" },
                subMenuProps: {
                    items: [],
                },
            },
        ];

        // if (selectedFolder) {
        //     farItems[0].subMenuProps?.items.push(
        //         {
        //             key: "dateAsc",
        //             text: languageStrings.DateAscending,
        //             iconProps: { iconName: "Ascending" },
        //             checked: selectedFileSortOption === FolderSortByOption.DateAscending,
        //             canCheck: true,
        //             onClick: () => {
        //                 setSelectedFileSortOption(FolderSortByOption.DateAscending);
        //             },
        //         },
        //         {
        //             key: "dateDesc",
        //             text: languageStrings.DateDescending,
        //             iconProps: { iconName: "Descending" },
        //             canCheck: true,
        //             checked: selectedFileSortOption === FolderSortByOption.DateDescending,
        //             onClick: () => {
        //                 setSelectedFileSortOption(FolderSortByOption.DateDescending);
        //             },
        //         }
        //     );
        // } else {
        //     farItems[0].subMenuProps?.items.push(
        //         {
        //             key: "nameAsc",
        //             text: languageStrings.NameAscending,
        //             iconProps: { iconName: "Ascending" },
        //             checked: selectedFolderSortOption === FolderSortByOption.NameAscending,
        //             canCheck: true,
        //             onClick: () => {
        //                 setSelectedFolderSortOption(FolderSortByOption.NameAscending);
        //             },
        //         },
        //         {
        //             key: "nameDesc",
        //             text: languageStrings.NameDescending,
        //             iconProps: { iconName: "Descending" },
        //             canCheck: true,
        //             checked: selectedFolderSortOption === FolderSortByOption.NameDescending,
        //             onClick: () => {
        //                 setSelectedFolderSortOption(FolderSortByOption.NameDescending);
        //             },
        //         }
        //     );
        // }
        farItems[0].subMenuProps?.items.push(
            {
                key: "dateAsc",
                text: languageStrings.DateAscending,
                iconProps: { iconName: "Ascending" },
                checked: selectedFileSortOption === FolderSortByOption.DateAscending,
                canCheck: true,
                onClick: () => {
                    setSelectedFileSortOption(FolderSortByOption.DateAscending);
                },
            },
            {
                key: "dateDesc",
                text: languageStrings.DateDescending,
                iconProps: { iconName: "Descending" },
                canCheck: true,
                checked: selectedFileSortOption === FolderSortByOption.DateDescending,
                onClick: () => {
                    setSelectedFileSortOption(FolderSortByOption.DateDescending);
                },
            }
        );

        if (selectedFolder && selectedFolder?.folderName === ApprovalFolderName && createdByList?.length > 0) {
            let items: IContextualMenuItem[] = [];

            items.push({
                key: "FilterByAllCreatedBy",
                text: languageStrings.FilterByAll,
                iconProps: { iconName: "AllApps" },
                checked: selectedCreatedByOptions.length === 0,
                canCheck: true,
                onClick: () => {
                    setSelectedCreatedByOptions([]);
                },
            });

            createdByList.forEach((item, index) => {
                items.push({
                    key: `cb_${index}_${item}`,
                    text: item,
                    iconProps: { iconName: "Contact" },
                    checked: selectedCreatedByOptions.includes(item),
                    canCheck: true,
                    onClick: () => {
                        if (selectedCreatedByOptions.includes(item)) {
                            setSelectedCreatedByOptions([...selectedCreatedByOptions.filter((x) => x !== item)]);
                        } else {
                            setSelectedCreatedByOptions([...selectedCreatedByOptions, item]);
                        }
                    },
                });
            });

            farItems.push({
                key: "FilterCreatedBy",
                text: languageStrings.CreatedBy,
                iconProps: { iconName: "Add" },
                subMenuProps: {
                    items,
                },
            });
        }

        if (selectedFolder && selectedFolder?.folderName !== ApprovalFolderName && addressedToList?.length > 0) {
            let items: IContextualMenuItem[] = [];

            items.push({
                key: "FilterByAllAddressedTo",
                text: languageStrings.FilterByAll,
                iconProps: { iconName: "AllApps" },
                checked: selectedAddressedToOptions.length === 0,
                canCheck: true,
                onClick: () => {
                    setSelectedAddressedToOptions([]);
                },
            });

            addressedToList.forEach((item, index) => {
                items.push({
                    key: `at_${index}_${item}`,
                    text: item,
                    iconProps: { iconName: "Contact" },
                    checked: selectedAddressedToOptions.includes(item),
                    canCheck: true,
                    onClick: () => {
                        if (selectedAddressedToOptions.includes(item)) {
                            setSelectedAddressedToOptions([...selectedAddressedToOptions.filter((x) => x !== item)]);
                        } else {
                            setSelectedAddressedToOptions([...selectedAddressedToOptions, item]);
                        }
                    },
                });
            });

            farItems.push({
                key: "FilterCreatedBy",
                text: languageStrings.MessageTo,
                iconProps: { iconName: "Message" },
                subMenuProps: {
                    items,
                },
            });
        }

        if (notApprovedCount > 0) {
            farItems.unshift({
                key: "Count",
                text: `${notApprovedCount} items waiting approval`,
                disabled: true,
            });
        }

        setCommandBarFarItems(farItems);
    }, [
        selectedPatient,
        selectedFileSortOption,
        selectedFolderSortOption,
        notApprovedCount,
        selectedFolder,
        createdByList,
        addressedToList,
        selectedCreatedByOptions,
        selectedAddressedToOptions,
        setSelectedCreatedByOptions,
    ]);

    const patientSelected = (patient: IPatient) => {
        dispatch(setSelectedPatient(patient.id! > 0 ? patient : undefined));
        setIsSelectPatientsPanelOpen(false);
        setIsUploadLocalFilePanelOpen(true);
    };

    return (
        <>
            <BrumCommandBar commandBarItems={commandBarItems} commandBarFarItems={commandBarFarItems} />

            <PageFolderItemList
                fileCategory={FileCategory.Methodolgy}
                sortOption={selectedFileSortOption}
            />

            {isUploadLocalFilePanelOpen && (
                <UploadLocalFilePanel
                    isOpen={isUploadLocalFilePanelOpen}
                    dismissPanel={() => {
                        setIsUploadLocalFilePanelOpen(false);
                    }}
                    fileCategory={FileCategory.Reports}
                    onSuccess={() => {
                        dispatch(setRefreshFolderContent());
                        setIsUploadLocalFilePanelOpen(false);
                        setRefreshList((prev) => !prev);
                    }}
                />
            )}
            {isSelectPatientsPanelOpen && (
                <SelectPatientPanel
                    isOpen={isSelectPatientsPanelOpen}
                    dismissPanel={() => {
                        setIsSelectPatientsPanelOpen(false);
                    }}
                    itemSelected={patientSelected}
                    isAdminMode={false}
                />
            )}
        </>
    );
};
