import { DefaultButton, Label } from "@fluentui/react";
import React from "react";
import { isMobile, isBrowser } from "react-device-detect";
import { MediaMode } from "../../../helpers/enums";
import { LanguageContext } from "../../../helpers/LocalizationModule";
import { getBase64 } from "../../../helpers/utils";
import { useAppDispatch } from "../../../redux/hooks";
import { setIsCameraDeviceActive, setMediaMode } from "../../../redux/slices/instaSlice";
import { BrumSpinner } from "../../BrumSpinner";

export interface IInstaVideoProps {
    videoBlobChanged: (blob: Blob) => void;
    videoBase64Changed: (videoBase64?: string, uploadedFormFile?: any) => void;
}

export const InstaVideo: React.FunctionComponent<IInstaVideoProps> = ({ videoBlobChanged, videoBase64Changed }: IInstaVideoProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const dispatch = useAppDispatch();
    const videoRef = React.useRef<any>(null);

    const stopped = React.useRef<boolean>(false);

    const [stream, setStream] = React.useState<MediaStream | null>();
    const [mimeType] = React.useState("video/webm");
    const [shouldStop, setShouldStop] = React.useState(false);
    const [startRecording, setStartRecording] = React.useState(false);

    const fileUploadInput = React.useRef<HTMLInputElement>(null);

    const [searchingCamera, setSearchingCamera] = React.useState(true);
    const [cameraSupported, setCameraSupported] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            const devices: MediaDeviceInfo[] = (await navigator.mediaDevices.enumerateDevices()).filter((x) => x.kind === "videoinput");
            if (devices?.length > 0) {
                setCameraSupported(true);
            }
            setSearchingCamera(false);
        })();
    }, []);

    const recordVideo = async () => {
        dispatch(setIsCameraDeviceActive(true));
        setShouldStop(false);
        let constraints = {
            audio: {
                echoCancellation: true,
            },
            video: {
                width: {
                    min: 640,
                    max: 1024,
                },
                height: {
                    min: 480,
                    max: 768,
                },
            },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        setStream(stream);
        let video = videoRef.current;
        if (video) {
            video.srcObject = stream;
            video.play();
        }
        setStartRecording(true);
    };

    const stopVideoAudioTracks = React.useCallback(() => {
        if (stream) {
            stream.getTracks().forEach(function (track) {
                if (track.readyState === "live") {
                    track.stop();
                }
            });
        }
    }, [stream]);

    React.useEffect(() => {
        if (startRecording) {
            let recordedChunks: any[] = [];
            stopped.current = false;
            const mediaRecorder = new MediaRecorder(stream as MediaStream);

            mediaRecorder.ondataavailable = function (e) {
                if (e.data.size > 0) {
                    recordedChunks.push(e.data);
                }

                if (shouldStop === true && stopped.current === false) {
                    setStartRecording(false);
                    stopVideoAudioTracks();
                    mediaRecorder.stop();
                    stopped.current = true;
                }
            };

            mediaRecorder.onstop = function () {
                dispatch(setIsCameraDeviceActive(false));
                const blob = new Blob(recordedChunks, {
                    type: mimeType,
                });
                recordedChunks = [];
                videoBlobChanged(blob);
            };

            mediaRecorder.start(200);
        }
    }, [shouldStop, startRecording, dispatch, mimeType, stopVideoAudioTracks, stream, videoBlobChanged]);

    const takeMobileMobile = async (e: any) => {
        let file = e.target.files[0];
        videoBase64Changed(undefined, file);
    };

    const cancelPhoto = () => {
        stopVideoAudioTracks();
        dispatch(setIsCameraDeviceActive(false));
        dispatch(setMediaMode(MediaMode.None));
    };

    return (
        <div className="flex flex-col">
            {searchingCamera && (
                <div className="text-center mt-10">
                    <div>
                        <BrumSpinner />
                    </div>
                    <Label disabled className="text-lg">
                        {languageStrings.SearchingForCameraDevices}
                    </Label>
                </div>
            )}
            {!searchingCamera && !cameraSupported && (
                <div className="text-center mt-10">
                    <Label disabled className="text-lg">
                        {languageStrings.NoCamaraFound}
                    </Label>
                </div>
            )}
            {cameraSupported && isBrowser && (
                <div>
                    {startRecording && (
                        <div className="p-2 pb-2 mx-auto text-center">
                            <DefaultButton onClick={() => cancelPhoto()}>{languageStrings.Cancel}</DefaultButton>
                        </div>
                    )}
                    {!startRecording && (
                        <div className="py-5 mx-auto text-center">
                            <DefaultButton text={languageStrings.RecordingStart} onClick={() => recordVideo()} />
                        </div>
                    )}
                    <video className="w-[640px] h-[480px] max-h-[480px] mx-auto" ref={videoRef} muted></video>
                    {startRecording && (
                        <div className="py-5 mx-auto text-center">
                            <DefaultButton text={languageStrings.RecordingStop} onClick={() => setShouldStop(true)} />
                        </div>
                    )}
                </div>
            )}
            {cameraSupported && isMobile && (
                <div className="py-5 text-center">
                    <label htmlFor="cameraFileInput">
                        <DefaultButton onClick={() => fileUploadInput.current?.click()}>Open camera</DefaultButton>

                        <input className="hidden" ref={fileUploadInput} id="cameraFileInput" type="file" accept="video/*" capture="environment" onChange={(e) => takeMobileMobile(e)} />
                    </label>
                </div>
            )}
        </div>
    );
};
