import { configureStore } from '@reduxjs/toolkit';
import navigationReducer from './slices/navigationSlice';
import userReducer from './slices/userSlice';
import instaReducer from './slices/instaSlice';

const store = configureStore({
	reducer: {
		insta: instaReducer,
		navigation: navigationReducer,
		user: userReducer,
	},
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
