import { CommandBar, ICommandBarItemProps, SearchBox } from "@fluentui/react";
import React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { LanguageContext } from "../helpers/LocalizationModule";

export interface IBB_CommandBarProps {
    commandBarItems?: ICommandBarItemProps[];
    commandBarFarItems?: ICommandBarItemProps[];
    onSearch?: (value: string) => void;
}

export const BrumCommandBar: React.FunctionComponent<IBB_CommandBarProps> = ({ commandBarItems, commandBarFarItems, onSearch }: IBB_CommandBarProps) => {
    const languageStrings = React.useContext(LanguageContext);

    return (
        <>
            {onSearch && (
                <div className="border-b-2 border-b-gray-200 grid grid-cols-12 bg-white">
                    <div className="col-span-8 md:col-span-10 my-auto md:pr-6">
                        <CommandBar items={commandBarItems ?? []} farItems={commandBarFarItems ?? []} />
                    </div>
                    <div className="col-span-4 md:col-span-2 my-auto pr-6">
                        <SearchBox placeholder={languageStrings.Search} underlined onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => onSearch(newValue ?? "")} />
                    </div>
                </div>
            )}
            {!onSearch && (
                <div className="border-b-2 border-b-gray-200">
                    <CommandBar items={commandBarItems ?? []} farItems={commandBarFarItems ?? []} styles={{ root: { paddingLeft: 0 } }} />
                </div>
            )}
        </>
    );
};
