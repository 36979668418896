import { DefaultButton, Dropdown, DropdownMenuItemType, IDropdownOption, IPanelStyles, MessageBar, MessageBarType, Panel, PanelType, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import React from "react";
import "../../../node_modules/@syncfusion/ej2-inputs/styles/uploader/fabric.css";
import "../../../node_modules/@syncfusion/ej2-inputs/styles/input/fabric.css";
import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
import { FileCategory, FileType, MediaMode, UserType } from "../../helpers/enums";
import { useAppSelector } from "../../redux/hooks";
import { isMobile } from "react-device-detect";
import { UploaderComponent, UploadingEventArgs } from "@syncfusion/ej2-react-inputs";
import { ILocalFileUploadRequest } from "../../interfaces/ILocalFileUploadRequest";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { getPatient } from "../../services/patientService";

const buttonStyles = { root: { marginLeft: 8 } };

const panelStyles: Partial<IPanelStyles> = {
    content: {
        overflowX: "hidden",
        touchAction: "true",
        height: "calc(100vh - 124px);",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
    },
};

export interface IUploadLocalFilePanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    fileCategory: FileCategory;
    onSuccess: () => void;
}

export const UploadLocalFilePanel: React.FunctionComponent<IUploadLocalFilePanelProps> = ({ isOpen, dismissPanel, fileCategory, onSuccess }: IUploadLocalFilePanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const allowedImageExtensions = ["art", "bmp", "blp", "cd5", "cit", "cpt", "cr2", "cut", "dds", "dib", "djvu", "egt", "exif", "gif", "gpl", "grf", "icns", "ico", "iff", "jng", "jpeg", "jpg", "jfif", "jp2", "jps", "lbm", "max", "miff", "mng", "msp", "nef", "nitf", "ota", "pbm", "pc1", "pc2", "pc3", "pcf", "pcx", "pdn", "pgm", "PI1", "PI2", "PI3", "pict", "pct", "pnm", "pns", "ppm", "psb", "psd", "pdd", "psp", "px", "pxm", "pxr", "qfx", "raw", "rle", "sct", "sgi", "rgb", "int", "bw", "tga", "tiff", "tif", "vtf", "xbm", "xcf", "xpm", "3dv", "amf", "ai", "awg", "cgm", "cdr", "cmx", "dxf", "e2d", "egt", "eps", "fs", "gbr", "odg", "svg", "stl", "vrml", "x3d", "sxd", "v2d", "vnd", "wmf", "emf", "art", "xar", "png", "webp", "jxr", "hdp", "wdp", "cur", "ecw", "iff", "lbm", "liff", "nrrd", "pam", "pcx", "pgf", "sgi", "rgb", "rgba", "bw", "int", "inta", "sid", "ras", "sun", "tga", "heic", "heif"];
    const allowedVideoExtensions = ["webm", "mkv", "flv", "vob", "ogv", "ogg", "rrc", "gifv", "mng", "mov", "avi", "qt", "wmv", "yuv", "rm", "asf", "amv", "mp4", "m4p", "m4v", "mpg", "mp2", "mpeg", "mpe", "mpv", "m4v", "svi", "3gp", "3g2", "mxf", "roq", "nsv", "flv", "f4v", "f4p", "f4a", "f4b", "mod"];
    const allowedExtensions = ".art, .bmp, .blp, .cd5, .cit, .cpt, .cr2, .cut, .dds, .dib, .djvu, .egt, .exif, .gif, .gpl, .grf, .icns, .ico, .iff, .jng, .jpeg, .jpg, .jfif, .jp2, .jps, .lbm, .max, .miff, .mng, .msp, .nef, .nitf, .ota, .pbm, .pc1, .pc2, .pc3, .pcf, .pcx, .pdf, .pdn, .pgm, .PI1, .PI2, .PI3, .pict, .pct, .pnm, .pns, .ppm, .psb, .psd, .pdd, .psp, .px, .pxm, .pxr, .qfx, .raw, .rle, .sct, .sgi, .rgb, .int, .bw, .tga, .tiff, .tif, .vtf, .xbm, .xcf, .xpm, .3dv, .amf, .ai, .awg, .cgm, .cdr, .cmx, .dxf, .e2d, .egt, .eps, .fs, .gbr, .odg, .svg, .stl, .vrml, .x3d, .sxd, .v2d, .vnd, .wmf, .emf, .art, .xar, .png, .webp, .jxr, .hdp, .wdp, .cur, .ecw, .iff, .lbm, .liff, .nrrd, .pam, .pcx, .pgf, .sgi, .rgb, .rgba, .bw, .int, .inta, .sid, .ras, .sun, .tga, .heic, .heif, .webm, .mkv, .flv, .vob, .ogv, .ogg, .rrc, .gifv, .mng, .mov, .avi, .qt, .wmv, .yuv, .rm, .asf, .amv, .mp4, .m4p, .m4v, .mpg, .mp2, .mpeg, .mpe, .mpv, .m4v, .svi, .3gp, .3g2, .mxf, .roq, .nsv, .flv, .f4v, .f4p, .f4a, .f4b, .mod"

    const selectedPatient = useAppSelector((state) => state.user.selectedPatient);
    const user = useAppSelector((state) => state.user.user);
    const mediaMode = useAppSelector((state) => state.insta.mediaMode);

    const [uploadObject, setUploadObject] = React.useState<UploaderComponent>();
    // const [files, setFiles] = React.useState<FileInfo[]>([]);
    const [asyncSettings, setAsyncSettings] = React.useState<object>();

    const [isSaving, setIsSaving] = React.useState(false);
    const [isFormValid, setIsFormValid] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [tagUserOptions, setTagUserOptions] = React.useState<IDropdownOption[]>([]);

    const [localFileRequest, setLocalFileRequest] = React.useState<ILocalFileUploadRequest>({
        patientId: 5,
        fileCategoryId: fileCategory,
        description: "",
        type: FileType.File,
    });

    React.useEffect(() => {
        setAsyncSettings({
            saveUrl: `${process.env.REACT_APP_API_URL}/api/files/Upload`,
            removeUrl: ""
        });
    }, []);

    React.useEffect(() => {
        (async () => {
            if (selectedPatient) {
                const patient = await getPatient(selectedPatient.id!.toString());

                const medewerkers = patient.users.filter((x) => x.typeId === UserType.Medewerker);
                const gaurdians = patient.users.filter((x) => x.typeId === UserType.Gaurdian);
                const caretakers = patient.users.filter((x) => x.typeId === UserType.Caretaker);

                const options: IDropdownOption[] = [];

                if (medewerkers.length > 0 && user?.typeId === UserType.Gaurdian) {
                    options.push({ key: "medewerkers", text: languageStrings.BrumBrumEmployees, itemType: DropdownMenuItemType.Header });
                    medewerkers.forEach((medewerker) => {
                        options.push({ key: medewerker.id!, text: `${medewerker.firstName} ${medewerker.lastName}` });
                    });
                }

                if (caretakers.length > 0 && user?.typeId === UserType.Gaurdian) {
                    options.push({ key: "caretakers", text: languageStrings.Caretakers, itemType: DropdownMenuItemType.Header });
                    caretakers.forEach((caretaker) => {
                        options.push({ key: caretaker.id!, text: `${caretaker.firstName} ${caretaker.lastName}` });
                    });
                }

                if (gaurdians.length > 0) {
                    options.push({ key: "gaurdians", text: languageStrings.Parents, itemType: DropdownMenuItemType.Header });
                    gaurdians.forEach((gaurdian) => {
                        options.push({ key: gaurdian.id!, text: `${gaurdian.firstName} ${gaurdian.lastName}` });
                    });
                }

                setTagUserOptions(options);
            }
        })();
    }, [selectedPatient, user]);

    React.useEffect(() => {
        validateForm();
    }, [localFileRequest]);

    const showNoteAssignTo: boolean = React.useMemo(() => {
        return tagUserOptions?.length > 0;
    }, [tagUserOptions]);

    const onFileUpload = React.useCallback(
        (args?: UploadingEventArgs) => {
            setIsSaving(true);
            let fileType = localFileRequest.type;
            if (mediaMode === MediaMode.FileUploadMedia && args?.fileData?.type) {
                if (allowedImageExtensions.includes(args?.fileData.type)) {
                    fileType = FileType.Image;
                } else if (allowedVideoExtensions.includes(args?.fileData.type)) {
                    fileType = FileType.Video;
                }
            }

            if (args) {
                //let isApprover = user?.roles.find((x) => x.id === UserRole.Goedkeurder || x.id === UserRole.Admin);
                let isMedewerkerBrumBrum = user?.typeId === UserType.Medewerker ? true : false;
                args.customFormData = [
                    { userId: user?.id! },
                    { patientId: selectedPatient?.id! },
                    { fileCategoryId: localFileRequest.fileCategoryId },
                    { description: localFileRequest.description },
                    { type: fileType },
                    { approved: isMedewerkerBrumBrum ? false : true },
                ];

                if (localFileRequest?.userAddressedTo) {
                    args.customFormData.push({ userAddressedTo: localFileRequest.userAddressedTo });
                }

                args.currentRequest?.setRequestHeader('Authorization', 'Bearer ' + user?.token);
            }
        },
        [selectedPatient, localFileRequest]
    );

    const validateForm = () => {
        let isValid = true;

        if (!localFileRequest.description || localFileRequest.description.length === 0) {
            isValid = false;
        }

        if (uploadObject?.filesData?.length === 0 || uploadObject?.filesData?.length === undefined) {
            isValid = false;
        }

        setIsFormValid(isValid);
    };

    const onRenderFooterContent = React.useCallback(
        () => (
            <div className="text-right">
                <DefaultButton onClick={dismissPanel} disabled={isSaving}>
                    {languageStrings.Cancel}
                </DefaultButton>
                <DefaultButton
                    disabled={isSaving
                        // || uploadObject?.filesData?.length === 0
                        // || !localFileRequest?.description
                        || (showNoteAssignTo && !localFileRequest?.userAddressedTo)}
                    onClick={() => {
                        if (uploadObject?.filesData?.length === 0) {
                            setErrorMessage(languageStrings.SelectFile);
                        } else {
                            uploadObject?.upload();
                        }
                    }}
                    styles={buttonStyles}
                    text={languageStrings.Upload}
                >
                    {isSaving && <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: 5 } }}></Spinner>}
                </DefaultButton>
            </div>
        ),
        [dismissPanel, uploadObject, isFormValid, isSaving, localFileRequest, showNoteAssignTo, setErrorMessage]
    );

    const saveSuccess = () => {
        setIsSaving(false);
        onSuccess();
    };

    const saveFailure = () => {
        setIsSaving(false);
    };

    return (
        <Panel
            headerText={languageStrings.Upload}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            type={PanelType.custom}
            customWidth={`${isMobile ? "100%" : "500px"}`}
            closeButtonAriaLabel="Close"
            styles={panelStyles}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            allowTouchBodyScroll
        >
            <div>
                <div className="my-2">
                    {errorMessage && (
                        <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
                            {errorMessage}
                        </MessageBar>
                    )}
                </div>
                <div className="mt-4">
                    <UploaderComponent
                        id="chunkUpload"
                        type="file"
                        ref={(scope) => {
                            setUploadObject(scope!);
                        }}
                        maxFileSize={1024000000} //1 GB
                        asyncSettings={asyncSettings}
                        autoUpload={false}
                        uploading={onFileUpload}
                        multiple={true}
                        success={saveSuccess}
                        failure={saveFailure}
                        allowedExtensions={mediaMode === MediaMode.FileUploadMedia ? allowedExtensions : ""}
                    ></UploaderComponent>
                </div>
            </div>
            <div className="mt-4">
                <TextField
                    label={languageStrings.Description}
                    multiline
                    rows={8}
                    value={localFileRequest?.description}
                    onChange={(e, newValue) => {
                        setLocalFileRequest(prevFile => ({ ...prevFile, description: newValue ? newValue : "" }));
                    }}
                />
            </div>
            {showNoteAssignTo && (
                <div className="mt-4">
                    <Dropdown
                        label={languageStrings.MessageTo}
                        required
                        options={tagUserOptions}
                        onChange={(event, option) => {
                            setLocalFileRequest(prevFile => ({ ...prevFile, userAddressedTo: option?.key ? (option?.key as number) : undefined }));
                        }}
                    />
                </div>
            )}
        </Panel>
    );
};
