import {
    DefaultButton,
    Dropdown,
    Icon,
    IDropdownOption,
    IPanelStyles,
    IPersonaSharedProps,
    Label,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    Persona,
    PersonaSize,
    ResponsiveMode,
    Spinner,
    SpinnerSize,
    TextField,
    TooltipHost,
} from "@fluentui/react";
import * as EmailValidator from "email-validator";
import axios from "axios";
import React from "react";
import { AdminCrudMode, UserRole } from "../../helpers/enums";
import { IRole } from "../../interfaces/IRole";
import { IUser } from "../../interfaces/IUser";
import { IUserType } from "../../interfaces/IUserType";
import { getRoles } from "../../services/roleService";
import { addUser, editUser, getUser } from "../../services/userService";
import { BrumToggle } from "../BrumToggle";
import { ChangePassword } from "../ChangePassword";
import { AdminManageHeader } from "../page/admin/AdminManageHeader";
import { ManagePatientsPanel } from "./ManagePatientsPanel";
import { isMobile } from "react-device-detect";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { getBase64 } from "../../helpers/utils";
import { BrumSpinner } from "../BrumSpinner";
const passwordValidator = require("password-validator");
const buttonStyles = { root: { marginLeft: 8 } };

const panelStyles: Partial<IPanelStyles> = {
    content: {
        overflowX: "hidden",
        touchAction: "true",
        height: "calc(100vh - 124px);",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
    },
};
export interface IAddUpdateUserPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    adminCrudMode: AdminCrudMode;
    saveSuccess: (user: IUser) => void;
    selectedUser?: IUser;
    users: IUser[];
}

export const AddUpdateUserPanel: React.FunctionComponent<IAddUpdateUserPanelProps> = ({ isOpen, dismissPanel, adminCrudMode, saveSuccess, selectedUser, users }: IAddUpdateUserPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const [isManagePatientsPanelOpen, setIsManagePatientsPanelOpen] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [patients, setPatients] = React.useState<any[]>([]);
    const [userRoles, setUserRoles] = React.useState<IRole[]>([]);
    const [userTypeOptions, setUserTypeOptions] = React.useState<IDropdownOption[]>([]);
    const [roleOptions, setRoleOptions] = React.useState<IDropdownOption[]>([]);

    const [isSaving, setIsSaving] = React.useState(false);
    const [hidePasswordDialog, setHidePasswordDialog] = React.useState(true);

    const fileUploadInput = React.useRef<HTMLInputElement>(null);

    const [childPersona, setChildPersona] = React.useState<IPersonaSharedProps>({
        imageUrl: "",
        showInitialsUntilImageLoads: true,
    });

    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorFirstName, setErrorFirstName] = React.useState("");
    const [errorLastName, setErrorLastName] = React.useState("");
    const [errorRole, setErrorRole] = React.useState("");
    const [errorEmail, setErrorEmail] = React.useState("");
    const [errorPassword, setErrorPassword] = React.useState("");
    const [errorMobile, setErrorMobile] = React.useState("");

    const passwordSchema = new passwordValidator();
    passwordSchema.is().min(6, languageStrings.ValidationErrorPasswordTooSHort);
    passwordSchema.has().uppercase(1, languageStrings.ValidationErrorPasswordMustHaveUppercase);
    passwordSchema.has().digits(1, languageStrings.ValidationErrorPasswordMustHaveDigits);

    const [user, setUser] = React.useState<IUser>({
        firstName: "",
        lastName: "",
        email: "",
        mobilePhoneNumber: "",
        password: "",
        roles: [],
        patients: [],
        twoFactorEmailEnabled: false,
        twoFactorMobileEnabled: false,
        profileImage: "",
    });

    React.useEffect(() => {
        setIsLoading(true);
        if (selectedUser) {
            // setUser({ ...selectedUser });
            (async () => {
                try {
                    setUser(await getUser(selectedUser.id!.toString()));
                } catch (error) {
                    // Ignore
                }
                setIsLoading(false);
            })();
        }
        else {
            setIsLoading(false);
        }
    }, [selectedUser]);

    React.useEffect(() => {
        (async () => {
            const [userTypes, userRoles] = await Promise.all([getUserTypes(), getRoles()]);
            setUserRoles(userRoles);

            setUserTypeOptions(
                userTypes.map((userType) => {
                    const dropdownOption: IDropdownOption = {
                        key: userType.id!,
                        text: userType.name,
                    };

                    return dropdownOption;
                })
            );

            setRoleOptions(
                userRoles.map((userRole) => {
                    const dropdownOption: IDropdownOption = {
                        key: userRole.id!,
                        text: userRole.name,
                    };

                    return dropdownOption;
                })
            );
        })();
    }, []);

    React.useEffect(() => {
        if (user) {
            if (user.profileImage) {
                setChildPersona({ ...childPersona, imageUrl: `data:image/*;base64,${user.profileImage}` });
            }

            if (Array.isArray(user.patients) && user.patients.length > 0) {
                setPatients(
                    user.patients.map((patient, index) => {
                        return (
                            <Persona
                                imageUrl={patient.profileImage && `data:image/*;base64,${patient.profileImage}`}
                                text={`${patient.firstName} ${patient.lastName}`}
                                size={PersonaSize.size40}
                                styles={{
                                    root: {
                                        marginTop: 10,
                                    },
                                }}
                                key={index}
                            />
                        );
                    })
                );
            }
        }
    }, [user]);

    const getUserTypes = async (): Promise<IUserType[]> => {
        return (await axios.get(`${process.env.REACT_APP_API_URL}/api/types`)).data;
    };

    const onRoleChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        const userHasRole = user.roles.find((x) => x.id === option?.key);
        if (userHasRole) {
            setUser({ ...user, roles: user.roles.filter((x) => x.id !== option?.key) });
        } else {
            const selectedRole = userRoles.find((x) => x.id === option?.key);
            if (selectedRole) {
                setUser({ ...user, roles: [...user.roles, selectedRole] });
            }
        }
    };

    const getPanelHeaderText = () => {
        let headerText = "";
        switch (adminCrudMode) {
            case AdminCrudMode.Add:
                headerText = languageStrings.AddUser;
                break;

            case AdminCrudMode.Edit:
                headerText = languageStrings.EditUser;
                break;

            default:
                break;
        }

        return headerText;
    };

    const resetErrors = () => {
        setErrorFirstName("");
        setErrorLastName("");
        setErrorRole("");
        setErrorEmail("");
        setErrorPassword("");
        setErrorMobile("");
    };

    const onAddUpdateUser = React.useCallback(async () => {
        let hasError = false;
        resetErrors();

        if (!user.firstName || user.firstName.length === 0) {
            setErrorFirstName(languageStrings.ValidationErrorFirstName);
            hasError = true;
        }

        if (!user.lastName || user.lastName.length === 0) {
            setErrorLastName(languageStrings.ValidationErrorLastName);
            hasError = true;
        }

        if (user.roles.length === 0) {
            setErrorRole(languageStrings.ValidationErrorRole);
            hasError = true;
        }

        if (!user.email || user.email.length === 0) {
            setErrorEmail(languageStrings.ValidationErrorEmptyEmail);
            hasError = true;
        }

        if (user.email && !EmailValidator.validate(user.email)) {
            setErrorEmail(languageStrings.ValidationErrorEmailNotVaild);
            hasError = true;
        }

        if (user.twoFactorMobileEnabled && user.mobilePhoneNumber?.length === 0) {
            setErrorMobile(languageStrings.ValidationErrorPhoneRequired);
            hasError = true;
        }

        if (user.mobilePhoneNumber && !isValidPhoneNumber(user.mobilePhoneNumber)) {
            setErrorMobile(languageStrings.ValidationErrorPhoneNotValid);
            hasError = true;
        }

        if (adminCrudMode === AdminCrudMode.Add) {
            const userExist = users.find((x) => x.email?.toLowerCase() === user.email?.toLowerCase());
            if (userExist) {
                setErrorEmail(languageStrings.ValidationErrorEmailInUse);
                hasError = true;
            }
        }

        if (adminCrudMode === AdminCrudMode.Add) {
            const passwordErrors = passwordSchema.validate(user.password, { details: true });

            if (passwordErrors && passwordErrors.length > 0) {
                setErrorPassword(passwordErrors[0].message);
                hasError = true;
            }

            if (hasError) {
                return;
            }

            resetErrors();

            setIsSaving(true);
            try {
                await addUser(user);
                saveSuccess(user);
            } catch (error) {
                setErrorMessage(languageStrings.ErrorOccured);
                setIsSaving(false);
            }
        } else if (adminCrudMode === AdminCrudMode.Edit) {
            if (hasError) {
                return;
            }
            resetErrors();
            setIsSaving(true);
            try {
                await editUser(user.id!.toString(), user);
                saveSuccess(user);
            } catch (error) {
                setErrorMessage(languageStrings.ErrorOccured);
                setIsSaving(false);
            }
        }
    }, [user, isSaving, saveSuccess]);

    const onRenderFooterContent = React.useCallback(
        () => (
            <div className="text-right">
                <DefaultButton onClick={dismissPanel} disabled={isSaving}>
                    {languageStrings.Cancel}
                </DefaultButton>
                <DefaultButton onClick={onAddUpdateUser} styles={buttonStyles} disabled={isSaving} text={languageStrings.Save}>
                    {isSaving && <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: 5 } }}></Spinner>}
                </DefaultButton>
            </div>
        ),
        [dismissPanel, onAddUpdateUser, user, isSaving]
    );

    const isValidPhoneNumber = (input: string) => {
        const regex = /^([0|\+[0-9]{1,5})?([0-9][0-9]{9})$/;
        return regex.test(input);
    }

    const getPasswordTooltip = () => {
        return (
            <ul>
                <li> - {languageStrings.ValidationErrorPasswordTooSHort}</li>
                <li> - {languageStrings.ValidationErrorPasswordMustHaveUppercase}</li>
                <li> - {languageStrings.ValidationErrorPasswordMustHaveDigits}</li>
            </ul>
        );
    };

    const fileUploaded = async (e: any) => {
        let file = e.target.files[0];
        const res = await getBase64(file);
        const baseParts = res.split(",");

        if (baseParts?.length >= 2) {
            setUser({ ...user, profileImageBase64: baseParts[1], profileImage: baseParts[1] });
        }
    };

    return (
        <Panel
            headerText={getPanelHeaderText()}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            closeButtonAriaLabel="Close"
            styles={panelStyles}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            type={PanelType.custom}
            customWidth={`${isMobile ? "100%" : "500px"}`}
            allowTouchBodyScroll
        >
            {isLoading
                ? (<div className="text-center mt-4">
                    <BrumSpinner />
                </div>)
                : (<>
                    {/* <div className="h-[calc(100vh-120px)] xl:h-[calc(100vh-120px)] overflow-scroll overflow-x-hidden scrollbar-thin theme-scrollbar theme-scrollbar-track p-4 pt-0"> */}
                    <div className="">
                        <form className="pt-4">
                            {errorMessage && (
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                                    {errorMessage}
                                </MessageBar>
                            )}
                            <div className="mb-4">
                                <AdminManageHeader
                                    title={languageStrings.ProfileImage}
                                    manageClicked={() => {
                                        fileUploadInput.current?.click();
                                    }}
                                    altText={languageStrings.Change}
                                />
                                <div>
                                    <Persona {...childPersona} size={PersonaSize.size120} />
                                    <input className="hidden" ref={fileUploadInput} id="cameraFileInput" type="file" accept="image/*" capture="environment" onChange={(e) => fileUploaded(e)} />
                                </div>
                            </div>
                            <div className="pb-2">
                                <TextField
                                    label={languageStrings.FirstName}
                                    value={user.firstName}
                                    placeholder=""
                                    onChange={(e, newValue) => {
                                        setUser({ ...user, firstName: newValue ?? "" });
                                    }}
                                    name="new-user-firstname"
                                    id="new-user-firstname"
                                    autoComplete="do-not-autofill"
                                    required
                                    errorMessage={errorFirstName}
                                />
                            </div>
                            <div className="pb-2">
                                <TextField
                                    label={languageStrings.LastName}
                                    value={user.lastName}
                                    onChange={(e, newValue) => {
                                        setUser({ ...user, lastName: newValue ?? "" });
                                    }}
                                    name="new-user-lastname"
                                    id="new-user-lastname"
                                    autoComplete="do-not-autofill"
                                    required
                                    errorMessage={errorLastName}
                                />
                            </div>
                            <div className="pb-2">
                                <Dropdown
                                    label={languageStrings.Type}
                                    options={userTypeOptions}
                                    selectedKey={user.typeId}
                                    responsiveMode={ResponsiveMode.large}
                                    onChange={(event, option) => {
                                        setUser({ ...user, typeId: option?.key ? (option?.key as number) : undefined });
                                    }}
                                />
                            </div>
                            <div className="pb-2">
                                <Dropdown
                                    label={languageStrings.Role}
                                    options={roleOptions}
                                    multiSelect
                                    //selectedKey={user.roles.length > 0 ? user.roles[0].id : undefined}
                                    selectedKeys={user.roles.length > 0 ? user.roles.map((role) => role.id!) : []}
                                    onChange={(event, option) => onRoleChanged(event, option)}
                                    responsiveMode={ResponsiveMode.large}
                                    required
                                    errorMessage={errorRole}
                                />
                            </div>
                            <div className="pb-2">
                                <TextField
                                    label={languageStrings.Email}
                                    value={user.email}
                                    onChange={(e, newValue) => {
                                        setUser({ ...user, email: newValue ? newValue : "" });
                                    }}
                                    name="new-email"
                                    id="new-email"
                                    autoComplete="new-email"
                                    type="email"
                                    required
                                    errorMessage={errorEmail}
                                />
                            </div>
                            <div className="pb-2">
                                <TextField
                                    label={languageStrings.Phone}
                                    value={user.mobilePhoneNumber}
                                    onChange={(e, newValue) => {
                                        setUser({ ...user, mobilePhoneNumber: newValue ? newValue : "" });
                                    }}
                                    name="new-phone"
                                    id="new-phone"
                                    autoComplete="new-phone"
                                    errorMessage={errorMobile}
                                />
                            </div>
                            {adminCrudMode === AdminCrudMode.Add && (
                                <div className="grid grid-cols-12">
                                    <div className="pb-2 col-span-11">
                                        <TextField
                                            label={languageStrings.Password}
                                            value={user.password}
                                            type="password"
                                            onChange={(e, newValue) => {
                                                setUser({ ...user, password: newValue ? newValue : "" });
                                            }}
                                            canRevealPassword
                                            name="new-password"
                                            id="new-password"
                                            autoComplete="new-password"
                                            required
                                            errorMessage={errorPassword}
                                        />
                                    </div>
                                    <div className="m-auto pt-6">
                                        <TooltipHost content={getPasswordTooltip()}>
                                            <Icon className="text-lg theme-color-text hover:cursor-help" iconName={"Info"}></Icon>
                                        </TooltipHost>
                                    </div>
                                </div>
                            )}
                            <div className="pb-2">
                                <BrumToggle
                                    label={languageStrings.TwoFactorEmailEnabled}
                                    checked={user.twoFactorEmailEnabled}
                                    id={"twofactor"}
                                    toggled={() => setUser({ ...user, twoFactorEmailEnabled: !user.twoFactorEmailEnabled })}
                                />
                            </div>
                            <div className="pb-2">
                                <BrumToggle
                                    label={languageStrings.TwoFactorMobileEnabled}
                                    checked={user.twoFactorMobileEnabled}
                                    id={"twofactormobile"}
                                    toggled={() => setUser({ ...user, twoFactorMobileEnabled: !user.twoFactorMobileEnabled })}
                                />
                            </div>
                            <div className="pb-2">
                                <BrumToggle
                                    id={"allowdownload"}
                                    label={languageStrings.AllowDownload}
                                    checked={user.allowDownload ? user.allowDownload : false}
                                    toggled={() => setUser({ ...user, allowDownload: user.allowDownload ? !user.allowDownload : true })}
                                />
                            </div>
                            <div className="pb-2">
                                <BrumToggle
                                    label={languageStrings.ReceiveEmailOnFileApproval}
                                    checked={user.receiveEmailOnFileApproval == true}
                                    id={"receiveEmailOnFileApproval"}
                                    toggled={() => setUser({ ...user, receiveEmailOnFileApproval: user.receiveEmailOnFileApproval ? !user.receiveEmailOnFileApproval : true })}
                                />
                            </div>
                            <div className="pb-2">
                                <BrumToggle
                                    label={languageStrings.ReceiveSmsOnFileApproval}
                                    checked={user.receiveSmsOnFileApproval == true}
                                    id={"receiveSmsOnFileApproval"}
                                    toggled={() => setUser({ ...user, receiveSmsOnFileApproval: user.receiveSmsOnFileApproval ? !user.receiveSmsOnFileApproval : true })}
                                />
                            </div>
                            {adminCrudMode === AdminCrudMode.Edit && (
                                <div className="mb-4">
                                    <AdminManageHeader
                                        title={languageStrings.Password}
                                        altText={languageStrings.Change}
                                        manageClicked={() => {
                                            setHidePasswordDialog(false);
                                        }}
                                    />
                                </div>
                            )}
                            <div className="mb-4">
                                <AdminManageHeader
                                    title={languageStrings.Children}
                                    manageClicked={() => {
                                        setIsManagePatientsPanelOpen(true);
                                    }}
                                />
                                <div>
                                    {patients?.length === 0 && <Label disabled>{languageStrings.NoChildrenFound}</Label>}
                                    {patients?.length > 0 && patients}
                                </div>
                            </div>
                        </form>
                    </div>
                    <ChangePassword hideDialog={hidePasswordDialog} dismissDialog={() => setHidePasswordDialog(true)} user={user} />
                    {isManagePatientsPanelOpen && (
                        <ManagePatientsPanel
                            setUser={setUser}
                            isOpen={isManagePatientsPanelOpen}
                            user={user}
                            dismissPanel={() => {
                                setIsManagePatientsPanelOpen(false);
                            }}
                        />
                    )}
                </>)}
        </Panel>
    );
};
