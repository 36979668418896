import { DefaultButton, IPanelStyles, IPersonaSharedProps, Label, Panel, PanelType, Persona, PersonaSize, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import React from "react";
import { AdminCrudMode, UserType } from "../../helpers/enums";
import { IPatient } from "../../interfaces/IPatient";
import { addPatient, editPatient, getPatient } from "../../services/patientService";
import { AdminManageHeader } from "../page/admin/AdminManageHeader";
import { ManageUsersPanel } from "./ManageUsersPanel";
import { isMobile } from "react-device-detect";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { getBase64 } from "../../helpers/utils";
import { IPatientPayload } from "../../interfaces/IPatientPayload";
import { BrumToggle } from "../BrumToggle";
import { BrumSpinner } from "../BrumSpinner";

const buttonStyles = { root: { marginLeft: 8 } };

const panelStyles: Partial<IPanelStyles> = {
    content: {
        overflowX: "hidden",
        touchAction: "true",
        height: "calc(100vh - 124px);",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
    },
};
export interface IAddUpdatePatientPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    adminCrudMode: AdminCrudMode;
    saveSuccess: (patient: IPatient) => void;
    selectedPatient?: IPatient;
}

export const AddUpdatePatientPanel: React.FunctionComponent<IAddUpdatePatientPanelProps> = ({ isOpen, dismissPanel, adminCrudMode, saveSuccess, selectedPatient }: IAddUpdatePatientPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isManageUsersPanelOpen, setIsManageUsersPanelOpen] = React.useState(false);
    const [userTypeToManage, setUserTypeToManage] = React.useState<UserType>();

    const [errorFirstName, setErrorFirstName] = React.useState("");
    const [errorLastName, setErrorLastName] = React.useState("");

    const [caretakers, setCaretakers] = React.useState<any[]>([]);
    const [gaurdians, setGaurdians] = React.useState<any[]>([]);
    const [medewerkers, setMedewerkers] = React.useState<any[]>([]);

    const [isFormValid, setIsFormValid] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [childPersona, setChildPersona] = React.useState<IPersonaSharedProps>({
        imageUrl: "",
        showInitialsUntilImageLoads: true,
    });

    const fileUploadInput = React.useRef<HTMLInputElement>(null);

    const [patient, setPatient] = React.useState<IPatientPayload>({
        firstName: "",
        lastName: "",
        users: [],
        profileImageBase64: "",
    });

    React.useEffect(() => {
        // if (selectedPatient) {
        //     setPatient({ ...selectedPatient, profileImageBase64: selectedPatient.profileImage });
        // }
        setIsLoading(true);
        if (selectedPatient) {
            (async () => {
                try {
                    const patient = await getPatient(selectedPatient.id!.toString());
                    setPatient({ ...patient, profileImageBase64: patient.profileImage });
                } catch (error) {
                    // Ignore
                }
                setIsLoading(false);
            })();
        }
        else {
            setIsLoading(false);
        }
    }, [selectedPatient]);

    React.useEffect(() => {
        if (patient) {
            if (patient.profileImageBase64) {
                setChildPersona({ ...childPersona, imageUrl: `data:image/*;base64,${patient.profileImageBase64}` });
            }

            setMedewerkers(
                patient.users
                    .filter((x) => x.typeId === UserType.Medewerker)
                    .map((medewerker, index) => {
                        return (
                            <Persona
                                imageUrl={medewerker.profileImage && `data:image/*;base64,${medewerker.profileImage}`}
                                text={`${medewerker.firstName} ${medewerker.lastName}`}
                                size={PersonaSize.size40}
                                styles={{
                                    root: {
                                        marginTop: 10,
                                    },
                                }}
                                key={index}
                            />
                        );
                    })
            );

            setCaretakers(
                patient.users
                    .filter((x) => x.typeId === UserType.Caretaker)
                    .map((caretaker, index) => {
                        return (
                            <Persona
                                imageUrl={caretaker.profileImage && `data:image/*;base64,${caretaker.profileImage}`}
                                text={`${caretaker.firstName} ${caretaker.lastName}`}
                                size={PersonaSize.size40}
                                styles={{
                                    root: {
                                        marginTop: 10,
                                    },
                                }}
                                key={index}
                            />
                        );
                    })
            );

            setGaurdians(
                patient.users
                    .filter((x) => x.typeId === UserType.Gaurdian)
                    .map((gaurdian, index) => {
                        return (
                            <Persona
                                imageUrl={gaurdian.profileImage && `data:image/*;base64,${gaurdian.profileImage}`}
                                text={`${gaurdian.firstName} ${gaurdian.lastName}`}
                                size={PersonaSize.size40}
                                styles={{
                                    root: {
                                        marginTop: 10,
                                    },
                                }}
                                key={index}
                            />
                        );
                    })
            );
        }
    }, [patient]);

    const onManageClicked = (userType: UserType) => {
        setUserTypeToManage(userType);
        setIsManageUsersPanelOpen(true);
    };

    const getPanelHeaderText = () => {
        let headerText = "";
        switch (adminCrudMode) {
            case AdminCrudMode.Add:
                headerText = languageStrings.AddChild;
                break;

            case AdminCrudMode.Edit:
                headerText = languageStrings.EditChild;
                break;

            default:
                break;
        }

        return headerText;
    };

    const onAddUpdatePatient = React.useCallback(async () => {
        let hasError = false;
        resetErrors();

        if (!patient.firstName || patient.firstName.length === 0) {
            setErrorFirstName(languageStrings.ValidationErrorFirstName);
            hasError = true;
        }

        if (!patient.lastName || patient.lastName.length === 0) {
            setErrorLastName(languageStrings.ValidationErrorLastName);
            hasError = true;
        }

        if (hasError) {
            return;
        }

        resetErrors();

        if (adminCrudMode === AdminCrudMode.Add) {
            setIsSaving(true);
            await addPatient(patient);
            saveSuccess(patient);
        } else if (adminCrudMode === AdminCrudMode.Edit) {
            setIsSaving(true);
            await editPatient(patient.id?.toString()!, patient);
            saveSuccess(patient);
        }
    }, [patient]);

    const onRenderFooterContent = React.useCallback(
        () => (
            <div className="text-right">
                <DefaultButton onClick={dismissPanel} disabled={isSaving}>
                    {languageStrings.Cancel}
                </DefaultButton>
                <DefaultButton onClick={onAddUpdatePatient} styles={buttonStyles} text={languageStrings.Save}>
                    {isSaving && <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: 5 } }}></Spinner>}
                </DefaultButton>
            </div>
        ),
        [dismissPanel, onAddUpdatePatient, patient, isFormValid, isSaving]
    );

    const resetErrors = () => {
        setErrorFirstName("");
        setErrorLastName("");
    };

    const fileUploaded = async (e: any) => {
        let file = e.target.files[0];
        const res = await getBase64(file);
        const baseParts = res.split(",");

        if (baseParts?.length >= 2) {
            setPatient({ ...patient, profileImageBase64: baseParts[1] });
        }
    };

    return (
        <Panel
            headerText={getPanelHeaderText()}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            closeButtonAriaLabel="Close"
            styles={panelStyles}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            type={PanelType.custom}
            customWidth={`${isMobile ? "100%" : "500px"}`}
            allowTouchBodyScroll
        >
            {isLoading
                ? (<div className="text-center mt-4">
                    <BrumSpinner />
                </div>)
                : (<>
                    <div className="">
                        <form className="pt-4">
                            <div className="mb-4">
                                <AdminManageHeader
                                    title={languageStrings.ProfileImage}
                                    manageClicked={() => {
                                        fileUploadInput.current?.click();
                                    }}
                                    altText={languageStrings.Change}
                                />
                                <div>
                                    <Persona {...childPersona} size={PersonaSize.size120} />
                                    <input className="hidden" ref={fileUploadInput} id="cameraFileInput" type="file" accept="image/*" capture="environment" onChange={(e) => fileUploaded(e)} />
                                </div>
                            </div>
                            <div className="pb-2">
                                <TextField
                                    value={patient.firstName}
                                    label={languageStrings.FirstName}
                                    onChange={(e, newValue) => {
                                        setPatient({ ...patient, firstName: newValue });
                                    }}
                                    name="new-child-firstname"
                                    id="new-child-firstname"
                                    autoComplete="new-child-firstname"
                                    errorMessage={errorFirstName}
                                />
                            </div>
                            <div className="pb-2">
                                <TextField
                                    value={patient.lastName}
                                    label={languageStrings.LastName}
                                    onChange={(e, newValue) => {
                                        setPatient({ ...patient, lastName: newValue });
                                    }}
                                    name="new-child-lastname"
                                    id="new-child-lastname"
                                    autoComplete="new-child-lastname"
                                    errorMessage={errorLastName}
                                />
                            </div>
                            <div className="pb-2">
                                <BrumToggle
                                    label={languageStrings.Archived}
                                    checked={patient.isArchived == true}
                                    id={"isArchived"}
                                    toggled={() => setPatient({ ...patient, isArchived: patient.isArchived ? !patient.isArchived : true })}
                                />
                            </div>
                            <div className="mb-4">
                                <AdminManageHeader
                                    title={languageStrings.BrumBrumEmployees}
                                    manageClicked={() => {
                                        onManageClicked(UserType.Medewerker);
                                    }}
                                />
                                <div>
                                    {medewerkers.length === 0 && <Label disabled>{languageStrings.NoMedewerkersFound}</Label>}
                                    {medewerkers.length > 0 && medewerkers}
                                </div>
                            </div>
                            <div className="mb-4">
                                <AdminManageHeader
                                    title={languageStrings.Caretakers}
                                    manageClicked={() => {
                                        onManageClicked(UserType.Caretaker);
                                    }}
                                />
                                <div>
                                    {caretakers.length === 0 && <Label disabled>{languageStrings.NoCaretakersFound}</Label>}
                                    {caretakers.length > 0 && caretakers}
                                </div>
                            </div>
                            <div className="mb-4">
                                <AdminManageHeader
                                    title={languageStrings.Parents}
                                    manageClicked={() => {
                                        onManageClicked(UserType.Gaurdian);
                                    }}
                                />
                                <div>
                                    {gaurdians.length === 0 && <Label disabled>{languageStrings.NoParentsFound}</Label>}
                                    {gaurdians.length > 0 && gaurdians}
                                </div>
                            </div>
                        </form>
                    </div>

                    {isManageUsersPanelOpen && userTypeToManage && (
                        <ManageUsersPanel
                            setPatient={setPatient}
                            isOpen={isManageUsersPanelOpen}
                            userType={userTypeToManage}
                            patient={patient}
                            dismissPanel={() => {
                                setIsManageUsersPanelOpen(false);
                            }}
                        />
                    )}
                </>)}
        </Panel>
    );
};
