import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppPage } from "../../helpers/enums";
import { IFileFolder } from "../../interfaces/IFileFolder";
import { IPatient } from "../../interfaces/IPatient";

type InitialState = {
    currentPage?: AppPage;
    selectedFolder?: IFileFolder;
    refreshFolderContent: string;
    notApprovedCount: number;
    addressedToList: string[];
    createdByList: string[];
    activePatients: IPatient[]
};
const initialState: InitialState = {
    currentPage: AppPage.None,
    selectedFolder: undefined,
    refreshFolderContent: "",
    notApprovedCount: 0,
    addressedToList: [],
    createdByList: [],
    activePatients: []
};

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setCurrentPage: (state, action: PayloadAction<AppPage>) => {
            state.currentPage = action.payload;
        },
        setSelectedFolder: (state, action: PayloadAction<IFileFolder | undefined>) => {
            state.selectedFolder = action.payload;
        },
        // setRefreshFolderContent: (state, action: PayloadAction<string>) => {
        //     state.refreshFolderContent = action.payload;
        // },
        setRefreshFolderContent: (state) => {
            state.refreshFolderContent = new Date().toISOString();
        },
        setNotApprovedCount: (state, action: PayloadAction<number>) => {
            state.notApprovedCount = action.payload;
        },
        setAddressedToList: (state, action: PayloadAction<string[]>) => {
            state.addressedToList = action.payload;
        },
        setCreatedByList: (state, action: PayloadAction<string[]>) => {
            state.createdByList = action.payload;
        },
        setActivePatients: (state, action: PayloadAction<IPatient[]>) => {
            state.activePatients = action.payload;
        },
        resetNav: () => initialState,
    },
});

export default navigationSlice.reducer;
export const { setCurrentPage, setSelectedFolder, setRefreshFolderContent, resetNav, setNotApprovedCount, setAddressedToList, setCreatedByList, setActivePatients } = navigationSlice.actions;
