import React from "react";
import { AppPage, FileCategory, FolderSortByOption, UserRole } from "../../helpers/enums";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { IFile } from "../../interfaces/IFile";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAddressedToList, setCreatedByList, setNotApprovedCount } from "../../redux/slices/navigationSlice";
import { getFileLight, getFiles, getFilesForCategory } from "../../services/fileService";
import { BrumSpinner } from "../BrumSpinner";
import { FilePreviewPanel } from "../panels/FilePreviewPanel";
import { FileListItem } from "./FileListItem";
import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { AfterOpenDetail } from "lightgallery/lg-events";
import { markFilesAsRead } from "../../services/userService";
import { IFilesResponse } from "../../interfaces/IFilesResponse";
import { Spinner } from "@fluentui/react";
import { ApprovalFolderName } from "../../helpers/constants";

export interface IPageFolderItemListProps {
    fileCategory: FileCategory;
    sortOption: FolderSortByOption;
    filterOptions?: string[];
    filterCreatedByOptions?: string[];
    filterAddressedToOptions?: string[];
}

export const PageFolderItemList: React.FunctionComponent<IPageFolderItemListProps> = ({
    fileCategory,
    sortOption,
    filterOptions,
    filterCreatedByOptions,
    filterAddressedToOptions,
}: IPageFolderItemListProps) => {
    const languageStrings = React.useContext(LanguageContext);
    const dispatch = useAppDispatch();

    const selectedPatient = useAppSelector((state) => state.user.selectedPatient);
    const selectedFolder = useAppSelector((state) => state.navigation.selectedFolder);
    const refreshFolderContent = useAppSelector((state) => state.navigation.refreshFolderContent);
    const user = useAppSelector((state) => state.user.user);
    const currentPage = useAppSelector((state) => state.navigation.currentPage);

    const [files, setFiles] = React.useState<IFile[]>([]);
    const [isBusy, setIsBusy] = React.useState(true);
    const [isFilePreviewPanelOpen, setIsFilePreviewPanelOpen] = React.useState(false);
    const [isFilePreviewPanelReadOnly, setIsFilePreviewReadOnly] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState<IFile>();
    const [items, setItems] = React.useState<any>([]);
    const [isLoadingNextPage, setIsLoadingNextPage] = React.useState<boolean>(false);
    const [hasElevatedPermissions, setHasElevatedPermissions] = React.useState<boolean>(false);

    const lightGallery = React.useRef<any>(null);
    const lightGalleryImageLoaded = React.useRef<boolean>(false);
    const nextPageStartId = React.useRef<number | undefined>(undefined);
    const createdByList = React.useRef<string[]>([]);
    const addressedToList = React.useRef<string[]>([]);

    React.useEffect(() => {
        const isAdminOrApprover: boolean = (user?.roles || []).findIndex((x) => x.id === UserRole.Goedkeurder || x.id === UserRole.Admin) !== -1;
        setHasElevatedPermissions(isAdminOrApprover);
    }, [user]);

    React.useEffect(() => {
        if (fileCategory || refreshFolderContent) {
            nextPageStartId.current = undefined;
            createdByList.current = [];
            addressedToList.current = [];
            loadPagedFiles();
        }
    }, [fileCategory, refreshFolderContent, selectedPatient, selectedFolder]);

    React.useEffect(() => {
        if (selectedFile) {
            const updatedSelectedFile = files.find(x => x.id === selectedFile.id);
            setSelectedFile(updatedSelectedFile);
        }
    }, [files]);

    const loadPagedFiles = async (): Promise<void> => {
        setIsLoadingNextPage(true);
        let pageCount: number = 1;
        do {
            nextPageStartId.current = await loadFiles(nextPageStartId.current);
            pageCount++;
        } while (nextPageStartId.current && nextPageStartId.current > 0 && pageCount <= 3);
        setIsLoadingNextPage(false);
    }

    const loadFiles = async (nextPageStartId?: number): Promise<number | undefined> => {
        try {
            let filesReponse: IFilesResponse;

            if (currentPage === AppPage.BrumGeneralPage) {
                filesReponse = await getFilesForCategory(sortOption === FolderSortByOption.DateDescending, nextPageStartId, fileCategory?.toString());
            } else {
                filesReponse = await getFiles(sortOption === FolderSortByOption.DateDescending, nextPageStartId, user?.id.toString(), selectedPatient?.id?.toString(), fileCategory?.toString(), selectedFolder);
            }

            const newFiles: any[] = filesReponse?.files?.map((file, index) => { return { ...file, sortIndex: file.id } }) ?? [];

            const isToBeApproved = selectedFolder?.folderName === ApprovalFolderName;
            const filesToDisplay: any[] = isToBeApproved ? newFiles : [];
            if (!isToBeApproved) {
                newFiles.forEach((newFile) => {
                    if (newFile.approved) {
                        filesToDisplay.push({ ...newFile });
                    } else if (!newFile.approved && user?.id === newFile.userId) {
                        filesToDisplay.push({ ...newFile });
                    }
                });
            }

            filesToDisplay.map((file) => {
                if (file.createdBy) {
                    const fileCreatedByDisplayName = file.createdBy === `${user?.firstName} ${user?.lastName}`
                        ? languageStrings.Me
                        : file.createdBy;

                    if (!createdByList.current.some((x) => x === fileCreatedByDisplayName)) {
                        createdByList.current = createdByList.current.concat([fileCreatedByDisplayName]);
                    }
                }
                if (file.addressedTo) {
                    const fileAddressedToDisplayName = file.addressedTo === `${user?.firstName} ${user?.lastName}`
                        ? languageStrings.Me
                        : file.addressedTo;

                    if (!addressedToList.current.some((x) => x === fileAddressedToDisplayName)) {
                        addressedToList.current = addressedToList.current.concat([fileAddressedToDisplayName]);
                    }
                }
            });

            dispatch(setCreatedByList(createdByList.current));
            dispatch(setAddressedToList(addressedToList.current));

            let fileIds = filesToDisplay.filter((x) => x.isNew).map((x) => x.id!);
            if (fileIds?.length > 0) {
                markFilesAsRead(user?.id!, fileIds);
            }

            if (nextPageStartId === undefined) {
                setFiles(filesToDisplay);
            }
            else {
                setFiles(prevFiles => prevFiles.concat(filesToDisplay));
            }

            setIsBusy(false);

            return filesReponse?.nextPageStartId;
        } catch (error) {
            console.error(error);
        }

        return undefined;
    };

    const getFilesToDisplay = () => {
        if (files.length > 0) {
            let filesToDisplay: IFile[] = [...files];

            if (filterOptions && filterOptions.length > 0) {
                filesToDisplay = filesToDisplay.filter((x) => filterOptions.includes(x.type as string));
            }

            if (filterCreatedByOptions && filterCreatedByOptions.length > 0) {
                filesToDisplay = filesToDisplay.filter((x) => filterCreatedByOptions.includes(x.createdBy as string));
            }

            if (filterAddressedToOptions && filterAddressedToOptions.length > 0) {
                filesToDisplay = filesToDisplay.filter((x) => filterAddressedToOptions.includes((x.addressedTo as string)));
            }

            dispatch(setNotApprovedCount(filesToDisplay.filter((x) => !x.approved).length));

            if (sortOption === FolderSortByOption.DateDescending) {
                filesToDisplay = filesToDisplay.sort((a, b) => (a.sortIndex! < b.sortIndex! ? 1 : -1));
            } else if (sortOption === FolderSortByOption.DateAscending) {
                filesToDisplay = filesToDisplay.sort((a, b) => (a.sortIndex! > b.sortIndex! ? 1 : -1));
            }

            return filesToDisplay;
        }
        return files;
    }

    const onInit = React.useCallback((detail: any) => {
        if (detail) {
            lightGallery.current = detail.instance;
        }
    }, []);

    const handleScroll = React.useCallback(async (event: React.UIEvent<HTMLDivElement>) => {
        const el = event.target as HTMLDivElement;
        const bottom = el.scrollHeight - el.scrollTop - el.clientHeight <= 1;
        if (bottom && nextPageStartId.current && nextPageStartId.current > 0 && !isLoadingNextPage) {
            setIsLoadingNextPage(true);
            nextPageStartId.current = await loadFiles(nextPageStartId.current);
            setIsLoadingNextPage(false);
        }
    }, [isLoadingNextPage, setIsLoadingNextPage]);

    const updateFile = async (fileId: number) => {
        const updatedFileIndex: number = files.findIndex(f => f.id === fileId);
        if (updatedFileIndex !== -1) {
            const updatedFile: IFile = await getFileLight(fileId, user?.id ?? 0);
            if (updatedFile) {
                setFiles(currentFiles => {
                    const newFiles: IFile[] = [...currentFiles];
                    newFiles[updatedFileIndex] = { ...updatedFile, sortIndex: updatedFile.id ?? 0 };
                    return selectedFolder?.folderName === ApprovalFolderName
                        ? newFiles.filter(f => !f.approved)
                        : newFiles;
                });
            }
        }
    };

    const deleteFile = async (fileId: number) => {
        setFiles(currentFiles => {
            return [...currentFiles.filter(f => f.id !== fileId)];
        });
    };

    return (
        <div className="h-[100%] overflow-scroll overflow-x-hidden p-4 scrollbar-thin theme-scrollbar theme-scrollbar-track" onContextMenu={(e) => e.preventDefault()} onScroll={handleScroll}>
            <LightGallery
                dynamic
                dynamicEl={items}
                onInit={onInit}
                plugins={[lgZoom, lgVideo]}
                onAfterClose={() => setIsFilePreviewPanelOpen(true)}
                download={user?.allowDownload || selectedFile?.allowDownload ? true : false}
                onAfterOpen={(detail: AfterOpenDetail) => {
                    if (!lightGalleryImageLoaded.current) {
                        const element = document.querySelector(".lg-image");
                        if (element) {
                            element?.addEventListener("contextmenu", (e: any) => e.preventDefault());
                            lightGalleryImageLoaded.current = true;
                        }
                    }
                }}
                onBeforeClose={() => {
                    if (lightGalleryImageLoaded.current) {
                        const element = document.querySelector(".lg-image");
                        if (element) {
                            element?.removeEventListener("contextmenu", (e: any) => e.preventDefault());
                            lightGalleryImageLoaded.current = false;
                        }
                    }
                }}
            ></LightGallery>
            {!isBusy
                ? getFilesToDisplay().length > 0
                    ? (
                        <>
                            <div className="grid grid-flow-row grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 auto-rows-min">
                                {getFilesToDisplay().map((file, index) => {
                                    return (
                                        <div key={file.id}>
                                            <FileListItem
                                                file={file}
                                                hasElevatedPermissions={hasElevatedPermissions}
                                                onEditClicked={(readOnly: boolean) => {
                                                    setIsFilePreviewPanelOpen(true);
                                                    setSelectedFile(file);
                                                    setIsFilePreviewReadOnly(readOnly);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {isLoadingNextPage && <Spinner />}
                        </>
                    )
                    : <div className="text-gray-500 text-md text-center mt-4">{languageStrings.NoItemsFound}</div>
                : (<div>
                    <BrumSpinner />
                </div>)}

            {isFilePreviewPanelOpen && selectedFile && (
                <FilePreviewPanel
                    isOpen={isFilePreviewPanelOpen}
                    hasElevatedPermissions={hasElevatedPermissions}
                    dismissPanel={() => {
                        setIsFilePreviewPanelOpen(false);
                    }}
                    file={selectedFile}
                    onDeleteSuccess={() => {
                        setIsFilePreviewPanelOpen(false);
                        deleteFile(selectedFile.id ?? 0);
                    }}
                    onEditSuccess={() => {
                        setIsFilePreviewPanelOpen(false);
                        updateFile(selectedFile.id ?? 0);
                    }}
                    onMethodologyChange={() => {
                        updateFile(selectedFile.id ?? 0);
                    }}
                    onFileOpened={() => {
                        setFiles(currentFiles => {
                            return currentFiles.map((file) => {
                                return { ...file, isNew: file.id === selectedFile.id ? false : file.isNew };
                            });
                        });
                    }}
                    onImageClicked={(src: string, text?: string) => {
                        setIsFilePreviewPanelOpen(false);
                        setItems([
                            {
                                id: "1",
                                size: "1400-933",
                                src,
                                subHtml: text,
                            },
                        ]);

                        setTimeout(() => {
                            lightGallery.current.openGallery();
                        }, 50);
                    }}
                    isReadOnly={isFilePreviewPanelReadOnly}
                />
            )}
        </div>
    );
};
