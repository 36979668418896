import axios from "axios";
import { IAuthRequest } from "../interfaces/IAuthRequest";
import { IAuthResponse } from "../interfaces/IAuthResponse";
import { ITwoFactorCodeAuthRequest } from "../interfaces/ITwoFactorCodeAuthRequest";
import { IUser } from "../interfaces/IUser";
import { IPatient } from "../interfaces/IPatient";
import { StorageHelper } from "../helpers/StorageHelper";
import { UserType } from "../helpers/enums";

export const getUsers = async (languageStrings: any): Promise<IUser[]> => {
	const users = (await axios.get(`${process.env.REACT_APP_API_URL}/api/users`)).data;
	return users.map((user: IUser) => {
		let rolesDisplay = "";
		let typeDisplay = "";

		if (user.roles?.length > 0) {
			rolesDisplay = user.roles.map((role) => role.name).join(", ")
		}

		switch (user.typeId) {
			case UserType.Caretaker:
				typeDisplay = languageStrings.Caretaker
				break;
			case UserType.Gaurdian:
				typeDisplay = languageStrings.Parent
				break;
			case UserType.Medewerker:
				typeDisplay = languageStrings.BrumBrumEmployee
				break;
			default:
				break;
		}

		return {
			...user,
			rolesDisplay,
			typeDisplay
		}
	})
};

export const getUser = async (userId: string): Promise<IUser> => {
	return (await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}`)).data;
};

export const addUser = async (user: IUser): Promise<IUser> => {
	return (await axios.post(`${process.env.REACT_APP_API_URL}/api/users`, user)).data;
};

export const editUser = async (userId: string, user: IUser): Promise<IUser> => {
	return (await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${userId}`, user)).data;
};

export const deleteUser = async (userId: string) => {
	return (await axios.delete(`${process.env.REACT_APP_API_URL}/api/users/${userId}`)).data;
};

export const authenticate = async (auth: IAuthRequest): Promise<IAuthResponse> => {
	return (await axios.post(`${process.env.REACT_APP_API_URL}/api/users/authenticate`, auth)).data;
};

export const getPatients = async (userId: number): Promise<IPatient[]> => {
	return (await axios.get(`${process.env.REACT_APP_API_URL}/api/users/patients/${userId}`)).data ?? [];
};

export const twoFactorCodeAuth = async (authTwoCode: ITwoFactorCodeAuthRequest): Promise<IAuthResponse> => {
	return (await axios.post(`${process.env.REACT_APP_API_URL}/api/users/twofactorcodeauth`, authTwoCode)).data;
};

export const userFileHistory = async (userId: string, fileId: string): Promise<IUser> => {
	return (await axios.post(`${process.env.REACT_APP_API_URL}/api/users/UserFileHistory?UserId=${userId}&FileId=${fileId}`)).data;
};

export const updatePassword = async (userId: string, password: string): Promise<IUser> => {
	return (await axios.put(`${process.env.REACT_APP_API_URL}/api/users/UpdatePassword/${userId}`, { password })).data;
};

export const markFilesAsRead = async (userId: number, fileIds: number[]) => {
	return (await axios.post(`${process.env.REACT_APP_API_URL}/api/users/markFilesAsRead`, { userId, fileIds })).data;
};

export const updateLastActivity = async (userId: number) => {
	return (await axios.put(`${process.env.REACT_APP_API_URL}/api/users/UpdateLastActivity/${userId}`)).data;
};

export const refreshUserAccessToken = async (user: IAuthResponse): Promise<void> => {
	try {
		const refreshedToken = (await axios.get(`${process.env.REACT_APP_API_URL}/api/users/RefreshToken/${user.id}`, { headers: { Authorization: `Bearer ${user.token}` } })).data;
		if (refreshedToken) {
			axios.defaults.headers.common["Authorization"] = `Bearer ${refreshedToken}`;
			user.token = refreshedToken;
			StorageHelper.setItem("user", JSON.stringify(user));
		}
	} catch (error) {
	}
};