import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, IPanelStyles, mergeStyles, Panel, PanelType, Persona, PersonaSize, SelectionMode } from "@fluentui/react";
import React from "react";
import { UserType } from "../../helpers/enums";
import { IUser } from "../../interfaces/IUser";
import { getUsers } from "../../services/userService";
import { BrumSpinner } from "../BrumSpinner";
import { NoItemsFound } from "../NoItemsFound";
import { isMobile } from "react-device-detect";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { IPatient } from "../../interfaces/IPatient";
import { filterArray } from "../../helpers/utils";

const panelStyles: Partial<IPanelStyles> = {
    content: {
        overflowX: "hidden",
        touchAction: "true",
        height: "calc(100vh - 60px);",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
    },
};
export interface ISelectUserPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    itemSelected: (user: IUser) => void;
    userType: UserType;
    patient?: IPatient;
}

export const SelectUserPanel: React.FunctionComponent<ISelectUserPanelProps> = ({ isOpen, dismissPanel, itemSelected, userType, patient }: ISelectUserPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const [isBusy, setisBusy] = React.useState(false);
    const [users, setUsers] = React.useState<IUser[]>([]);

    React.useEffect(() => {
        (async () => {
            setisBusy(true);
            if (patient && patient.users.length > 0) {
                let users = await getUsers(languageStrings);          
                setUsers(
                    filterArray(
                        users.filter((x) => x.typeId === userType),
                        patient.users
                    )
                );
            } else {
                setUsers((await getUsers(languageStrings)).filter((x) => x.typeId === userType));
            }

            setisBusy(false);
        })();
    }, [userType, patient]);

    const columns: IColumn[] = [
        {
            key: "displayName",
            name: languageStrings.Name,
            fieldName: "displayName",
            data: "string",
            minWidth: 100,
            maxWidth: 350,
            isResizable: true,
            onRender: (user: IUser) => {
                return (
                    <div className="hover:cursor-pointer">
                        <Persona
                            imageUrl={user.profileImage && `data:image/*;base64,${user.profileImage}`}
                            text={`${user.firstName} ${user.lastName}`}
                            size={PersonaSize.size40}
                            onClick={() => {
                                itemSelected(user);
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const getHeaderText = () => {
        let headerText = "";

        switch (userType) {
            case UserType.Medewerker:
                headerText = languageStrings.SelectMedeWerker;
                break;

            case UserType.Gaurdian:
                headerText = languageStrings.SelectParent;
                break;

            case UserType.Caretaker:
                headerText = languageStrings.SelectCaretaker;
                break;

            default:
                break;
        }

        return headerText;
    };

    return (
        <Panel
            headerText={getHeaderText()}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            closeButtonAriaLabel="Close"
            styles={panelStyles}
            type={PanelType.custom}
            customWidth={`${isMobile ? "100%" : "500px"}`}
            allowTouchBodyScroll
        >
            <div className="">
                {!isBusy && users.length > 0 && (
                    <DetailsList
                        items={users ?? []}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="select row"
                        checkboxCellClassName={mergeStyles({
                            display: "flex",
                            alignItems: "center",
                        })}
                    />
                )}
                {!isBusy && users.length === 0 && <NoItemsFound />}
                {isBusy && <BrumSpinner />}
            </div>
        </Panel>
    );
};
