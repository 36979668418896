import React from "react";

const locales = {
    en: {
        AcceptTerms: "Do you accept the terms and conditions?",
        Active: "Active",
        Add: "Add",
        AddChild: "Add child",
        AddMethodology: "Add to methodology",
        AddNote: "Add note",
        AddUser: "Add user",
        Admin: "Admin",
        AdminChildren: "Admin children",
        AdminUsers: "Admin users",
        All: "All",
        AllChildren: "All children",
        AllowDownload: "Allow download",
        Approved: "Approved",
        Archive: "Archive",
        Archived: "Archived",
        AssignedToMe: "Assigned to me",
        BrumBrumEmployee: "Employee BrumBrum",
        BrumBrumEmployees: "BrumBrum employees",
        BrumGeneral: "Brum general",
        Camera: "Camera",
        Cancel: "Cancel",
        CantDisplayPreview: "Can't display preview. Video size too large",
        Care: "Care",
        Caretaker: "Caretaker",
        Caretakers: "Caretakers",
        ChangePassword: "Change password",
        ChangeChild: "Change child",
        Children: "Children",
        Complete: "Complete",
        Confirm: "Confirm",
        ConfirmDelete: "Confirm delete",
        ConfirmDeleteFileMessage: "Are you sure you want to delete this file?",
        ConfirmDeleteUserMessage: "Are you sure you want to delete this user?",
        ConfirmDeleteChildMessage: "Are you sure you want to delete this user?",
        Continue: "Continue",
        Change: "Change",
        CreatedBy: "Created by",
        DailyCmmunication: "Daily communication",
        DateAscending: "Date old to new",
        DateDescending: "Date new to old",
        Delete: "Delete",
        Deleting: "Deleting...",
        Description: "Description",
        Details: "Details",
        Done: "Done",
        Download: "Download",
        Edit: "Edit",
        EditChild: "Edit child",
        EditUser: "Edit user",
        Email: "Email",
        EnterNewPassword: "Please enter a new password",
        EnterDescription: "Please enter a description",
        ErrorOccured: "An error occurred.",
        ErrorUsernamePassword: "Username or password is incorrect",
        FinalizingUpload: "Finalizing upload...",
        FirstName: "First name",
        FilterBy: "Filter by",
        FilterByAll: "Show all",
        FilterByPhoto: "Photos",
        FilterByVideo: "Videos",
        FilterByNote: "Notes",
        FilterByFile: "Files",
        Home: "Home",
        Insta: "Insta",
        Image: "Image",
        ItemWaitingForApproval: "item awaiting approval",
        ItemsWaitingForApproval: "items awaiting approval",
        ItemSubmittedForApproval: "Item successfully submitted for approval",
        LastName: "Last name",
        Logout: "Logout",
        Me: "@Me",
        Name: "Name",
        Note: "Note",
        Manage: "Manage",
        ManageChildren: "Manage children",
        ManageParents: "Manage parents",
        ManageCaretakers: "Manage caretakers",
        ManageMedewerkers: "Manage BrumBrum emplyees",
        MessageTo: "Message to",
        Methodology: "Methodology",
        ModeCamera: "Camera mode",
        ModeVideo: "Video mode",
        NameAscending: "Name A to Z",
        NameDescending: "Name Z to A",
        NoChildSelected: "No child selected",
        NoCamaraFound: "No camera found",
        NoCaretakersFound: "No caretakers found",
        NoChildrenFound: "No children found",
        NoMedewerkersFound: "No employees found",
        NoDescription: "No description",
        NoItemsFound: "No items found",
        NoNote: "No note",
        NoParentsFound: "No parents found",
        OpenCamera: "Open camera",
        Parent: "Parent",
        Parents: "Parents",
        Password: "Password",
        Phone: "Phone",
        Profile: "Profile",
        ProfileImage: "Profile image",
        ProhibitedFromSharing: "Prohibited from sharing with third parties",
        ReceiveSmsOnFileApproval: "Sms On file approval",
        ReceiveEmailOnFileApproval: "Email On file approval",
        RecordingStart: "Start recording",
        RecordingStop: "Stop recording",
        Reports: "Reports",
        Reply: "Reply",
        RemoveMethodology: "Remove from methodology",
        Role: "Role",
        Search: "Search",
        SearchingForCameraDevices: "Searching for camera devices",
        SecurityCode: "Security code",
        SelectChild: "Select child",
        SelectParent: "Select parent",
        SelectCaretaker: "Select caretaker",
        SelectMedeWerker: "Select employee",
        SelectFile: "Please select a file",
        Save: "Save",
        SignIn: "Sign in",
        SortBy: "Sort by",
        SuccessfullyReplied: "Successfully replied",
        Theory: "Theory",
        ToBeApproved: "To be approved",
        TwoFactorEmailEnabled: "Two factor email enabled",
        TwoFactorMobileEnabled: "Two factor mobile enabled",
        Type: "Type",
        Upload: "Upload",
        UploadComplete: "Upload complete",
        UploadingVideo: "Uploading video",
        UploadingImage: "Uploading photo",
        UploadingNote: "Uploading note",
        Users: "Users",
        Video: "Video",
        VideoTooLarge: "Video size too large. [currentSize]MB exceeds the [maxSize]MB limit",
        VideoTooLargePreview: "Video size too large to preview",
        ValidationErrorFirstName: "Please enter a first name",
        ValidationErrorLastName: "Please enter a last name",
        ValidationErrorRole: "Please select a role",
        ValidationErrorEmptyEmail: "Please enter an email address",
        ValidationErrorEmailNotVaild: "Please enter a valid email address",
        ValidationErrorEmailInUse: "Email already in use",
        ValidationErrorPasswordEmpty: "Please enter a password",
        ValidationErrorPasswordTooSHort: "Password should be at least 6 characters",
        ValidationErrorPasswordMustHaveUppercase: "Password must have at least 1 uppercase letter",
        ValidationErrorPasswordMustHaveDigits: "Password must have at least 1 digit",
        ValidationErrorPhoneRequired: "Please enter mobile number",
        ValidationErrorPhoneNotValid: "Please enter a valid mobile number",
        ValidationErrorMessageToEmpty: "Message to field required",
        WorkingDocuments: "Working documents",
        Yes: "Yes"
    },
    nl: {
        AcceptTerms: "Accepteert u de voorwaarden?",
        Active: "Actief",
        Add: "Voeg toe",
        AddChild: "Kind toevoegen",
        AddMethodology: "Toevoegen aan Werkwijze",
        AddNote: "Overdracht toevoegen",
        AddUser: "Gebruiker toevoegen",
        Admin: "Admin",
        AdminChildren: "Admin kinderen",
        AdminUsers: "Admin gebruikers",
        All: "Alle",
        AllChildren: "Alle kinderen",
        AllowDownload: "Download toelaten",
        Approved: "Goedgekeurd",
        Archive: "Archief",
        Archived: "Gearchiveerd",
        AssignedToMe: "Toegewezen aan mij",
        BrumBrumEmployee: "Medewerker BrumBrum",
        BrumBrumEmployees: "BrumBrum medewerkers",
        BrumGeneral: "Brum algemeen",
        Camera: "Foto",
        Cancel: "Annuleer",
        CantDisplayPreview: "Kan preview niet weergeven. Videogrootte te groot",
        Care: "Zorg",
        Caretaker: "Verzorger",
        Caretakers: "Verzorgers",
        Continue: "Verder",
        Change: "Verander",
        CreatedBy: "Gemaakt door",
        ChangePassword: "Wijzig wachtwoord",
        ChangeChild: "Kies kind",
        Children: "Kinderen",
        Complete: "Compleet",
        Confirm: "Bevestigen",
        ConfirmDelete: "Bevestigen verwijderen",
        ConfirmDeleteFileMessage: "Weet u zeker dat u dit bestand wilt verwijderen?",
        ConfirmDeleteUserMessage: "Weet u zeker dat u deze gebruiker wilt verwijderen?",
        ConfirmDeleteChildMessage: "Weet je zeker dat je dit kind wilt verwijderen?",
        DailyCmmunication: "Dagelijkse communicatie",
        DateAscending: "Datum oud naar nieuw",
        DateDescending: "Datum nieuw naar oud",
        Delete: "Verwijderen",
        Deleting: "Verwijderen...",
        Description: "Beschrijving",
        Details: "Details",
        Done: "Gedaan",
        Download: "Download",
        Edit: "Bewerken",
        EditChild: "Kind bewerken",
        EditUser: "Gebruiker bewerken",
        Email: "Email",
        EnterNewPassword: "Voer een nieuw wachtwoord in",
        EnterDescription: "Voer een beschrijving in",
        ErrorOccured: "Er is een fout opgetreden",
        ErrorUsernamePassword: "Gebruikersnaam of wachtwoord is onjuist",
        FinalizingUpload: "Afronden upload...",
        FirstName: "Voornaam",
        FilterBy: "Filteren op",
        FilterByAll: "Alles tonen",
        FilterByPhoto: "Fotos",
        FilterByVideo: "Videos",
        FilterByNote: "Overdrachten",
        FilterByFile: "Bestanden",
        Home: "Home",
        Insta: "Insta",
        Image: "Afbeelding",
        ItemWaitingForApproval: "item wacht op goedkeuring",
        ItemsWaitingForApproval: "items wachten op goedkeuring",
        ItemSubmittedForApproval: "Item met succes ingediend voor goedkeuring",
        LastName: "Achternaam",
        Logout: "Afmelden",
        Me: "@Ik",
        Name: "Naam",
        Note: "Overdracht",
        Manage: "Beheren",
        ManageChildren: "Kinderen beheren",
        ManageParents: "Beheren ouders",
        ManageCaretakers: "Beheren verzorgers",
        ManageMedewerkers: "Beheren BrumBrum medewerkers",
        MessageTo: "Bericht aan",
        Methodology: "Werkwijze",
        ModeCamera: "Cameramodus",
        ModeVideo: "Videomodus",
        NameAscending: "Naam A tot Z",
        NameDescending: "Naam Z tot A",
        NoChildSelected: "Geen kind geselecteerd",
        NoCamaraFound: "Geen camera gevonden",
        NoCaretakersFound: "Geen verzorgers gevonden",
        NoChildrenFound: "Geen kinderen gevonden",
        NoMedewerkersFound: "No medewerkers gevonden",
        NoDescription: "Geen beschrijving",
        NoItemsFound: "Geen items gevonden",
        NoNote: "Geen overdracht",
        NoParentsFound: "Geen ouders gevonden",
        OpenCamera: "Open camera",
        Parent: "Ouder",
        Parents: "Ouders",
        Password: "Wachtwoord",
        Phone: "Telefoon",
        Profile: "Profiel",
        ProfileImage: "Profiel afbeelding",
        ProhibitedFromSharing: "Verboden te delen met derden",
        ReceiveSmsOnFileApproval: "Sms bij bestandsgoedkeuring",
        ReceiveEmailOnFileApproval: "E-mail bij bestandsgoedkeuring",
        RecordingStart: "Start opname",
        RecordingStop: "Stop opname",
        Reports: "Verslagen",
        Reply: "Reageren",
        RemoveMethodology: "Verwijder uit Werkwijze",
        Role: "Rol",
        Search: "Zoeken",
        SearchingForCameraDevices: "Zoeken naar camera-apparaten",
        SecurityCode: "Veiligheidscode",
        SelectChild: "Kies kind",
        SelectParent: "Kies ouder",
        SelectCaretaker: "Kies verzorger",
        SelectMedeWerker: "Kies medewerker",
        SelectFile: "Kies een bestand",
        Save: "Opslaan",
        SignIn: "Aanmelden",
        SortBy: "Sorteer op",
        SuccessfullyReplied: "Succesvol gereageerd",
        Theory: "Theorie",
        ToBeApproved: "Goed te keuren",
        TwoFactorEmailEnabled: "Twee-factor e-mail ingeschakeld",
        TwoFactorMobileEnabled: "Twee-factor mobiel ingeschakeld",
        Type: "Type",
        Upload: "Upload",
        UploadComplete: "Upload voltooid",
        UploadingVideo: "Video uploaden",
        UploadingImage: "Overdracht uploaden",
        UploadingNote: "",
        Users: "Gebruikers",
        Video: "Film",
        VideoTooLarge: "Videogrootte te groot. [currentSize]MB overschrijdt de [maxSize]MB limiet",
        VideoTooLargePreview: "Videogrootte te groot om te bekijken",
        ValidationErrorFirstName: "Voer een voornaam in",
        ValidationErrorLastName: "Voer een achternaam in",
        ValidationErrorRole: "Selecteer een rol",
        ValidationErrorEmptyEmail: "Voer een e-mail adres in",
        ValidationErrorEmailNotVaild: "Voer een geldig e-mail adres in",
        ValidationErrorEmailInUse: "E-mail reeds in gebruik",
        ValidationErrorPasswordEmpty: "Voer een wachtwoord in",
        ValidationErrorPasswordTooSHort: "Wachtwoord moet uit ten minste 6 tekens bestaan",
        ValidationErrorPasswordMustHaveUppercase: "Wachtwoord moet minstens 1 hoofdletter bevatten",
        ValidationErrorPasswordMustHaveDigits: "Wachtwoord moet minstens 1 cijfer bevatten",
        ValidationErrorPhoneRequired: "Voer uw mobiele nummer in",
        ValidationErrorPhoneNotValid: "Voer een geldig mobiel nummer in",
        ValidationErrorMessageToEmpty: "Bericht aan veld verplicht",
        WorkingDocuments: "Werkdocumenten",
        Yes: "Ja"
    },
};

export const LanguageContext = React.createContext(locales.en);

export class LocalizationModule {
    static getLocaleStrings() {
        const localeLang = navigator.language?.toLowerCase()?.substring(0, 2) ?? "";

        switch (localeLang) {
            case "en":
                return locales.en;
            case "nl":
                return locales.nl;
            default:
                return locales.en;
        }
    }
}
