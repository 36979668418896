import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MediaMode } from "../../helpers/enums";

type InitialState = {
    mediaMode?: MediaMode;
    isCameraDeviceActive: boolean;
};
const initialState: InitialState = {
    mediaMode: MediaMode.None,
    isCameraDeviceActive: false,
};

const instaSlice = createSlice({
    name: "insta",
    initialState,
    reducers: {
        setMediaMode: (state, action: PayloadAction<MediaMode>) => {
            state.mediaMode = action.payload;
        },
        setIsCameraDeviceActive: (state, action: PayloadAction<boolean>) => {
            state.isCameraDeviceActive = action.payload;
        },
        resetInsta: () => initialState,
    },
});

export default instaSlice.reducer;
export const { setMediaMode, setIsCameraDeviceActive, resetInsta } = instaSlice.actions;
