import { Label, Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import { LanguageContext } from "../helpers/LocalizationModule";

export const NoItemsFound: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);

    return (
        <div className="text-center mt-4">
            <Label disabled>{languageStrings.NoItemsFound}</Label>
        </div>
    );
};
