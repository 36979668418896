import { ICommandBarItemProps, IContextualMenuItem } from "@fluentui/react";
import React from "react";
import { FileCategory, FileType, FolderSortByOption } from "../helpers/enums";
import { LanguageContext } from "../helpers/LocalizationModule";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { BrumCommandBar } from "../_components/BrumCommandBar";
import { PageFolderItemList } from "../_components/page/PageFolderItemList";
import { PageFolderList } from "../_components/page/PageFolderList";
import { BrumSpinner } from "../_components/BrumSpinner";
import { getPatients } from "../services/userService";
import { setUser } from "../redux/slices/userSlice";
import { ApprovalFolderName } from "../helpers/constants";

export const HomePage: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);
    const dispatch = useAppDispatch();
    const selectedFolder = useAppSelector((state) => state.navigation.selectedFolder);
    const selectedPatient = useAppSelector((state) => state.user.selectedPatient);
    const notApprovedCount = useAppSelector((state) => state.navigation.notApprovedCount);
    const createdByList = useAppSelector((state) => state.navigation.createdByList);
    const addressedToList = useAppSelector((state) => state.navigation.addressedToList);
    const user = useAppSelector((state) => state.user.user);

    const [isBusy, setIsBusy] = React.useState(true);
    const [commandBarFarItems, setCommandBarFarItems] = React.useState<ICommandBarItemProps[]>([]);
    const [selectedFilterOptions, setSelectedFilterOptions] = React.useState<string[]>([]);
    const [selectedCreatedByOptions, setSelectedCreatedByOptions] = React.useState<string[]>([]);
    const [selectedAddressedToOptions, setSelectedAddressedToOptions] = React.useState<string[]>([]);

    const [selectedFolderSortOption, setSelectedFolderSortOption] = React.useState<FolderSortByOption>(FolderSortByOption.NameAscending);
    const [selectedFileSortOption, setSelectedFileSortOption] = React.useState<FolderSortByOption>(FolderSortByOption.DateDescending);
    const [filterMessageTo, setFilterMessageTo] = React.useState("");

    React.useEffect(() => {
        if (user?.id && !user?.patients) {
            (async () => {
                try {
                    const userPatients = await getPatients(user.id);
                    dispatch(setUser({ ...user, patients: userPatients }));
                } catch (error) {
                    console.error(error);
                }
                setIsBusy(false);
            })();
        }
        else {
            setIsBusy(false);
        }
    }, [user]);

    React.useEffect(() => {
        setSelectedAddressedToOptions(filterMessageTo ? [`${user?.firstName} ${user?.lastName}`] : []);
    }, [filterMessageTo]);

    React.useEffect(() => {
        const farItems: ICommandBarItemProps[] = [
            {
                key: "Sort",
                text: languageStrings.SortBy,
                iconProps: { iconName: "Sort" },
                subMenuProps: {
                    items: [],
                },
            }
        ];

        if (selectedFolder) {
            farItems[0].subMenuProps?.items.push(
                {
                    key: "dateAsc",
                    text: languageStrings.DateAscending,
                    iconProps: { iconName: "Ascending" },
                    checked: selectedFileSortOption === FolderSortByOption.DateAscending,
                    canCheck: true,
                    onClick: () => {
                        setSelectedFileSortOption(FolderSortByOption.DateAscending);
                    },
                },
                {
                    key: "dateDesc",
                    text: languageStrings.DateDescending,
                    iconProps: { iconName: "Descending" },
                    canCheck: true,
                    checked: selectedFileSortOption === FolderSortByOption.DateDescending,
                    onClick: () => {
                        setSelectedFileSortOption(FolderSortByOption.DateDescending);
                    },
                }
            );

            farItems.push({
                key: "Filter",
                text: languageStrings.FilterBy,
                iconProps: { iconName: "Filter" },
                subMenuProps: {
                    items: [
                        {
                            key: "FilterByAll",
                            text: languageStrings.FilterByAll,
                            iconProps: { iconName: "AllApps" },
                            checked: selectedFilterOptions.length === 0,
                            canCheck: true,
                            onClick: () => {
                                setSelectedFilterOptions([]);
                            },
                        },
                        {
                            key: "FilterByPhoto",
                            text: languageStrings.FilterByPhoto,
                            iconProps: { iconName: "Camera" },
                            checked: selectedFilterOptions.includes(FileType.Image),
                            canCheck: true,
                            onClick: () => {
                                if (selectedFilterOptions.includes(FileType.Image)) {
                                    setSelectedFilterOptions([...selectedFilterOptions.filter((x) => x !== FileType.Image)]);
                                } else {
                                    setSelectedFilterOptions([...selectedFilterOptions, FileType.Image]);
                                }
                            },
                        },
                        {
                            key: "FilterByVideo",
                            text: languageStrings.FilterByVideo,
                            iconProps: { iconName: "Video" },
                            checked: selectedFilterOptions.includes(FileType.Video),
                            canCheck: true,
                            onClick: () => {
                                if (selectedFilterOptions.includes(FileType.Video)) {
                                    setSelectedFilterOptions([...selectedFilterOptions.filter((x) => x !== FileType.Video)]);
                                } else {
                                    setSelectedFilterOptions([...selectedFilterOptions, FileType.Video]);
                                }
                            },
                        },
                        {
                            key: "FilterByNote",
                            text: languageStrings.FilterByNote,
                            iconProps: { iconName: "EditNote" },
                            checked: selectedFilterOptions.includes(FileType.Note),
                            canCheck: true,
                            onClick: () => {
                                if (selectedFilterOptions.includes(FileType.Note)) {
                                    setSelectedFilterOptions([...selectedFilterOptions.filter((x) => x !== FileType.Note)]);
                                } else {
                                    setSelectedFilterOptions([...selectedFilterOptions, FileType.Note]);
                                }
                            },
                        },
                        {
                            key: "FilterByFile",
                            text: languageStrings.FilterByFile,
                            iconProps: { iconName: "DietPlanNotebook" },
                            checked: selectedFilterOptions.includes(FileType.File),
                            canCheck: true,
                            onClick: () => {
                                if (selectedFilterOptions.includes(FileType.File)) {
                                    setSelectedFilterOptions([...selectedFilterOptions.filter((x) => x !== FileType.File)]);
                                } else {
                                    setSelectedFilterOptions([...selectedFilterOptions, FileType.File]);
                                }
                            },
                        },
                    ],
                },
            });
        } else {
            farItems[0].subMenuProps?.items.push(
                {
                    key: "nameAsc",
                    text: languageStrings.NameAscending,
                    iconProps: { iconName: "Ascending" },
                    checked: selectedFolderSortOption === FolderSortByOption.NameAscending,
                    canCheck: true,
                    onClick: () => {
                        setSelectedFolderSortOption(FolderSortByOption.NameAscending);
                    },
                },
                {
                    key: "nameDesc",
                    text: languageStrings.NameDescending,
                    iconProps: { iconName: "Descending" },
                    canCheck: true,
                    checked: selectedFolderSortOption === FolderSortByOption.NameDescending,
                    onClick: () => {
                        setSelectedFolderSortOption(FolderSortByOption.NameDescending);
                    },
                }
            );

            farItems.push({
                key: "MessageToFilter",
                text: languageStrings.MessageTo,
                iconProps: { iconName: "Message" },
                subMenuProps: {
                    items: [
                        {
                            key: "MessageToFilterAll",
                            text: languageStrings.All,
                            iconProps: { iconName: "People" },
                            checked: filterMessageTo.length === 0,
                            canCheck: true,
                            onClick: () => {
                                setFilterMessageTo("");
                            },
                        },
                        {
                            key: "MessageToFilterMe",
                            text: languageStrings.AssignedToMe,
                            iconProps: { iconName: "ProfileSearch" },
                            canCheck: true,
                            checked: filterMessageTo.length > 0,
                            onClick: () => {
                                if (user) {
                                    setFilterMessageTo(user.id.toString());
                                }
                            },
                        }
                    ],
                },
            })
        }

        if (selectedFolder && selectedFolder?.folderName === ApprovalFolderName && createdByList?.length > 0) {
            let items: IContextualMenuItem[] = [];

            items.push({
                key: "FilterByAllCreatedBy",
                text: languageStrings.FilterByAll,
                iconProps: { iconName: "AllApps" },
                checked: selectedCreatedByOptions.length === 0,
                canCheck: true,
                onClick: () => {
                    setSelectedCreatedByOptions([]);
                },
            });

            createdByList.forEach((item, index) => {
                items.push({
                    key: `cb_${index}_${item}`,
                    text: item,
                    iconProps: { iconName: "Contact" },
                    checked: selectedCreatedByOptions.includes(item === languageStrings.Me ? `${user?.firstName} ${user?.lastName}` : item),
                    canCheck: true,
                    onClick: () => {
                        const itemDisplayText = item === languageStrings.Me ? `${user?.firstName} ${user?.lastName}` : item;
                        if (selectedCreatedByOptions.includes(itemDisplayText)) {
                            setSelectedCreatedByOptions([...selectedCreatedByOptions.filter((x) => x !== itemDisplayText)]);
                        } else {
                            setSelectedCreatedByOptions([...selectedCreatedByOptions, itemDisplayText]);
                        }
                    },
                });
            });

            farItems.push({
                key: "FilterCreatedBy",
                text: languageStrings.CreatedBy,
                iconProps: { iconName: "Add" },
                subMenuProps: {
                    items,
                },
            });
        }

        if (selectedFolder && selectedFolder?.folderName !== ApprovalFolderName && addressedToList?.length > 0) {
            let items: IContextualMenuItem[] = [];

            items.push({
                key: "FilterByAllAddressedTo",
                text: languageStrings.FilterByAll,
                iconProps: { iconName: "AllApps" },
                checked: selectedAddressedToOptions.length === 0,
                canCheck: true,
                onClick: () => {
                    setSelectedAddressedToOptions([]);
                },
            });

            addressedToList.forEach((item, index) => {
                items.push({
                    key: `at_${index}_${item}`,
                    text: item,
                    iconProps: { iconName: "Contact" },
                    checked: selectedAddressedToOptions.includes(item === languageStrings.Me ? `${user?.firstName} ${user?.lastName}` : item),
                    canCheck: true,
                    onClick: () => {
                        const itemDisplayText = item === languageStrings.Me ? `${user?.firstName} ${user?.lastName}` : item;
                        if (selectedAddressedToOptions.includes(itemDisplayText)) {
                            setSelectedAddressedToOptions([...selectedAddressedToOptions.filter((x) => x !== itemDisplayText)]);
                        } else {
                            setSelectedAddressedToOptions([...selectedAddressedToOptions, itemDisplayText]);
                        }
                    },
                });
            });

            farItems.push({
                key: "FilterAddressedTo",
                text: languageStrings.MessageTo,
                iconProps: { iconName: "Message" },
                subMenuProps: {
                    items,
                },
            });
        }

        if (notApprovedCount > 0) {
            farItems.unshift({
                key: "Count",
                text: `${notApprovedCount === 1 ? `${notApprovedCount} ${languageStrings.ItemWaitingForApproval}` : `${notApprovedCount} ${languageStrings.ItemsWaitingForApproval}`}`,
                disabled: true,
            });
        }

        setCommandBarFarItems(farItems);
    }, [
        selectedPatient,
        selectedFileSortOption,
        selectedFolderSortOption,
        notApprovedCount,
        selectedFolder,
        selectedFilterOptions,
        createdByList,
        addressedToList,
        selectedCreatedByOptions,
        selectedAddressedToOptions,
        setSelectedCreatedByOptions,
        selectedFolder?.folderName,
        filterMessageTo,
        user
    ]);

    return (
        <>
            <BrumCommandBar commandBarFarItems={commandBarFarItems} />
            <div className="h-[calc(100%-46px)] overflow-scroll overflow-x-hidden p-4 scrollbar-thin theme-scrollbar theme-scrollbar-track">
                {isBusy && (
                    <div>
                        <BrumSpinner />
                    </div>
                )}
                {!isBusy && !selectedFolder && <PageFolderList fileCategory={FileCategory.Insta} sortOption={selectedFolderSortOption} assignedToUserId={filterMessageTo} />}
                {!isBusy && selectedFolder && (
                    <PageFolderItemList
                        fileCategory={FileCategory.Insta}
                        sortOption={selectedFileSortOption}
                        filterOptions={selectedFilterOptions}
                        filterCreatedByOptions={selectedCreatedByOptions}
                        filterAddressedToOptions={selectedAddressedToOptions || filterMessageTo}
                    />
                )}
            </div>
        </>
    );
};
