import { Icon, Label } from "@fluentui/react";

export interface IInstaCardProps {
    iconName: string;
    label: string;
    cardClicked: () => void;
}

export const InstaCard: React.FunctionComponent<IInstaCardProps> = ({ iconName, label, cardClicked }: IInstaCardProps) => {
    return (
        <div
            className="
            mx-auto
            sm:m-auto 
            text-center 
            p-6 
            lg:p-14
            shadow-purple-200 
            shadow-lg 
            border-2 
            border-purple-200 
            bg-white 
            hover:cursor-pointer 
            hover:shadow-purple-40
            min-w-[250px] 
            md:min-w-[300px]
            "
            onClick={() => cardClicked()}
        >
            <div>
                <Icon className="text-5xl lg:text-9xl theme-color-text" iconName={iconName}></Icon>
            </div>
            <div>
                <Label className="hover:cursor-pointer text-xl lg:text-4xl">{label}</Label>
            </div>
        </div>
    );
};
