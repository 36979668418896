import { Label } from "@fluentui/react";
import React from "react";
import { FileCategory, MediaMode } from "../helpers/enums";
import { LanguageContext } from "../helpers/LocalizationModule";
import { IPatient } from "../interfaces/IPatient";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setMediaMode } from "../redux/slices/instaSlice";
import { setSelectedPatient } from "../redux/slices/userSlice";
import { InstaCamera } from "../_components/page/Insta/InstaCamera";
import { InstaMain } from "../_components/page/Insta/InstaMain";
import { InstaVideo } from "../_components/page/Insta/InstaVideo";
import { SelectPatientPanel } from "../_components/panels/SelectPatientPanel";
import { UploadFilePanel } from "../_components/panels/UploadFilePanel";
import { UploadLocalFilePanel } from "../_components/panels/UploadLocalFilePanel";

export const InstaPage: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);
    const dispatch = useAppDispatch();

    const mediaMode = useAppSelector((state) => state.insta.mediaMode);
    const selectedPatient = useAppSelector((state) => state.user.selectedPatient);

    const [videoBlob, setVideoBlob] = React.useState<Blob>();
    const [videoBase64, setVideoBase64] = React.useState<string>("");
    const [imageData, setImageData] = React.useState<string>("");
    const [uploadedFormFile, setUploadedFormFile] = React.useState<any>();

    const [isUploadFilePanelOpen, setIsUploadFilePanelOpen] = React.useState(false);
    const [isSelectPatientsPanelOpen, setIsSelectPatientsPanelOpen] = React.useState(false);
    const [convertFileToVideo, setConvertFileToVideo] = React.useState(false);

    React.useEffect(() => {
        if (mediaMode === MediaMode.None && !selectedPatient) {
            setIsSelectPatientsPanelOpen(true);
        }
        if (mediaMode === MediaMode.Note) {
            setIsUploadFilePanelOpen(true);
        }
    }, [mediaMode, selectedPatient]);

    const videoBlobChanged = (vidblob: Blob) => {
        setImageData("");
        setVideoBlob(vidblob);
        setVideoBase64("");
        setIsUploadFilePanelOpen(true);
        setUploadedFormFile(undefined);
        setConvertFileToVideo(false);
    };

    const videoBase64Changed = (videoBase64?: string, uploadedFormFile?: any) => {
        setImageData("");
        setVideoBlob(undefined);
        //setVideoBase64(videoBase64);
        setIsUploadFilePanelOpen(true);
        setUploadedFormFile(uploadedFormFile);
        setConvertFileToVideo(true);
    };

    const photoChanged = (data: string) => {
        setVideoBlob(undefined);
        setVideoBase64("");
        setImageData(data);
        setIsUploadFilePanelOpen(true);
        setUploadedFormFile(undefined);
        setConvertFileToVideo(false);
    };

    const patientSelected = (patient: IPatient) => {
        dispatch(setSelectedPatient(patient.id! > 0 ? patient : undefined));
        setIsSelectPatientsPanelOpen(false);
    };

    return (
        <>
            {selectedPatient && (
                <div className="h-[100%] overflow-scroll overflow-x-hidden p-4 scrollbar-thin theme-scrollbar theme-scrollbar-track ">
                    {mediaMode === MediaMode.None && <InstaMain />}
                    {mediaMode === MediaMode.Camera && !imageData && <InstaCamera photoChanged={photoChanged} />}
                    {mediaMode === MediaMode.Video && !videoBlob && <InstaVideo videoBlobChanged={videoBlobChanged} videoBase64Changed={videoBase64Changed} />}

                    {isUploadFilePanelOpen && (
                        <UploadFilePanel
                            isOpen={isUploadFilePanelOpen}
                            dismissPanel={() => {
                                setImageData("");
                                setVideoBlob(undefined);
                                setVideoBase64("");
                                setIsUploadFilePanelOpen(false);
                                dispatch(setMediaMode(MediaMode.None));
                            }}
                            videoBlob={videoBlob}
                            imageData={imageData}
                            videoBase64={videoBase64}
                            uploadedFormFile={uploadedFormFile}
                            convertFileToVideo={convertFileToVideo}
                        />
                    )}
                    {mediaMode === MediaMode.FileUploadMedia && (
                        <UploadLocalFilePanel
                            isOpen={mediaMode === MediaMode.FileUploadMedia}
                            dismissPanel={() => {
                                dispatch(setMediaMode(MediaMode.None))
                            }}
                            fileCategory={FileCategory.Insta}
                            onSuccess={() => {
                                dispatch(setMediaMode(MediaMode.None))
                            }}
                        />
                    )}
                </div>
            )}
            {!selectedPatient && (
                <div className="text-center mt-6">
                    <Label disabled className="text-lg">
                        {languageStrings.NoChildSelected}
                    </Label>
                </div>
            )}
            {isSelectPatientsPanelOpen && (
                <SelectPatientPanel
                    isOpen={isSelectPatientsPanelOpen}
                    dismissPanel={() => {
                        setIsSelectPatientsPanelOpen(false);
                    }}
                    itemSelected={patientSelected}
                    isAdminMode={false}
                />
            )}
        </>
    );
};
