import {
    DefaultButton,
    Dropdown,
    DropdownMenuItemType,
    IDropdownOption,
    IPanelStyles,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    ProgressIndicator,
    Spinner,
    SpinnerSize,
    TextField,
} from "@fluentui/react";
import React from "react";
import { AppPage, FileCategory, FileType, MediaMode, UploadStatus, UserRole, UserType } from "../../helpers/enums";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { isMobile } from "react-device-detect";
import { IFilePayload } from "../../interfaces/IFilePayload";
import { setCurrentPage } from "../../redux/slices/navigationSlice";
import { LanguageContext } from "../../helpers/LocalizationModule";
import axios, { AxiosRequestConfig } from "axios";
import { getPatient } from "../../services/patientService";
import { Buffer } from "buffer";

const buttonStyles = { root: { marginLeft: 8 } };

const panelStyles: Partial<IPanelStyles> = {
    content: {
        overflowX: "hidden",
        touchAction: "true",
        height: "calc(100vh - 124px);",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
    },
};

export interface IUploadFilePanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    videoBlob?: Blob;
    videoBase64?: string;
    imageData?: string;
    uploadedFormFile?: any;
    convertFileToVideo?: boolean;
}

export const UploadFilePanel: React.FunctionComponent<IUploadFilePanelProps> = ({
    isOpen,
    dismissPanel,
    videoBlob,
    videoBase64,
    imageData,
    uploadedFormFile,
    convertFileToVideo,
}: IUploadFilePanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const videoMaxBytes = 1000000000;
    const videoMaxBytesPreview = 100000000;

    const dispatch = useAppDispatch();
    const mediaMode = useAppSelector((state) => state.insta.mediaMode);
    const selectedPatient = useAppSelector((state) => state.user.selectedPatient);
    const user = useAppSelector((state) => state.user.user);

    const [uploadStatus, setUploadStatus] = React.useState<UploadStatus>(UploadStatus.Init);
    const [percentComplete, setPercentComplete] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [isApprover, setIsApprover] = React.useState(false);
    const [isMedewerkerBrumBrum, setIsMedewerkerBrumBrum] = React.useState(false);

    const [videoTooBigForPreview, setVideoTooBigForPreview] = React.useState(false);
    const [videoTooBigForUpload, setVideoTooBigForUpload] = React.useState(false);
    const [videoSizeMB, setVideoSizeMB] = React.useState(0);
    const [videoMaxSizeMB, setVideoMaxSizeMB] = React.useState(videoMaxBytes / 1e6);

    const videoRef = React.useRef<any>(null);

    const [base64, setBase64] = React.useState("");

    const [tagUserOptions, setTagUserOptions] = React.useState<IDropdownOption[]>([]);

    const [newFile, setNewFile] = React.useState<IFilePayload>({
        patientId: 0,
        fileCategoryId: FileCategory.Insta,
        name: "",
        description: "",
    });

    React.useEffect(() => {
        setIsMedewerkerBrumBrum(user?.typeId === UserType.Medewerker ? true : false);
        setIsApprover(user?.roles.find((x) => x.id === UserRole.Goedkeurder || x.id === UserRole.Admin) ? true : false);
    }, [user]);

    React.useEffect(() => {
        (async () => {
            if (selectedPatient) {
                const patient = await getPatient(selectedPatient.id!.toString());

                const medewerkers = patient.users.filter((x) => x.typeId === UserType.Medewerker);
                const gaurdians = patient.users.filter((x) => x.typeId === UserType.Gaurdian);
                const caretakers = patient.users.filter((x) => x.typeId === UserType.Caretaker);

                const options: IDropdownOption[] = [];

                if (medewerkers.length > 0 && user?.typeId === UserType.Gaurdian) {
                    options.push({ key: "medewerkers", text: languageStrings.BrumBrumEmployees, itemType: DropdownMenuItemType.Header });
                    medewerkers.forEach((medewerker) => {
                        options.push({ key: medewerker.id!, text: `${medewerker.firstName} ${medewerker.lastName}` });
                    });
                }

                if (caretakers.length > 0 && user?.typeId === UserType.Gaurdian) {
                    options.push({ key: "caretakers", text: languageStrings.Caretakers, itemType: DropdownMenuItemType.Header });
                    caretakers.forEach((caretaker) => {
                        options.push({ key: caretaker.id!, text: `${caretaker.firstName} ${caretaker.lastName}` });
                    });
                }

                if (gaurdians.length > 0) {
                    options.push({ key: "gaurdians", text: languageStrings.Parents, itemType: DropdownMenuItemType.Header });
                    gaurdians.forEach((gaurdian) => {
                        options.push({ key: gaurdian.id!, text: `${gaurdian.firstName} ${gaurdian.lastName}` });
                    });
                }

                setTagUserOptions(options);
            }
        })();
    }, [selectedPatient, user]);

    React.useEffect(() => {
        if (videoBlob) {
            var reader = new FileReader();
            reader.readAsDataURL(videoBlob!);
            reader.onloadend = function () {
                var base64data = reader.result;
                const buffer = Buffer.from((base64data as string).substring((base64data as string).indexOf(",") + 1));
                if (buffer.length > videoMaxBytes) {
                    setVideoSizeMB(buffer.length / 1e6);
                    setVideoTooBigForUpload(true);
                } else if (buffer.length > videoMaxBytesPreview) {
                    setVideoTooBigForPreview(true);
                    setBase64(base64data as string);
                }
                else {
                    setBase64(base64data as string);
                }
            };
        } else if (videoBase64) {
            const buffer = Buffer.from((videoBase64 as string).substring((videoBase64 as string).indexOf(",") + 1));
            if (buffer.length > videoMaxBytes) {
                setVideoSizeMB(buffer.length / 1e6);
                setVideoTooBigForUpload(true);
            } else if (buffer.length > videoMaxBytesPreview) {
                setVideoTooBigForPreview(true);
                setBase64(videoBase64 as string);
            }
            else {
                setBase64(videoBase64 as string);
            }
        } else if (convertFileToVideo) {
            if (uploadedFormFile.size > videoMaxBytes) {
                setVideoSizeMB(uploadedFormFile.size / 1e6);
                setVideoTooBigForUpload(true);
            } else if (uploadedFormFile.size > videoMaxBytesPreview) {
                setVideoTooBigForPreview(true);
                var reader = new FileReader();
                reader.readAsDataURL(uploadedFormFile);
                reader.onloadend = function (evt) {
                    videoRef.current.src = evt?.target?.result;
                };
            }
            else {
                var reader = new FileReader();
                reader.readAsDataURL(uploadedFormFile);
                reader.onloadend = function (evt) {
                    videoRef.current.src = evt?.target?.result;
                };
            }
        }
    }, [videoBlob, videoBase64]);

    const onSaveFile = async () => {
        if (user?.typeId === UserType.Gaurdian && !newFile.userAddressedTo) {
            setErrorMessage(languageStrings.ValidationErrorMessageToEmpty);
            return;
        }

        setErrorMessage("");

        setUploadStatus(UploadStatus.Uploading);
        let base64Part = "";
        let fileType = "";

        if (mediaMode === MediaMode.Video) {
            base64Part = (base64 as string).split(",")[1];
            fileType = FileType.Video;
        } else if (mediaMode === MediaMode.Camera) {
            base64Part = (imageData as string).split(",")[1];
            fileType = FileType.Image;
        } else if (mediaMode === MediaMode.Note) {
            fileType = FileType.Note;
        }

        const formData = new FormData();
        if (uploadedFormFile) {
            formData.set("file", uploadedFormFile, uploadedFormFile.name);
        } else if (videoBlob) {
            formData.set("file", videoBlob, `${new Date().toUTCString()}.webm`);
        } else if (imageData) {
            const base64Response = await fetch(imageData);
            const imageBlob = await base64Response.blob();

            formData.set("file", imageBlob, `${new Date().toUTCString()}.jpg`);
        }

        formData.set("userId", user?.id!.toString() as string);
        formData.set("patientId", selectedPatient?.id?.toString() as string);
        formData.set("fileCategoryId", newFile.fileCategoryId!.toString());
        formData.set("description", newFile.description ?? "");
        formData.set("type", fileType);
        formData.set("approved", isMedewerkerBrumBrum ? "false" : "true"); //UserAddressedTo
        formData.set("userAddressedTo", newFile.userAddressedTo?.toString() ?? "");

        const config: AxiosRequestConfig<FormData> = {
            onUploadProgress: function (progressEvent: any) {
                const percentCompleted = progressEvent.loaded / progressEvent.total;
                setPercentComplete(+percentCompleted.toFixed(2));

                if (percentCompleted === 1) {
                    setUploadStatus(UploadStatus.Finalizing);
                }
            },
        };

        axios.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/files/upload`, formData, config)
            .then((res) => {
                setErrorMessage("");
                setUploadStatus(UploadStatus.Completed);
                if (!isMedewerkerBrumBrum) {
                    dispatch(setCurrentPage(AppPage.HomePage));
                }
            })
            .catch((err) => {
                if (err.response?.data?.errors?.Description?.length > 0) {
                    setErrorMessage(err.response?.data?.errors?.Description[0]);
                } else {
                    setErrorMessage(languageStrings.ErrorOccured);
                }

                setUploadStatus(UploadStatus.Init);
            });
    };

    const getProgressLabel = React.useCallback(() => {
        if (uploadStatus === UploadStatus.Uploading) {
            return `${Math.round(percentComplete * 100)}% ${languageStrings.Complete}`;
        }
        if (uploadStatus === UploadStatus.Finalizing) {
            return languageStrings.FinalizingUpload;
        }

        return languageStrings.UploadComplete;
    }, [uploadStatus, percentComplete]);

    const getProgressDescription = () => {
        if (mediaMode === MediaMode.Camera) {
            return languageStrings.UploadingImage;
        } else if (mediaMode === MediaMode.Video) {
            return languageStrings.UploadingVideo;
        } else if (mediaMode === MediaMode.Note) {
            return languageStrings.UploadingNote;
        }

        return "";
    };

    const showNoteAssignTo: boolean = React.useMemo(() => {
        // return (user?.typeId === UserType.Medewerker || user?.typeId === UserType.Gaurdian) && tagUserOptions?.length > 0;
        return tagUserOptions?.length > 0;
    }, [mediaMode, user, tagUserOptions]);

    const onRenderFooterContent = React.useCallback(
        () => (
            <div className="text-right">
                {uploadStatus === UploadStatus.Completed && <DefaultButton onClick={() => dispatch(setCurrentPage(AppPage.HomePage))}>{languageStrings.Done}</DefaultButton>}
                {uploadStatus !== UploadStatus.Completed && (
                    <DefaultButton onClick={dismissPanel} disabled={uploadStatus !== UploadStatus.Init}>
                        {languageStrings.Cancel}
                    </DefaultButton>
                )}
                {uploadStatus !== UploadStatus.Completed && (
                    <DefaultButton
                        styles={buttonStyles}
                        // disabled={uploadStatus !== UploadStatus.Init || videoTooBigForUpload || (mediaMode === MediaMode.Note && (!newFile?.description || (showNoteAssignTo && !newFile?.userAddressedTo)))}
                        disabled={uploadStatus !== UploadStatus.Init
                            || videoTooBigForUpload
                            || (mediaMode === MediaMode.Note && !newFile?.description)
                            || (showNoteAssignTo && !newFile?.userAddressedTo)}
                        text={languageStrings.Save}
                        onClick={() => onSaveFile()}
                    >
                        {uploadStatus !== UploadStatus.Init && <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: 5 } }}></Spinner>}
                    </DefaultButton>
                )}
            </div>
        ),
        [dismissPanel, uploadStatus, newFile, mediaMode, showNoteAssignTo, onSaveFile]
    );

    return (
        <Panel
            headerText={mediaMode === MediaMode.Note ? languageStrings.AddNote : languageStrings.Upload}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            type={PanelType.custom}
            customWidth={`${isMobile ? "100%" : "500px"}`}
            closeButtonAriaLabel="Close"
            styles={panelStyles}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            allowTouchBodyScroll
        >
            <div className="">
                <div className="pb-2">
                    {videoTooBigForUpload && (
                        <div className="text-xl pt-8 text-center">
                            {languageStrings.VideoTooLarge.replace("[currentSize]", videoSizeMB.toFixed(2).toString()).replace("[maxSize]", videoMaxSizeMB.toString())}
                        </div>
                    )}
                    {videoTooBigForPreview && (
                        <div className="text-xl pt-8 text-center">
                            {languageStrings.VideoTooLargePreview}
                        </div>
                    )}
                </div>
                <div className="pb-2">
                    {(videoBlob || videoBase64) && !convertFileToVideo && !videoTooBigForPreview && <video src={base64} controls className="max-h-[300px] w-full object-cover"></video>}
                </div>
                <div className="pb-2">{convertFileToVideo && !videoTooBigForPreview && !videoTooBigForUpload && <video ref={videoRef} controls className="max-h-[300px] w-full object-cover"></video>}</div>
                <div className="pb-2">{imageData && <img src={imageData} alt="" className="max-h-[300px] w-full object-cover" />}</div>
                {!videoTooBigForUpload && (
                    <div className="pb-2">
                        <TextField
                            label={mediaMode === MediaMode.Note ? languageStrings.Note : languageStrings.Description}
                            required={mediaMode === MediaMode.Note}
                            multiline
                            rows={8}
                            value={newFile?.description}
                            onChange={(e, newValue) => {
                                setNewFile({ ...newFile, description: newValue ? newValue : "" });
                            }}
                        />
                    </div>
                )}

                {showNoteAssignTo && (
                    <div className="pb-2">
                        <Dropdown
                            label={languageStrings.MessageTo}
                            required
                            options={tagUserOptions}
                            onChange={(event, option) => {
                                setNewFile({ ...newFile, userAddressedTo: option?.key ? (option?.key as number) : undefined });
                            }}
                        />
                    </div>
                )}
                {errorMessage && (
                    <div className="mt-4">
                        <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                            {errorMessage}
                        </MessageBar>
                    </div>
                )}
                {uploadStatus !== UploadStatus.Init && mediaMode !== MediaMode.Note && (
                    <div className="mt-4">
                        <ProgressIndicator label={getProgressLabel()} description={getProgressDescription()} percentComplete={percentComplete} />
                    </div>
                )}
                {uploadStatus === UploadStatus.Completed && (
                    <MessageBar messageBarType={MessageBarType.success} className="mt-4">
                        {languageStrings.ItemSubmittedForApproval}
                    </MessageBar>
                )}
            </div>
        </Panel>
    );
};
