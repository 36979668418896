import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, ICommandBarItemProps, mergeStyles, Persona, PersonaSize, SelectionMode, Selection } from "@fluentui/react";
import React from "react";
import { AdminCrudMode, UserType } from "../../helpers/enums";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { IUser } from "../../interfaces/IUser";
import { getUsers, deleteUser } from "../../services/userService";
import { BrumCommandBar } from "../../_components/BrumCommandBar";
import { BrumSpinner } from "../../_components/BrumSpinner";
import { ConfirmDialog } from "../../_components/ConfirmDialog";
import { AddUpdateUserPanel } from "../../_components/panels/AddUpdateUserPanel";
import { isMobile, isBrowser } from "react-device-detect";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setLoggedInUserFromUser } from "../../redux/slices/userSlice";
import { StorageHelper } from "../../helpers/StorageHelper";

export const AdminUsersPage: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);

    const dispatch = useAppDispatch();

    const [isBusy, setisBusy] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);

    const [users, setUsers] = React.useState<IUser[]>([]);
    const [filteredUsers, setFilteredUsers] = React.useState<IUser[]>([]);
    const [searchText, setSearchText] = React.useState<string>("");

    const [hideConfirmDeleteDialog, setHideConfirmDeleteDialog] = React.useState(true);
    const loggedInUser = useAppSelector((state) => state.user.user);

    const [commandBarItems, setCommandBarItems] = React.useState<ICommandBarItemProps[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<any>();

    const [isAddUpdateUserPanelOpen, setIsAddUpdateUserPanelOpen] = React.useState(false);
    const [adminCrudeMode, setAdminCrudeMode] = React.useState<AdminCrudMode>(AdminCrudMode.None);
    const [columns, setColumns] = React.useState<IColumn[]>([]);

    const columnClickedRef = React.useRef<{ key: string, isSorted: boolean, isSortedDescending: boolean }>();

    const _selection = React.useRef(
        new Selection({
            onSelectionChanged: () => getSelectionDetails(),
        })
    );

    const getSelectionDetails = () => {
        const filteredSelectedItems = _selection.current.getSelection().filter((x) => x);

        if (filteredSelectedItems?.length === 1) {
            let selectedItem: any = filteredSelectedItems[0];
            setSelectedItem(selectedItem);
        } else {
            setSelectedItem(null);
        }
    };

    const _onColumnClick = React.useCallback((_ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
        try {
            setColumns(prevCols => {
                const newColumns = prevCols.slice();
                const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    
                newColumns.forEach((newCol: IColumn) => {
                    if (newCol === currColumn) {
                        currColumn.isSortedDescending = !currColumn.isSortedDescending;
                        currColumn.isSorted = true;
    
                        columnClickedRef.current = {
                            key: currColumn.key,
                            isSorted: currColumn.isSorted,
                            isSortedDescending: currColumn.isSortedDescending
                        };
                    } else {
                        newCol.isSorted = false;
                        newCol.isSortedDescending = true;
                    }
                });

                setFilteredUsers(prevFiltered => {
                    return copyAndSort(prevFiltered, currColumn.fieldName!, currColumn.isSortedDescending);
                });

                return newColumns;
            });


        } catch (error) {
            console.error("onColumnClick: ", error);
        }
    }, [filteredUsers, columns, searchText]);

    function copyAndSort<T>(items: T[], columnKey?: string, isSortedDescending?: boolean): T[] {
        try {
            const key = columnKey as keyof T;
            return items.slice(0).sort((a: T, b: T) => {
                // Convert the values to strings and then to lowercase for case-insensitive comparison
                let aValue = String(a[key]).toLowerCase();
                let bValue = String(b[key]).toLowerCase();
    
                if (aValue < bValue) {
                    return isSortedDescending ? 1 : -1;
                }
                if (aValue > bValue) {
                    return isSortedDescending ? -1 : 1;
                }
                return 0;
            });
        } catch (error) {
            console.error("copyAndSort: ", error);
            return items;
        }
    };

    React.useEffect(() => {
        setCommandBarItems([
            {
                key: "Add",
                text: languageStrings.Add,
                disabled: selectedItem || isDeleting,
                iconProps: { iconName: "Add" },
                onClick: () => {
                    setIsAddUpdateUserPanelOpen(true);
                    setAdminCrudeMode(AdminCrudMode.Add);
                },
            },
            {
                key: "Edit",
                text: languageStrings.Edit,
                disabled: !selectedItem || isDeleting,
                iconProps: { iconName: "Edit" },
                onClick: () => {
                    setIsAddUpdateUserPanelOpen(true);
                    setAdminCrudeMode(AdminCrudMode.Edit);
                },
            },
            {
                key: "Delete",
                text: `${isDeleting ? languageStrings.Deleting : languageStrings.Delete}`,
                disabled: !selectedItem || isDeleting,
                iconProps: { iconName: "Delete" },
                onClick: async () => {
                    await onDeleteUser();
                },
            },
        ]);
    }, [selectedItem, isDeleting]);

    React.useEffect(() => {
        let cols: IColumn[] = [
            {
                key: "displayName",
                name: languageStrings.Name,
                fieldName: "displayName",
                data: "string",
                minWidth: 100,
                maxWidth: 350,
                isResizable: true,
                isSorted: columnClickedRef.current && columnClickedRef.current.key === "displayName" ? columnClickedRef.current.isSorted : false,
                isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "displayName" ? columnClickedRef.current.isSortedDescending : false,
                onColumnClick: _onColumnClick,
                onRender: (user: IUser) => {
                    return (
                        <div className="">
                            {isBrowser && <Persona imageUrl={user.profileImage && `data:image/*;base64,${user.profileImage}`} text={`${user.firstName} ${user.lastName}`} size={PersonaSize.size40} />}
                            {isMobile && (
                                <Persona
                                    imageUrl={user.profileImage && `data:image/*;base64,${user.profileImage}`}
                                    text={`${user.firstName ?? ""} ${user.lastName ?? ""}`}
                                    secondaryText={`${
                                        user.typeId === UserType.Caretaker ? languageStrings.Caretaker : user.typeId === UserType.Gaurdian ? languageStrings.Parent : languageStrings.BrumBrumEmployee
                                    } (${user.roles.map((role) => role.name).join(", ")})`}
                                    size={PersonaSize.size40}
                                />
                            )}
                        </div>
                    );
                },
            },
        ];

        if (isBrowser) {
            cols.push(
                {
                    key: "typeDisplay",
                    name: languageStrings.Type,
                    fieldName: "typeDisplay",
                    data: "string",
                    minWidth: 100,
                    maxWidth: 350,
                    isResizable: true,
                    isSorted: columnClickedRef.current && columnClickedRef.current.key === "typeDisplay" ? columnClickedRef.current.isSorted : false,
                    isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "typeDisplay" ? columnClickedRef.current.isSortedDescending : false,
                    onColumnClick: _onColumnClick,
                },
                {
                    key: "rolesDisplay",
                    name: languageStrings.Role,
                    fieldName: "rolesDisplay",
                    data: "string",
                    minWidth: 100,
                    maxWidth: 350,
                    isResizable: true,
                    isSorted: columnClickedRef.current && columnClickedRef.current.key === "rolesDisplay" ? columnClickedRef.current.isSorted : false,
                    isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "rolesDisplay" ? columnClickedRef.current.isSortedDescending : false,
                    onColumnClick: _onColumnClick
                }
            );
        }

        setColumns(cols);
    }, [isBrowser]);

    React.useEffect(() => {
        (async () => {
            setisBusy(true);
            setUsers(await getUsers(languageStrings));
            setisBusy(false);
        })();
    }, []);

    React.useEffect(() => {
        if (!searchText) {
            setFilteredUsers([...users]);
        } else {
            setFilteredUsers([...users.filter(x => 
                    x.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
                    x.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
                    x.email?.toLowerCase().includes(searchText.toLowerCase())
                )]);
        }
    }, [users, searchText]);

    const onDeleteUser = async () => {
        setHideConfirmDeleteDialog(false);
    };

    const saveSuccess = async (savedUser: IUser) => {
        setIsAddUpdateUserPanelOpen(false);
        setisBusy(true);

        setUsers(await getUsers(languageStrings));
        setisBusy(false);

        if (adminCrudeMode === AdminCrudMode.Edit) {
            setSelectedItem(savedUser);
        }

        setAdminCrudeMode(AdminCrudMode.None);

        if (savedUser.id === loggedInUser?.id) {
            dispatch(setLoggedInUserFromUser(savedUser));
        }
    };

    React.useEffect(() => {
        if (loggedInUser) {
            StorageHelper.setItem("user", JSON.stringify(loggedInUser));
        }
    }, [loggedInUser]);

    const onConfirmDelete = async () => {
        setHideConfirmDeleteDialog(true);
        setIsDeleting(true);
        await deleteUser(selectedItem.id!);
        setIsDeleting(false);
        setisBusy(true);
        setUsers(await getUsers(languageStrings));
        setisBusy(false);

    };

    const searchChanged = (value: string) => {
        setSearchText(value);
    };

    return (
        <>
            <BrumCommandBar commandBarItems={commandBarItems} onSearch={searchChanged} />
            <div className="h-[calc(100%-46px)] overflow-scroll overflow-x-hidden scrollbar-thin theme-scrollbar theme-scrollbar-track">
                {!isBusy && (
                    <DetailsList
                        items={filteredUsers ?? []}
                        columns={columns}
                        selectionMode={SelectionMode.single}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        selectionPreservedOnEmptyClick={true}
                        selection={_selection.current}
                        checkboxCellClassName={mergeStyles({
                            display: "flex",
                            alignItems: "center",
                        })}
                    />
                )}
                {isBusy && (
                    <div className="text-center mt-4">
                        <BrumSpinner />
                    </div>
                )}
                {isAddUpdateUserPanelOpen && (
                    <AddUpdateUserPanel
                        isOpen={isAddUpdateUserPanelOpen}
                        dismissPanel={() => {
                            setIsAddUpdateUserPanelOpen(false);
                        }}
                        adminCrudMode={adminCrudeMode}
                        saveSuccess={saveSuccess}
                        selectedUser={selectedItem}
                        users={users}
                    />
                )}
            </div>
            <ConfirmDialog
                hideDialog={hideConfirmDeleteDialog}
                dismissDialog={() => setHideConfirmDeleteDialog(true)}
                title={languageStrings.ConfirmDelete}
                subText={languageStrings.ConfirmDeleteUserMessage}
                onConfirm={() => onConfirmDelete()}
            />
        </>
    );
};
