import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuthResponse } from "../../interfaces/IAuthResponse";
import { IPatient } from "../../interfaces/IPatient";
import { IUser } from "../../interfaces/IUser";

type InitialState = {
    user?: IAuthResponse;
    selectedPatient?: IPatient;
};
const initialState: InitialState = {
    user: undefined,
    selectedPatient: undefined,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<IAuthResponse | undefined>) => {
            state.user = action.payload;
            if (action.payload?.patients?.length === 1) {
                state.selectedPatient = action.payload.patients[0];
            }
        },
        setLoggedInUserFromUser: (state, action: PayloadAction<IUser>) => {
            if (state.user) {
                state.user.firstName = action.payload.firstName;
                state.user.lastName = action.payload.lastName;
                state.user.email = action.payload.email ?? "";
                state.user.patients = action.payload.patients?.filter(p => p.isArchived !== true);
                state.user.roles = action.payload.roles;
                state.user.typeId = action.payload.typeId;
                state.user.allowDownload = action.payload.allowDownload ? action.payload.allowDownload : false;
            }
        },
        setSelectedPatient: (state, action: PayloadAction<IPatient | undefined>) => {
            state.selectedPatient = action.payload;
        },
        restUser: () => initialState,
    },
});

export default userSlice.reducer;
export const { setUser, setSelectedPatient, restUser, setLoggedInUserFromUser } = userSlice.actions;
