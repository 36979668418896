import {
    ICommandBarItemProps,
    IPanelStyles,
    Panel,
    PanelType,
    DefaultButton,
    Text,
    Label,
    TextField,
    Spinner,
    SpinnerSize,
    MessageBar,
    MessageBarType,
    Dropdown,
    IDropdownOption,
    DropdownMenuItemType,
} from "@fluentui/react";
import React from "react";
import { AppPage, FileCategory, FileType, MimeContentType, UserRole, UserType } from "../../helpers/enums";
import { useAppSelector } from "../../redux/hooks";
import { BrumCommandBar } from "../BrumCommandBar";
import { isMobile } from "react-device-detect";
import { IFile } from "../../interfaces/IFile";
import { IFilePayload } from "../../interfaces/IFilePayload";
import { ConfirmDialog } from "../ConfirmDialog";
import { addFileToMethodolgy, deleteFile, downloadFileStream, editFile, getFile, getFileCountByFileCategory, getFileUrl } from "../../services/fileService";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { BrumSpinner } from "../BrumSpinner";
import { BrumToggle } from "../BrumToggle";
import { getPatient } from "../../services/patientService";
import axios from "axios";
import ViewSDKClient from "../../pdf/ViewSDKClient";

const mime = require("mime");

const buttonStyles = { root: { marginLeft: 8 } };

const panelStyles: Partial<IPanelStyles> = {
    content: {
        overflowX: "hidden",
        touchAction: "true",
        height: "calc(100vh - 124px);",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
    },
};

export interface IFilePreviewPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    onDeleteSuccess: () => void;
    onEditSuccess: () => void;
    file: IFile;
    onFileOpened: () => void;
    isReadOnly: boolean;
    onImageClicked: (src: string, text?: string) => void;
    onMethodologyChange: () => void;
    hasElevatedPermissions: boolean;
}

export const FilePreviewPanel: React.FunctionComponent<IFilePreviewPanelProps> = ({
    isOpen,
    dismissPanel,
    onDeleteSuccess,
    onEditSuccess,
    file,
    onFileOpened,
    isReadOnly,
    onImageClicked,
    onMethodologyChange,
    hasElevatedPermissions
}: IFilePreviewPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const user = useAppSelector((state) => state.user.user);
    const currentPage = useAppSelector((state) => state.navigation.currentPage);

    const [commandBarItems, setCommandBarItems] = React.useState<ICommandBarItemProps[]>([]);
    const [contentType, setcontentType] = React.useState("");
    const [fileName, setFileName] = React.useState("");
    const [thumbUrl, setThumbUrl] = React.useState<string>("");

    const [hideConfirmDeleteDialog, setHideConfirmDeleteDialog] = React.useState(true);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [inReplyMode, setInReplyMode] = React.useState(false);
    const [replySuccess, setReplySuccess] = React.useState(false);
    const [replyMessage, setReplyMessage] = React.useState("");
    const [canChangeMethodology, setCanChangeMethodology] = React.useState(hasElevatedPermissions);

    const [refreshMethodolgyFileCount, setRefreshMethodolgyFileCount] = React.useState<number>();
    const [methodolgyFileCount, setMethodolgyFileCount] = React.useState<number>(0);

    const [fileToEdit, setFileToEdit] = React.useState<IFilePayload>({
        userId: user?.id,
        description: file.description,
        approved: file.approved === true ? true : false,
        userAddressedTo: file.userAddressedTo,
        allowDownload: file.allowDownload === true ? true : false
    });

    const [fileToReply, setFileToReply] = React.useState<IFilePayload>({
        description: "",
        approved: false,
        userAddressedTo: file.userAddressedTo
    });

    const [fileWithData, setFileWithData] = React.useState<IFile>();
    const [loadingImage, setLoadingImage] = React.useState(true);

    const [tagUserOptions, setTagUserOptions] = React.useState<IDropdownOption[]>([]);

    React.useEffect(() => {
        (async () => {
            setMethodolgyFileCount(await getFileCountByFileCategory(FileCategory.Methodolgy.toString()));
        })();
    }, [refreshMethodolgyFileCount])

    React.useEffect(() => {
        if (file && file.name?.includes(".pdf")) {
            const viewSDKClient = new ViewSDKClient();
            const url = getFileUrl(file.id!.toString(), file.patientId?.toString(), user?.token);

            viewSDKClient.ready().then(() => {
                viewSDKClient.previewFile(
                    "pdf-div",
                    {
                        embedMode: "SIZED_CONTAINER",
                    },
                    file.name || "",
                    url
                );
            });
        }
    }, [file]);

    React.useEffect(() => {
        (async () => {
            if (file) {
                if (file.type === FileType.Video) {
                    const base64Response = await fetch(`data:image/jpeg;base64,${file.thumbnailData}`);
                    setThumbUrl(URL.createObjectURL(await base64Response.blob()));
                } else if (file.type === FileType.Image) {
                    setLoadingImage(true);
                    setFileWithData(await getFile(file.id?.toString()));
                    setLoadingImage(false);
                }
            }
        })();
    }, [file]);

    React.useEffect(() => {
        if (user && file && file.isNew) {
            onFileOpened();
        }
    }, [file, user]);

    React.useEffect(() => {
        if (inReplyMode) {
            setFileToReply({
                description: "",
                approved: false,
                userAddressedTo: fileToEdit?.userAddressedTo ?? file.userAddressedTo
            })

            setReplyMessage("");
            setReplySuccess(false);
        }
    }, [inReplyMode]);

    React.useEffect(() => {
        (async () => {
            if (file) {
                if (file.type === FileType.File) {
                    setcontentType(mime.getType(file.name!));
                    setFileName(file.name!);
                } else if (file.type === FileType.Image) {
                    setcontentType(MimeContentType.Image);
                    setFileName(`${file.dateCreated!}.jpg`);
                } else if (file.type === FileType.Video) {
                    setcontentType(MimeContentType.Video);
                    setFileName(`${file.dateCreated!}.webm`);
                }

                if (file.patientId) {
                    const patient = await getPatient(file.patientId!.toString());

                    const medewerkers = patient.users.filter((x) => x.typeId === UserType.Medewerker);
                    const gaurdians = patient.users.filter((x) => x.typeId === UserType.Gaurdian);
                    const caretakers = patient.users.filter((x) => x.typeId === UserType.Caretaker);

                    const options: IDropdownOption[] = [];

                    if (medewerkers.length > 0 && user?.typeId === UserType.Gaurdian) {
                        options.push({ key: "medewerkers", text: languageStrings.BrumBrumEmployees, itemType: DropdownMenuItemType.Header });
                        medewerkers.forEach((medewerker) => {
                            options.push({ key: medewerker.id!, text: `${medewerker.firstName} ${medewerker.lastName}` });
                        });
                    }

                    if (caretakers.length > 0 && user?.typeId === UserType.Gaurdian) {
                        options.push({ key: "caretakers", text: languageStrings.Caretakers, itemType: DropdownMenuItemType.Header });
                        caretakers.forEach((caretaker) => {
                            options.push({ key: caretaker.id!, text: `${caretaker.firstName} ${caretaker.lastName}` });
                        });
                    }

                    if (gaurdians.length > 0) {
                        options.push({ key: "gaurdians", text: languageStrings.Parents, itemType: DropdownMenuItemType.Header });
                        gaurdians.forEach((gaurdian) => {
                            options.push({ key: gaurdian.id!, text: `${gaurdian.firstName} ${gaurdian.lastName}` });
                        });
                    }

                    setTagUserOptions(options);
                }
            }
        })();
    }, [file, user]);

    const isDeleteDisabled = () => {
        if (file.fileCategoryId === FileCategory.Methodolgy && canChangeMethodology) {
            return false;
        }

        if (isDeleting || isReadOnly) {
            return true
        }

        if (file.userTypeId === UserType.Gaurdian && user?.typeId === UserType.Medewerker && user.roles.some(x => x.id === UserRole.Goedkeurder)) {
            return true;
        }

        if (currentPage !== AppPage.HomePage && !user?.roles.some(x => x.id === UserRole.Admin)) {
            return true;
        }
    }

    React.useEffect(() => {
        const items: ICommandBarItemProps[] = [
            {
                key: "Delete",
                text: languageStrings.Delete,
                disabled: isDeleteDisabled(),
                iconProps: { iconName: "Delete" },
                onClick: () => {
                    setHideConfirmDeleteDialog(false);
                },
            }
        ];

        if (file.type !== FileType.Note) {
            items.push({
                key: "Download",
                text: languageStrings.Download,
                disabled: isDeleting || (!(hasElevatedPermissions || user?.allowDownload || file.allowDownload) && currentPage === AppPage.HomePage),
                iconProps: { iconName: "Download" },
                onClick: () => {
                    (async () => {
                        let filename = `${file.patientFirstName}_${file.patientLastName}_${file.dateCreated}.${file.type === FileType.Video ? "webm" : "jpeg"}`;
                        await downloadFileStream(file.id!.toString(), file.patientId?.toString(), file.name ?? filename, user?.token);
                    })();
                },
            });
        }

        if (file.fileCategoryId !== FileCategory.Methodolgy && (file.type === FileType.Image || file.type === FileType.Video) && canChangeMethodology) {
            items.push({
                key: "Methodology",
                text: (file.associatedFileId && file.associatedFileId > 0) ? languageStrings.RemoveMethodology : languageStrings.AddMethodology,
                disabled: isDeleting || (!file.associatedFileId && methodolgyFileCount >= 20),
                iconProps: { iconName: "PenWorkspace" },
                onClick: () => {
                    (async () => {
                        try {
                            if (!file.associatedFileId) {
                                await addFileToMethodolgy(file.id!.toString());
                                onMethodologyChange();
                            } else {
                                setIsDeleting(true);
                                await deleteFile(file.associatedFileId!.toString());
                                setIsDeleting(false);
                                onMethodologyChange();
                                setRefreshMethodolgyFileCount(new Date().getTime());
                            }
                        } catch (error) {
                            console.error("Error adding file to methodology:", error);
                        }
                    })();
                },
            })
        }

        setCommandBarItems(items);
    }, [contentType, isDeleting, fileName, user, isReadOnly, file, hasElevatedPermissions]);

    const onSaveFile = async () => {
        try {
            setIsSaving(true);
            if (inReplyMode) {
                const formData = new FormData();

                formData.set("userId", user?.id!.toString() as string);
                formData.set("patientId", file?.patientId?.toString() as string);
                formData.set("fileCategoryId", file?.fileCategoryId!.toString());
                formData.set("description", fileToReply.description ?? "");
                formData.set("type", FileType.Note);
                formData.set("approved", user?.typeId === UserType.Medewerker ? "false" : "true");
                formData.set("userAddressedTo", fileToReply.userAddressedTo?.toString() ?? "");

                axios.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;

                axios
                    .post(`${process.env.REACT_APP_API_URL}/api/files/upload`, formData)
                    .then((res) => {
                        setInReplyMode(false);
                        setReplySuccess(true);
                        setReplyMessage(languageStrings.SuccessfullyReplied);
                    })
                    .catch((err) => {
                        setReplySuccess(false);
                        setReplyMessage(languageStrings.ErrorOccured);
                    });
            } else {
                await editFile(file.id!.toString(), fileToEdit);
                onEditSuccess();
            }
            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
            setErrorMessage(languageStrings.ErrorOccured);
        }
    };

    const onRenderFooterContent = React.useCallback(
        () => (
            <div className="text-right">
                <DefaultButton
                    onClick={() => {
                        if (inReplyMode) {
                            setInReplyMode(false);
                        } else {
                            dismissPanel();
                        }
                    }}
                    disabled={isSaving}
                >
                    {languageStrings.Cancel}
                </DefaultButton>
                {!inReplyMode && currentPage === AppPage.HomePage &&
                    <DefaultButton styles={buttonStyles} text={languageStrings.Reply} disabled={isSaving} onClick={() => setInReplyMode(true)} />}
                <DefaultButton
                    styles={buttonStyles}
                    text={languageStrings.Save}
                    disabled={isSaving
                        || (isReadOnly && !inReplyMode)
                        || (inReplyMode && (!fileToReply?.description || !fileToReply?.userAddressedTo))}
                    onClick={() => onSaveFile()}
                >
                    {isSaving && <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: 5 } }}></Spinner>}
                </DefaultButton>
            </div>
        ),
        [dismissPanel, onSaveFile, fileToEdit, fileToReply, file]
    );

    const onConfirmDelete = async () => {
        setHideConfirmDeleteDialog(true);
        setIsDeleting(true);
        try {
            await deleteFile(file.id?.toString());
        } catch (error) {
            setErrorMessage(languageStrings.ErrorOccured);
        }
        setIsDeleting(false);
        onDeleteSuccess();
    };

    const isDescriptionDisabled = () => {
        return isReadOnly;
    }

    return (
        <Panel
            headerText={languageStrings.Details}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            closeButtonAriaLabel="Close"
            styles={panelStyles}
            type={PanelType.custom}
            customWidth={`${isMobile ? "100%" : "500px"}`}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            allowTouchBodyScroll
        >
            <div>
                <BrumCommandBar commandBarItems={commandBarItems} />
                <div className="">
                    <div className="p-2">
                        {errorMessage && (
                            <div className="mb-2">
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                                    {errorMessage}
                                </MessageBar>
                            </div>
                        )}
                        {replyMessage && (
                            <div className="mb-2">
                                <MessageBar messageBarType={replySuccess ? MessageBarType.success : MessageBarType.error} isMultiline={false}>
                                    {replyMessage}
                                </MessageBar>
                            </div>
                        )}
                        {file.type === FileType.Image && (
                            <div className="mb-4 text-center">
                                {loadingImage && <BrumSpinner />}
                                {!loadingImage && fileWithData && (
                                    <>
                                        <img
                                            onContextMenu={(e) => e.preventDefault()}
                                            src={`data:${contentType};base64,${fileWithData.data}`}
                                            onClick={() => onImageClicked(`data:${contentType};base64,${fileWithData.data}`, fileWithData.description)}
                                            alt=""
                                            className="max-h-[300px] w-full object-cover"
                                        />
                                        <div className="text-center">{languageStrings.ProhibitedFromSharing}</div>
                                    </>

                                )}
                            </div>
                        )}
                        {file.type === FileType.Video && (
                            <div className="mb-4">
                                {thumbUrl && (
                                    <>
                                        <video
                                            onContextMenu={(e) => e.preventDefault()}
                                            src={`${process.env.REACT_APP_API_URL}/api/files/download?FileId=${file.id}&PatientId=${file.patientId}&FileName=${file.name}&AccessToken=${user?.token}`}
                                            className="max-h-[300px] w-full object-cover"
                                            controls
                                            poster={thumbUrl}
                                            preload="none"
                                            muted
                                            playsInline
                                        />
                                        <div className="text-center">{languageStrings.ProhibitedFromSharing}</div>
                                    </>
                                )}
                            </div>
                        )}
                        {file.type === FileType.File && (
                            <>
                                {file.name?.includes(".pdf") && <div className="mb-4" id="pdf-div">
                                </div>}
                                <div className="mb-4">
                                    <Label>{languageStrings.Name}</Label>
                                    <Text>{file?.name}</Text>
                                </div>
                            </>
                        )}
                        {hasElevatedPermissions && (
                            <>
                                <div className="pb-2">
                                    <BrumToggle
                                        id="approved"
                                        label={languageStrings.Approved}
                                        checked={fileToEdit.approved === true ? true : false}
                                        toggled={() => setFileToEdit({ ...fileToEdit, approved: fileToEdit.approved !== undefined ? !fileToEdit.approved : true })}
                                    />
                                </div>
                                {currentPage === AppPage.HomePage && file.type !== FileType.Note &&
                                    <div className="pb-2">
                                        <BrumToggle
                                            id={"allowdownload"}
                                            label={languageStrings.AllowDownload}
                                            checked={fileToEdit.allowDownload ? fileToEdit.allowDownload : false}
                                            toggled={() => setFileToEdit({ ...fileToEdit, allowDownload: fileToEdit.allowDownload ? !fileToEdit.allowDownload : true })}
                                        />
                                    </div>}
                            </>
                        )}
                        <div>
                            {(isDescriptionDisabled() || file.approved) && fileToEdit?.description && <>
                                <Label>{file.type === FileType.Note ? languageStrings.Note : languageStrings.Description}</Label>
                                <div className="mb-4">{fileToEdit?.description}</div>
                            </>}
                            {!isDescriptionDisabled() && !file.approved && <TextField
                                disabled={inReplyMode}
                                label={file.type === FileType.Note ? languageStrings.Note : languageStrings.Description}
                                multiline
                                rows={15}
                                value={fileToEdit?.description}
                                onChange={(e, newValue) => {
                                    setFileToEdit({ ...fileToEdit, description: newValue ? newValue : "" });
                                }}
                            />}
                        </div>
                        <>
                            {!inReplyMode &&
                                <Dropdown
                                    disabled={file.userTypeId === UserType.Gaurdian && user?.typeId === UserType.Medewerker && user.roles.some(x => x.id === UserRole.Goedkeurder)}
                                    label={languageStrings.MessageTo}
                                    options={tagUserOptions}
                                    selectedKey={fileToEdit.userAddressedTo}
                                    onChange={(event, option) => {
                                        setFileToEdit({ ...fileToEdit, userAddressedTo: option?.key ? (option?.key as number) : undefined });
                                    }}
                                />}
                            {inReplyMode && <div className="pb-2">
                                <Dropdown
                                    // disabled={file.addressedTo !== undefined || fileToEdit.userAddressedTo !== undefined}
                                    required
                                    label={languageStrings.MessageTo}
                                    options={tagUserOptions}
                                    // selectedKey={fileToEdit.userAddressedTo}
                                    onChange={(event, option) => {
                                        setFileToReply({ ...fileToReply, userAddressedTo: option?.key ? (option?.key as number) : undefined });
                                    }}
                                />
                            </div>
                            }
                            {inReplyMode && <TextField
                                required
                                label={languageStrings.Reply}
                                multiline
                                rows={15}
                                value={fileToReply?.description}
                                onChange={(e, newValue) => {
                                    setFileToReply({ ...fileToReply, description: newValue ? newValue : "" });
                                }}
                            />}
                        </>
                    </div>
                </div>

                <ConfirmDialog
                    hideDialog={hideConfirmDeleteDialog}
                    dismissDialog={() => setHideConfirmDeleteDialog(true)}
                    title={languageStrings.ConfirmDelete}
                    subText={languageStrings.ConfirmDeleteFileMessage}
                    onConfirm={() => onConfirmDelete()}
                />
            </div>
        </Panel>
    );
};
