export enum AppPage {
    None,
    HomePage,
    InstaPage,
    WorkDocumentPage,
    ReportPage,
    LoginPage,
    AdminPage,
    AdminUsersPage,
    AdminChildrenPage,
    ArchivePage,
    BrumGeneralPage,
    Methodology
}

export enum FileCategory {
	Insta = 5,
	WorkDocument = 6,
	Reports = 7,
    Archive = 8,
    BrumGeneral = 9,
    Methodolgy = 10
}

export enum MediaMode {
	None,
	FileUpload,
	Camera,
	Video,
    Note,
    FileUploadMedia,
}

export enum UserType {
	Gaurdian = 1,
	Caretaker = 2,
    Medewerker = 3
}

export enum UserRole {
    Admin = 1,
    Gebruiker = 2,
    Goedkeurder = 3,
}

export enum AdminCrudMode {
	None,
	Add,
	Edit,
}

export enum FileType {
    Video = "video",
    Image = "image",
    File = "file",
    Note = "note",
}

export enum MimeContentType {
    Video = "video/webm",
    Image = "image/jpeg",
    File = "application/json"
}

export enum UploadStatus {
    Init,
    Uploading,
    Finalizing,
    Completed
}

export enum FolderSortByOption {
    DateAscending,
    DateDescending,
    NameAscending,
    NameDescending,
}