import {
    ConstrainMode,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    ICommandBarItemProps,
    IPanelStyles,
    mergeStyles,
    Panel,
    PanelType,
    Persona,
    PersonaSize,
    SearchBox,
    Selection,
    SelectionMode,
} from "@fluentui/react";
import React from "react";
import { IPatient } from "../../interfaces/IPatient";
import { IUser } from "../../interfaces/IUser";
import { BrumCommandBar } from "../BrumCommandBar";
import { NoItemsFound } from "../NoItemsFound";
import { SelectPatientPanel } from "./SelectPatientPanel";
import { isMobile } from "react-device-detect";
import { LanguageContext } from "../../helpers/LocalizationModule";

const panelStyles: Partial<IPanelStyles> = {
    content: {
        overflowX: "hidden",
        touchAction: "true",
        height: "calc(100vh - 124px);",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
    },
};

export interface IManagePatientsPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    user: IUser;
    setUser: (user: IUser) => void;
}

export const ManagePatientsPanel: React.FunctionComponent<IManagePatientsPanelProps> = ({ isOpen, dismissPanel, user, setUser }: IManagePatientsPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const [commandBarItems, setCommandBarItems] = React.useState<ICommandBarItemProps[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<any>();
    const [isSelectPatientPanelOpen, setIsSelectPatientPanelOpen] = React.useState(false);

    const [filteredPatients, setFilteredPatients] = React.useState<IPatient[]>([]);
    const [searchText, setSearchText] = React.useState<string>("");

    const _selection = React.useRef(
        new Selection({
            onSelectionChanged: () => getSelectionDetails(),
        })
    );

    const getSelectionDetails = () => {
        const filteredSelectedItems = _selection.current.getSelection().filter((x) => x);

        if (filteredSelectedItems?.length === 1) {
            let selectedItem: any = filteredSelectedItems[0];
            setSelectedItem(selectedItem);
        } else {
            setSelectedItem(null);
        }
    };

    React.useEffect(() => {
        if (searchText) {
            setFilteredPatients(user.patients?.filter((x) => x.firstName?.toLowerCase().includes(searchText.toLowerCase()) || x.lastName?.toLowerCase().includes(searchText.toLowerCase())) ?? []);
        } else {
            setFilteredPatients(user.patients ?? []);
        }
    }, [user, searchText]);

    React.useEffect(() => {
        setCommandBarItems([
            {
                key: "Add",
                text: languageStrings.Add,
                disabled: selectedItem,
                iconProps: { iconName: "Add" },
                onClick: () => {
                    setIsSelectPatientPanelOpen(true);
                },
            },
            {
                key: "Delete",
                text: languageStrings.Delete,
                disabled: !selectedItem,
                iconProps: { iconName: "Delete" },
                onClick: () => {
                    setUser({ ...user, patients: user.patients?.filter((x) => x.id !== selectedItem.id) });
                    setSelectedItem(undefined);
                },
            },
        ]);
    }, [selectedItem]);

    const columns: IColumn[] = [
        {
            key: "displayName",
            name: languageStrings.Name,
            fieldName: "displayName",
            data: "string",
            minWidth: 100,
            maxWidth: 350,
            isResizable: true,
            onRender: (user: IUser) => {
                return (
                    <div className="hover:cursor-pointer">
                        <Persona
                            imageUrl={user.profileImage && `data:image/*;base64,${user.profileImage}`}
                            text={`${user.firstName} ${user.lastName}`}
                            size={PersonaSize.size40}
                            onClick={() => {}}
                        />
                    </div>
                );
            },
        },
    ];

    const patientSelected = (patient: IPatient) => {
        setIsSelectPatientPanelOpen(false);
        setUser({ ...user, patients: user.patients ? [...user.patients, patient] : [patient] });
    };

    const onRenderFooterContent = React.useCallback(
        () => (
            <div className="text-right">
                <DefaultButton onClick={dismissPanel}>{languageStrings.Done}</DefaultButton>
            </div>
        ),
        [dismissPanel]
    );

    const searchChanged = (value: string) => {
        setSearchText(value);
    };

    return (
        <Panel
            headerText={languageStrings.ManageChildren}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            closeButtonAriaLabel="Close"
            styles={panelStyles}
            type={PanelType.custom}
            customWidth={`${isMobile ? "100%" : "500px"}`}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            allowTouchBodyScroll
        >
            <div>
                <BrumCommandBar commandBarItems={commandBarItems} />
                <div className="py-2">
                    <SearchBox placeholder={languageStrings.Search} underlined onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => searchChanged(newValue ?? "")} />
                </div>
                {user?.patients?.length > 0 && (
                    <div className="">
                        <DetailsList
                            items={filteredPatients ?? []}
                            columns={columns}
                            selectionMode={SelectionMode.single}
                            layoutMode={DetailsListLayoutMode.justified}
                            constrainMode={ConstrainMode.unconstrained}
                            selection={_selection.current}
                            checkboxCellClassName={mergeStyles({
                                display: "flex",
                                alignItems: "center",
                            })}
                        />
                    </div>
                )}
                {user?.patients?.length === 0 && <NoItemsFound />}
            </div>

            {isSelectPatientPanelOpen && (
                <SelectPatientPanel
                    isOpen={isSelectPatientPanelOpen}
                    dismissPanel={() => {
                        setIsSelectPatientPanelOpen(false);
                    }}
                    itemSelected={patientSelected}
                    isAdminMode={true}
                    selectedUser={user}
                />
            )}
        </Panel>
    );
};
