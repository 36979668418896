import axios from "axios";
import { IPatient } from "../interfaces/IPatient";
import { IPatientPayload } from "../interfaces/IPatientPayload";

export const getPatients = async (activeOnly: boolean = false): Promise<IPatient[]> => {
    return (await axios.get(`${process.env.REACT_APP_API_URL}/api/patients/${activeOnly}`)).data;
};

export const getPatient = async (patientId: string): Promise<IPatient> => {
    return (await axios.get(`${process.env.REACT_APP_API_URL}/api/patients/${patientId}`)).data;
};

export const addPatient = async (patient: IPatientPayload): Promise<IPatient> => {
    return (await axios.post(`${process.env.REACT_APP_API_URL}/api/patients`, patient)).data;
};

export const editPatient = async (patientId: string, patient: IPatientPayload): Promise<IPatient> => {
    return (await axios.put(`${process.env.REACT_APP_API_URL}/api/patients/${patientId}`, patient)).data;
};

export const deletePatient = async (patientId: string) => {
    return (await axios.delete(`${process.env.REACT_APP_API_URL}/api/patients/${patientId}`)).data;
};
