import { InstaCard } from "./InstaCard";
import { MediaMode } from "../../../helpers/enums";
import { useAppDispatch } from "../../../redux/hooks";
import { setMediaMode } from "../../../redux/slices/instaSlice";
import { LanguageContext } from "../../../helpers/LocalizationModule";
import React from "react";

export const InstaMain: React.FunctionComponent = () => {
    const languageStrings = React.useContext(LanguageContext);

    const dispatch = useAppDispatch();

    return (
        <>
            <div className="h-full flex flex-col lg:flex-row xl:mx-[10%] py-[10%] md:py-0 gap-2">
                <InstaCard iconName="Camera" label={languageStrings.Camera} cardClicked={() => dispatch(setMediaMode(MediaMode.Camera))} />
                <InstaCard iconName="Video" label={languageStrings.Video} cardClicked={() => dispatch(setMediaMode(MediaMode.Video))} />
                <InstaCard iconName="EditNote" label={languageStrings.Note} cardClicked={() => dispatch(setMediaMode(MediaMode.Note))} />
                <InstaCard iconName="Upload" label={languageStrings.Upload} cardClicked={() => dispatch(setMediaMode(MediaMode.FileUploadMedia))} />
            </div>
        </>
    );
};
