import axios from "axios";
import { IFile } from "../interfaces/IFile";
import { IFileFolder } from "../interfaces/IFileFolder";
import { IFilePayload } from "../interfaces/IFilePayload";
import streamSaver from "streamsaver";
import { IFileCategoryNewCount } from "../interfaces/IFileCategoryNewCount";
import { IFilesResponse } from "../interfaces/IFilesResponse";

export const addFile = async (file: IFilePayload): Promise<IFilePayload> => {
    return (await axios.post(`${process.env.REACT_APP_API_URL}/api/files`, file)).data;
};

export const getFileFolders = async (includeToBeApproved: boolean, userId: string, patientId?: string, fileCategoryId?: string, assignedToUserId?: string): Promise<IFileFolder[]> => {
    return (
        await axios.get(
            `${process.env.REACT_APP_API_URL}/api/Files/GetFileFolders?UserId=${userId}${patientId ? `&PatientId=${patientId}` : ""
            }&FileCategoryId=${fileCategoryId}&IncludeToBeApproved=${includeToBeApproved}${assignedToUserId ? `&assignedToUserId=${assignedToUserId}` : ""}`
        )
    ).data;
};

export const getFiles = async (orderByDesc?: boolean, nextPageStartId?: number, userId?: string, patientId?: string, fileCategoryId?: string, selectedFolder?: IFileFolder): Promise<IFilesResponse> => {
    return (
        await axios.get(
            `${process.env.REACT_APP_API_URL}/api/Files/GetFiles?OrderByDesc=${orderByDesc ?? true}&NextPageStartId=${nextPageStartId ?? -1}&FileCategoryId=${fileCategoryId}${patientId ? `&PatientId=${patientId}` : ""}&FolderName=${selectedFolder?.folderName
            }&UserId=${userId}${selectedFolder?.createdByUserId ? `&CreatedByUserId=${selectedFolder?.createdByUserId}` : ""}`
        )
    ).data;
};

export const getFilesForCategory = async (orderByDesc?: boolean, nextPageStartId?: number, fileCategoryId?: string): Promise<IFilesResponse> => {
    return (await axios.get(`${process.env.REACT_APP_API_URL}/api/Files/GetFiles?OrderByDesc=${orderByDesc ?? true}&NextPageStartId=${nextPageStartId ?? -1}&FileCategoryId=${fileCategoryId}`)).data;
};

export const getFileLight = async (fileId: number, userId: number): Promise<IFile> => {
    return (await axios.get(`${process.env.REACT_APP_API_URL}/api/Files/GetFileLight?UserId=${userId}&FileId=${fileId}`))?.data;
};

export const getFile = async (fileId?: string): Promise<IFile> => {
    return (await axios.get(`${process.env.REACT_APP_API_URL}/api/Files/${fileId}`)).data;
};

export const getFileCategoryNewCount = async (userId: string, patientId?: string): Promise<IFileCategoryNewCount[]> => {
    return (await axios.get(`${process.env.REACT_APP_API_URL}/api/Files/GetFileCategoryNewCount?UserId=${userId}${patientId ? `&PatientId=${patientId}` : ""}`)).data;
};

export const editFile = async (fileId: string, file: IFilePayload): Promise<void> => {
    return (await axios.put(`${process.env.REACT_APP_API_URL}/api/files/${fileId}`, file)).data;
};

export const deleteFile = async (fileId?: string) => {
    return (await axios.delete(`${process.env.REACT_APP_API_URL}/api/Files/${fileId}`)).data;
};

export const downloadFileStream = async (fileId: string, patietId?: string, filename?: string, accessToken?: string | undefined): Promise<void> => {
    const response: Response = await fetch(getFileUrl(fileId, patietId, accessToken), {
        method: "Get",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (filename) {
        const fileStream = streamSaver.createWriteStream(filename);
        const readableStream = response.body;

        //   // More optimized
        if (readableStream?.pipeTo) {
            return await readableStream.pipeTo(fileStream);
        }

        const writer = fileStream.getWriter();
        const reader = response.body?.getReader();

        if (reader) {
            const pump: any = () => reader.read().then((res) => (res.done ? writer.close() : writer.write(res.value).then(pump)));

            pump();
        }
    }

};

export const getFileUrl = (fileId: string, patietId?: string, accessToken?: string | undefined) => {
    return `${process.env.REACT_APP_API_URL}/api/Files/Download?FileId=${fileId}${patietId ? `&PatientId=${patietId}` : ``}&AccessToken=${accessToken}`
}

export const addFileToMethodolgy = async (fileId?: string): Promise<IFile> => {
    return (await axios.post(`${process.env.REACT_APP_API_URL}/api/Files/AddFileToMethodolgy/${fileId}`)).data;
};

export const removeFileFromMethodolgy = async (fileId?: string): Promise<IFile> => {
    return (await axios.post(`${process.env.REACT_APP_API_URL}/api/Files/RemoveFileFromMethodolgy`, { fileId })).data;
};

export const getFileCountByFileCategory = async (fileCategory?: string): Promise<number> => {
    return (await axios.get(`${process.env.REACT_APP_API_URL}/api/Files/GetFileCountByFileCategory/${fileCategory}`)).data;
};