import { Icon } from "@fluentui/react";
import React from "react";
import { AppPage, FileCategory, FolderSortByOption, UserRole } from "../../helpers/enums";
import { LanguageContext } from "../../helpers/LocalizationModule";
import { IFileFolder } from "../../interfaces/IFileFolder";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { resetInsta } from "../../redux/slices/instaSlice";
import { resetNav, setAddressedToList, setCreatedByList, setCurrentPage, setNotApprovedCount, setSelectedFolder } from "../../redux/slices/navigationSlice";
import { restUser } from "../../redux/slices/userSlice";
import { getFileFolders } from "../../services/fileService";
import { BrumSpinner } from "../BrumSpinner";
import { StorageHelper } from "../../helpers/StorageHelper";
import { ApprovalFolderName } from "../../helpers/constants";

export interface IPageFolderListProps {
    fileCategory: FileCategory;
    refreshList?: boolean;
    sortOption: FolderSortByOption;
    assignedToUserId?: string;
}

export const PageFolderList: React.FunctionComponent<IPageFolderListProps> = ({ fileCategory, refreshList, sortOption, assignedToUserId }: IPageFolderListProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const dispatch = useAppDispatch();

    const selectedPatient = useAppSelector((state) => state.user.selectedPatient);
    const user = useAppSelector((state) => state.user.user);
    const selectedFolder = useAppSelector((state) => state.navigation.selectedFolder);

    const [toBeApprovedFolder, setToBeApprovedFolder] = React.useState<IFileFolder>();
    const [fileFolders, setFileFolders] = React.useState<IFileFolder[]>([]);
    const [sortedFileFolders, setSortedFileFolders] = React.useState<IFileFolder[]>([]);
    const [isBusy, setIsBusy] = React.useState(true);
    const [isApprover, setIsApprover] = React.useState(false);

    React.useEffect(() => {
        if (!selectedFolder) {
            dispatch(setCreatedByList([]));
            dispatch(setAddressedToList([]));
        }
    }, [selectedFolder]);

    React.useEffect(() => {
        dispatch(setNotApprovedCount(0));
        setIsApprover(user?.roles.find((x) => x.id === UserRole.Goedkeurder || x.id === UserRole.Admin) ? true : false);

        if (fileCategory && user) {
            (async () => {
                setIsBusy(true);
                try {
                    const folders = await getFileFolders(true, user.id.toString(), selectedPatient?.id?.toString(), fileCategory?.toString(), assignedToUserId);
                    if (folders?.length > 0 && folders[0].folderName === ApprovalFolderName) {
                        setToBeApprovedFolder(folders[0]);
                        setFileFolders(
                            folders.slice(1).map((folder, index) => {
                                const newFolder: IFileFolder = {
                                    ...folder,
                                    sortIndex: index,
                                };
    
                                return newFolder;
                            })
                        );
                    } else {
                        setToBeApprovedFolder(undefined);
                        setFileFolders(
                            folders.map((folder, index) => {
                                const newFolder: IFileFolder = {
                                    ...folder,
                                    sortIndex: index,
                                };
    
                                return newFolder;
                            })
                        );
                    }
                } catch (error: any) {
                    if (error.response?.status === 401) {
                        StorageHelper.removeItem("user");
                        dispatch(restUser());
                        dispatch(resetInsta());
                        dispatch(resetNav());
                        dispatch(setCurrentPage(AppPage.LoginPage));
                    }
                }
                setIsBusy(false);
            })();
        }
    }, [fileCategory, selectedPatient, refreshList, user, assignedToUserId]);

    React.useEffect(() => {
        if ((toBeApprovedFolder || fileFolders.length > 0) && sortOption !== undefined) {
            if (sortOption === FolderSortByOption.NameAscending) {
                if (toBeApprovedFolder && isApprover) {
                    setSortedFileFolders([toBeApprovedFolder, ...fileFolders.sort((a, b) => (a.sortIndex > b.sortIndex ? 1 : -1))]);
                } else {
                    setSortedFileFolders(fileFolders.sort((a, b) => (a.sortIndex! > b.sortIndex! ? 1 : -1)));
                }
            }

            if (sortOption === FolderSortByOption.NameDescending) {
                if (toBeApprovedFolder && isApprover) {
                    setSortedFileFolders([toBeApprovedFolder, ...fileFolders.sort((a, b) => (a.sortIndex < b.sortIndex ? 1 : -1))]);
                } else {
                    setSortedFileFolders(fileFolders.sort((a, b) => (a.sortIndex < b.sortIndex ? 1 : -1)));
                }
            }
        } else {
            setSortedFileFolders(fileFolders);
        }
    }, [toBeApprovedFolder, fileFolders, sortOption, isApprover]);

    const getFileCountDisplay = (folder: IFileFolder) => {
        if (folder.createdByUserId === user?.id) {
            return ""
        }
        else if (folder.newFileCount) {
            if (folder.newFileCount === 0) {
                return "";
            }

            return folder.newFileCount;
        }
    };

    return (
        <div>
            {!isBusy && (
                <div className="grid grid-flow-row grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 auto-rows-min">
                    {sortedFileFolders.length > 0 &&
                        sortedFileFolders.map((folder, index) => {
                            return (
                                <div key={folder.folderName} className="text-center my-3 py-3 hover:cursor-pointer hover:bg-gray-100" onClick={() => dispatch(setSelectedFolder(folder))}>
                                    <div className="inline-flex relative">
                                        <Icon className="text-purple-900 text-6xl" iconName="FabricFolder" styles={{ root: { fontSize: 36 } }} />
                                        <div
                                            className={`${folder.newFileCount && folder.createdByUserId !== user?.id
                                                    ? "inline-flex absolute top-0 -right-2 justify-center items-center w-8 h-8 text-sm font-bold  text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900"
                                                    : ""
                                                }`}
                                        >
                                            {getFileCountDisplay(folder)}
                                        </div>
                                    </div>
                                    <div className="text-lg text-purple-900 font-semibold">{folder.folderName === ApprovalFolderName ? languageStrings.ToBeApproved : folder.folderName}</div>
                                </div>
                            );
                        })}
                </div>
            )}

            {!isBusy && sortedFileFolders.length === 0 && <div className="text-gray-500 text-md text-center mt-4">{languageStrings.NoItemsFound}</div>}

            {isBusy && (
                <div>
                    <BrumSpinner />
                </div>
            )}
        </div>
    );
};
